function WhiteFacebook() {
  return (
    <svg
      width="7"
      height="14"
      viewBox="0 0 7 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.54745 14V7.62433H6.62061L6.92877 5.12805H4.54745V3.53802C4.54745 2.81769 4.7409 2.3245 5.73736 2.3245H7V0.098925C6.38565 0.0306183 5.76814 -0.00236327 5.15028 0.000131594C3.3178 0.000131594 2.05966 1.16076 2.05966 3.29143V5.12338H0V7.61966H2.06416V14H4.54745Z"
        className="fill-white hover:fill-grey-7"
      />
    </svg>
  );
}

export default WhiteFacebook;
