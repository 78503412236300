export function Mute(props) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 63 63"
      version="1.1"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      {...props}
    >
      <g>
        <path
          d="M31.123,3.123C15.669,3.123 3.123,15.669 3.123,31.123C3.123,46.577 15.669,59.123 31.123,59.123C46.577,59.123 59.123,46.577 59.123,31.123C59.123,15.669 46.577,3.123 31.123,3.123ZM31.123,5.123C45.473,5.123 57.123,16.773 57.123,31.123C57.123,45.473 45.473,57.123 31.123,57.123C16.773,57.123 5.123,45.473 5.123,31.123C5.123,16.773 16.773,5.123 31.123,5.123ZM37.624,40.115L37.624,35.411L40.677,38.394L42.585,36.53L25.794,20.123L23.886,21.987L28.465,26.462L24.123,26.462C23.571,26.462 23.123,26.91 23.123,27.462L23.123,34.784C23.123,35.336 23.571,35.784 24.123,35.784L29.229,35.784L36.021,40.913C36.68,41.41 37.624,40.94 37.624,40.115ZM37.624,27.913L33.045,23.333L36.069,21.317C36.734,20.874 37.624,21.351 37.624,22.149L37.624,27.913Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}