import { useState, useEffect, useRef } from "react";
import Link from 'next/link';
import { Schema } from './CtaSlider.schema';

export function CtaSlider({ cms }) {
  const moduleID = `${Schema().key}-${cms.id || cms.cmsId || cms.tinaId}`;
  const isFullwidth = cms?.isFullwidth || false;
  const itemCount = cms?.cta?.length || 0;
  const enableSlider = cms?.enableSlider || false;
  const sliderRef = useRef(null);
  const [mgRight, setMgRight] = useState(0);
  const [justify, setjustify] = useState('justify-center');

  useEffect(() => {
    if (!sliderRef?.current || !enableSlider) return;

    let isDown = false;
    let startX;
    let scrollLeft;
    const slider = sliderRef.current;
    const onSliderMousedown = (e) => {
      const slider = e.currentTarget;
      isDown = true;
      slider.classList.add('select-none', '!cursor-grabbing', 'snap-none');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    }
    const onSliderMouseleave = (e) => {
      const slider = e.currentTarget;
      isDown = false;
      slider.classList.remove('active');
    }
    const onSliderMouseup = (e) => {
      const slider = e.currentTarget;
      e.preventDefault();
      isDown = false;
      slider.classList.remove('select-none', '!cursor-grabbing', 'snap-none');
    }
    const onSliderMousemove = (e) => {
      if(!isDown) return;
      const slider = e.currentTarget;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
    }
    slider.addEventListener('mousedown', onSliderMousedown);
    slider.addEventListener('mouseleave', onSliderMouseleave);
    slider.addEventListener('mouseup', onSliderMouseup);
    slider.addEventListener('mousemove', onSliderMousemove);
    
    const reachRightEdge = () => {
      if (!sliderRef?.current) return;
      const slider = sliderRef.current;
      const right = -1* sliderRef.current.offsetLeft;
      if (slider.scrollWidth > slider.clientWidth) {
        slider.classList.add('pr-4', 'lg:pr-12');
        setjustify('justify-start');
        setMgRight(`${right}px`);
      } else {
        slider.classList.remove('pr-4', 'lg:pr-12');
        setjustify('justify-center');
        setMgRight(0);
      }
    }
    reachRightEdge();
    window.addEventListener('resize', reachRightEdge);

    const EXCLUDED_ANCHOR_LINKS = [];
    const header = document.querySelector('.site-header');

    const clamp = (min, value, max) => {
      return Math.min(Math.max(value, min), max);
    }
    const handleClick = (e) => {
      const anchor = e.target;
      if (!anchor || !anchor?.hash || anchor?.dataset?.initialized) return;
      const anchorEl = document.querySelector(anchor.hash);
      if (!anchorEl) {
        return;
      }
      if (!/^#\D/.test(anchor.hash) || EXCLUDED_ANCHOR_LINKS.includes(anchor.hash)) return;

      e.preventDefault();
      const shift = anchorEl.offsetTop - window.scrollY;
      const distance = Math.abs(shift);
      const duration = clamp(300, distance, 1200) / 1000;

      document.body.classList.add('pointer-events-none');

      window.scrollTo({
        top: anchorEl.offsetTop - (header ? header.offsetHeight : 0),
        behavior: 'smooth'
      });

      setTimeout(() => {
        document.body.classList.remove('pointer-events-none');
      }, duration * 1000);

      anchor.dataset.initialized = true;
    }
    const anchors = [...slider.querySelectorAll(`a[href^="#"], a[href^="${window.location.pathname}#"]`)];
    anchors.forEach((anchor) => {
      anchor.addEventListener('click', handleClick);
    })

    return () => {
      window.removeEventListener('resize', reachRightEdge);
      const slider = sliderRef?.current;
      if (slider) {
        slider.removeEventListener('mousedown', onSliderMousedown);
        slider.removeEventListener('mouseleave', onSliderMouseleave);
        slider.removeEventListener('mouseup', onSliderMouseup);
        slider.removeEventListener('mousemove', onSliderMousemove);
        anchors.forEach((anchor) => {
          anchor.removeEventListener('click', handleClick);
        })
      }
    };
  }, [sliderRef, enableSlider])

  useEffect(() => {
    
  }, [])

  if (itemCount <= 0) {
    return null;
  }
  
  return (
    <div
      id={`${moduleID}`}
      key={cms.tinaId}
      className={`cta-slider px-4 lg:px-12 w-full${isFullwidth ? '' : ' max-w-[1180px] mx-auto'}`}
      data-comp={CtaSlider.displayName}
    >
      <style>
        {`#${moduleID} {
            margin-top: ${cms.mobileSpacing}px;
          }
          @media(min-width: 1024px) {
            #${moduleID} {
              margin-top: ${cms.desktopSpacing}px;
            }
          }`}
      </style>
      <div className="ml-0 flex flex-row" style={{
          marginRight: mgRight
        }}>
        {itemCount > 0 && (
          <div className={`scrollbar-hidden snap-mandatory will-change-scroll my-0 list-none pl-0 w-full flex flex-row ${justify} max-sm:items-center gap-3 ${enableSlider ? ' scrollbar' : 'flex-wrap max-sm:flex-col'}`}
            ref={sliderRef}
          >
            {cms?.cta.map(({ title, link, cta_style, cta_visibility, className }, index) => {
              let class_visibility = '';
              if (cta_visibility === 'desktop') {
                class_visibility = 'max-lg:hidden';
              } else if (cta_visibility === 'mobile') {
                class_visibility = 'lg:hidden';
              }
              return link?.url && (title || link?.text) && (
                <div
                  key={`cta-slider-${index}`}
                  className={`w-full max-w-[225px] sm:max-w-[max(20%-0.6rem,151px)] md:max-w-max lg:min-w-[155px] flex-shrink-0 scroll-mr-6`}>
                  <Link href={link.url}>
                    <a
                      className={`${cta_style} ${class_visibility} block text-center w-full !min-w-0 pointer-events-none1 !cursor-[inherit] max-xs:px-2`}
                      draggable="false"
                    >
                      {title || link?.text}
                    </a>
                  </Link>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

const Cta = ({ title, link, cta_style, cta_visibility, className = '' }) => {
  let class_visibility = '';
  if (cta_visibility === 'desktop') {
    class_visibility = 'max-lg:hidden';
  } else if (cta_visibility === 'mobile') {
    class_visibility = 'lg:hidden';
  }
  return link?.url && (title || link?.text) && (
    <div
      className={`${className}`}>
      <Link href={link.url}>
        <a
          className={`${cta_style} ${class_visibility} block text-center w-full !min-w-0 pointer-events-none1 max-xs:px-2`}
          draggable="false"
        >
          {title || link?.text}
        </a>
      </Link>
    </div>
  );
}

CtaSlider.displayName = 'CtaS lider';
CtaSlider.Schema = Schema;
