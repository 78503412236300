import { useSettings } from '@backpackjs/storefront';
import Link from 'next/link';

function MobileQuickLinks(cms, ...props) {
  const settings = useSettings();

  return (
    settings?.mobileStickyNavigation?.length > 0 && (
      <div className="mobileQuickLinks border-b border-grey-4 lg:hidden">
        <ul className="m-0 mt-0 flex list-none flex-nowrap items-center gap-x-5 overflow-x-auto p-0 px-5 pb-1.5 font-primary">
          {settings?.mobileStickyNavigation?.map((linkItem, linkIndex) => {
            return (
              linkItem?.link?.url &&
              linkItem?.link?.text && (
                <li className="mt-0" key={`mobileQuickLink${linkIndex}`}>
                  {linkItem.link.url.indexOf('http') > -1 ? (
                    <a
                      href={linkItem.link.url}
                      target={linkItem.link.newTab === true ? '_blank' : '_self'}
                      className="block whitespace-nowrap text-xs text-grey-1 no-underline"
                      rel="noreferrer"
                    >
                      {linkItem.link.text}
                    </a>
                  ) : (
                    <Link href={linkItem.link.url}>
                      <a
                        title={linkItem.link.text}
                        target={
                          linkItem.link.newTab === true ? '_blank' : '_self'
                        }
                        className="block whitespace-nowrap text-xs text-grey-1 no-underline"
                      >
                        {linkItem.link.text}
                      </a>
                    </Link>
                  )}
                </li>
              )
            );
          })}
        </ul>
      </div>
    )
  );
}

export default MobileQuickLinks;
