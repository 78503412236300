function ProductBadge(props) {
  const { product, className, forceSaveBadge } = props;
  const metafields = product?.metafields || [];
  const badges = metafields.filter((badge) => {
    return badge.namespace === 'custom_fields' && badge.key === 'badge';
  });
  const imageBadges = metafields.filter((badge) => {
    return badge.namespace === 'custom_fields' && badge.key === 'badge_image';
  });
  let badgeText = badges?.[0]?.value;
  if (!badges.length && !imageBadges.length && !forceSaveBadge) {
    return null;
  }

  let badgeTextcolor = 'text-black';
  let bgClass = 'bg-yellow';
  if (badges.length && badges[0].value.toLowerCase() === 'best seller') {
    bgClass = 'bg-primary-1';
    badgeTextcolor = 'text-white';
  }

  if (badges.length && badges[0].value.toLowerCase() === 'save') {
    bgClass = 'bg-primary-2';
    badgeTextcolor = 'text-white';
  }

  if (forceSaveBadge) {
    bgClass = 'bg-primary-2';
    badgeTextcolor = 'text-white';
    badgeText = 'save';
  }

  return (
    <div className={`pdp-badges left-right-rotate font-gothamBold ${className}`}>
      <div
        className={`pdp-badge m-0${
          imageBadges.length && !forceSaveBadge ? ' !p-0' : ` ${bgClass} ${badgeTextcolor}`
        }`}
      >
        {imageBadges.length && !forceSaveBadge ? (
          <img
            src={imageBadges[0].value}
            className="block w-[63px] rounded-full lg:w-[73px]"
            alt={product.title}
          />
        ) : null}
        {(!imageBadges.length && badges.length) || forceSaveBadge ? (
          <span dangerouslySetInnerHTML={{ __html: badgeText }} />
        ) : null}
      </div>
    </div>
  );
}

export default ProductBadge;
