import { useBuilderContext } from './BundleBuildercontext';

export default function BuilderGridHeader() {
  const context = useBuilderContext();
  const products = context.builderData.filteredProducts;
  return (
    <div className="bundle-builder__grid-header mb-4 flex justify-between">
      <h2 className="h5 bundle-builder__grid-title mb-0">Build your bundle</h2>

      <span className="bundle-builder__product-count">
        {products?.length} products
      </span>
    </div>
  );
}
