import React from 'react';

export const useThrottle = (value, delay) => {
  // State and setters for debounced value
  const [throttledValue, setThrottledValue] = React.useState(value);
  const [throttleCount, setThrottleCount] = React.useState(0);

  React.useEffect(() => {
    if (throttleCount <= delay) {
      setThrottleCount(throttleCount + 1);
    }
  }, [value]);

  React.useEffect(
    () => {
      // Update throttled value after iterations matches delay count
      if (throttleCount >= delay) {
        setThrottledValue(value);
      }

      return () => {
        if (throttleCount === delay) {
          setThrottleCount(0);
        }
      };
    },
    [throttleCount, delay] // Only re-call effect if delay changes
  );

  return throttledValue;
};
