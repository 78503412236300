export function Close(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <line
        x1="14.5455"
        y1="1.41476"
        x2="1.41422"
        y2="14.546"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
      />
      <line
        x1="14.5858"
        y1="14.5455"
        x2="1.45453"
        y2="1.41423"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
      />
    </svg>
  );
}
