import Image from 'next/image';
import { useCartAddItem, useLocalizedVariant, useProductByHandle } from '@backpackjs/storefront';
import Link from 'next/link';
import ProductPrice from '../globals/layout/ProductPrice';

export default function SidecartUpsellItem({ product }) {
  const { product: currentProduct } = useProductByHandle({
    handle: product.handle,
  });
  const { cartAddItem } = useCartAddItem();
  return (
    <div className="upsell-ltem flex items-center border-b border-grey-4 py-3 last-of-type:border-b-0">
      <div className="upsell-item__image-container aspect-square w-20 shrink-0">
        {currentProduct && (
          <Link href={`/products/${currentProduct?.handle}`}>
            <a>
              <Image
                src={currentProduct?.featuredImage?.src}
                alt={currentProduct?.featuredImage?.alt}
                width="80"
                height="80"
                loading="lazy"
                sizes=""
              />
            </a>
          </Link>
        )}
      </div>
      <div className="upsell-item__info px-2">
        {product && (
          <Link href={`/products/${currentProduct?.handle}`}>
            <a className="no-underline">
              <h6 className="mb-0 font-gothamBold text-xs capitalize">
                {product?.metafield?.value || currentProduct?.title}
              </h6>
              <ProductPrice
                key={currentProduct?.id}
                variant={currentProduct?.variants[0]}
                className="text-xs"
              />
            </a>
          </Link>
        )}
      </div>
      <button
        type="button"
        className="button button--primary ml-auto !min-w-max !text-sm"
        onClick={() => {
          cartAddItem({
            merchandiseId: currentProduct.variants[0].id,
            quantity: 1,
            attributes: [
              {
                key: '_sc',
                value: (typeof window.ShipScoutLineItemProperty !== 'undefined' ? window.ShipScoutLineItemProperty : 'null'),
              }
            ],
          });
        }}
      >
        {' '}
        add{' '}
      </button>
    </div>
  );
}
