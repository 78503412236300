const families = ['Plus+Jakarta+Sans', 'Albert+Sans'];
const familiesQuery = families.map(
  (family) => `family=${family}:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600`
);
function HeadMeta() {
  return (
    <>
      <title>Briogeo</title>
      <link rel="shortcut icon" href="/favicon.svg" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="true"
      />
      <link
        rel="stylesheet"
        href={`https://fonts.googleapis.com/css2?${familiesQuery.join(
          '&'
        )}&display=swap`}
      />

      <link
        href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;600&display=swap"
        rel="stylesheet"
      />

      {/* additional open graph */}
      <meta property="og:site_name" content="Briogeo" />

      {/* pwa (progressive web app) */}
      <meta name="theme-color" content="#FFFFFF" />
      <link rel="manifest" href="/manifest.json" />
      <link
        rel="apple-touch-icon"
        sizes="192x192"
        href="/icons/icon-384x384.png"
      />
      <meta name="application-name" content="Briogeo" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="apple-mobile-web-app-title" content="Briogeo" />
      <meta name="format-detection" content="telephone=no" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="msapplication-TileColor" content="#FFFFFF" />
      <meta name="msapplication-tap-highlight" content="no" />
      <meta name="google-site-verification" content="5UaEMTGUQlRvHCVHLcQvgjYH3jzxiSlgyg0HRUQ77Go" />
    </>
  );
}

export default HeadMeta;
