/* eslint-disable react/destructuring-assignment */
import React from 'react';
import Head from 'next/head';

import { Partytown as VinatagePartytown } from '@builder.io/partytown/react';

const isNetlify = process.env.NETLIFY === 'true';
const context = process.env.CONTEXT;
const isDev = process.env.NODE_ENV === 'development' || context === 'dev';
const isPreview = ['deploy-preview', 'branch-deploy'].includes(context);
const isProduction =
  process.env.NODE_ENV === 'production' || context === 'production';

const getProxyUrl = (url) => {
  try {
    if (url.startsWith('http')) return url;

    if (isDev) {
      return `http://localhost:${process.env.PORT}/proxy/${url}`;
    }

    if (isPreview) {
      return `${process.env.DEPLOY_PRIME_URL}/proxy/${url}`;
      // could also be `${process.env.DEPLOY_URL}/proxy/${url}` (unique deploy url)
    }

    if (isProduction && isNetlify) {
      return `${process.env.URL}/proxy/${url}`;
    }

    return `${window.location.origin}/proxy/${url}`;
  } catch (error) {
    return `${process.env.SITE_URL}/proxy/${url}`;
  }
};

const resolveUrl = (url, location, type) => {
  const { host, pathname, search } = url;
  return type === 'script' && !pathname.startsWith('/proxy')
    ? new URL(`${location.origin}/proxy/${host}${pathname}${search}`)
    : url;
};

export function Partytown(props) {
  if (props.enabled !== true) return null;

  const options = {
    ...props,
    resolveUrl: props.resolveUrl || resolveUrl,
    loadScriptsOnMainThread:
      props?.loadScriptsOnMainThread?.flatMap(getProxyUrl),
  };

  if (isDev && props.debug === 'verbose') {
    console.log('partytown options', options);
  }

  return (
    <Head>
      <VinatagePartytown {...options} />
    </Head>
  );
}
