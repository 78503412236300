import { useCountries, useCountry, useSettings } from '@backpackjs/storefront';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import rehypeRaw from 'rehype-raw';
import wrap from 'rehype-wrap';
import Cookies from 'js-cookie';
import { useState, useEffect, useRef } from 'react';
import { IconClose } from '..';

export default function GeoLocation() {
  const { country, updateCountry } = useCountry();
  const countries = useCountries();
  const haveCountries = countries?.length;
  const countrySelector = useRef();
  const [enablePrompt, setEnablePrompt] = useState(false);
  const [customerCountry, setCustomerCountry] = useState();
  const settings = useSettings();
  const modalDismissed =
    Cookies.get('country-selector_modal-dismissed') || false;
  const countryChanged =
    Cookies.get('country-selector_country-changed') || false;
  const [selectedCountry, setSelectedCountry] = useState();

  const getCountry = (isoCode = 'US') => {
    return countries.find((loopCountry) => loopCountry.isoCode === isoCode);
  };

  const getCustomerCountry = async () => {
    const customerIsoCode = Cookies.get('country-selector_customer-country');
    if (customerIsoCode !== undefined) {
      setCustomerCountry(customerIsoCode);
      const newCountry = countries?.find(
        (loopCountry) => loopCountry.isoCode === customerIsoCode
      );
      setSelectedCountry(newCountry);
    } else {
      const resolvedCountry = await fetch(
        'https://geo-location.chauncey-bad.workers.dev/'
      )
        .then((response) => response.json())
        .then((response) => response);
      Cookies.set('country-selector_customer-country', resolvedCountry.country);
      const newCountry = countries?.find((loopCountry) => {
        return loopCountry.isoCode === resolvedCountry.country;
      });
      setCustomerCountry(resolvedCountry.country);
      setSelectedCountry(newCountry);
    }
  };

  const closeModal = () => {
    setEnablePrompt(false);
    Cookies.set('country-selector_modal-dismissed', true);
  };

  const changeSelectedCountry = (ev) => {
    const countryCode = ev.target.value;
    const newCountry = getCountry(countryCode);
    setSelectedCountry(newCountry);
  };

  const changeCountry = async (countryCode) => {
    const newCountry = getCountry(countryCode);
    await updateCountry({ country: newCountry });
    if (enablePrompt) {
      closeModal();
    } else {
      Cookies.set('country-selector_country-changed', true);
    }
  };

  const replaceVariables = (text) => {
    let updatedText = text;
    updatedText = updatedText.replace(
      /\\\[currentCountry\\\]|\[currentCountry\]/g,
      country?.name
    );
    updatedText = updatedText.replace(
      /\\\[storeCurrency\\\]|\[storeCurrency\]/g,
      `${country?.currency?.isoCode} ${country?.currency?.symbol}`
    );
    updatedText = updatedText.replace(
      /\\\[customerCountry\\\]|\[customerCountry\]/g,
      selectedCountry?.name
    );
    updatedText = updatedText.replace(
      /\\\[customerCurrency\\\]|\[customerCurrency\]/g,
      `${selectedCountry?.currency?.isoCode} ${selectedCountry?.currency?.symbol}`
    );
    return updatedText;
  };

  useEffect(() => {
    getCustomerCountry();
  }, []);

  useEffect(() => {
    if (
      countryChanged ||
      !customerCountry ||
      countries?.length === 0 ||
      !country
    ) {
      return () => {};
    }

    if (customerCountry !== country?.isoCode) {
      if (settings?.geoLocalization?.enableNotification === true) {
        setSelectedCountry(getCountry(customerCountry));
        setEnablePrompt(true);
      } else {
        const countryData = getCountry(customerCountry);
        changeCountry(countryData);
      }
    }
  }, [haveCountries, country, customerCountry, settings]);

  return (
    enablePrompt &&
    !modalDismissed && (
      <div className="geolocation-modal-backdrop fixed z-[5000] flex h-full w-full items-center justify-center before:absolute before:h-full before:w-full before:bg-black before:opacity-50">
        <div className="geolocation-modal z-1 w-[530px] max-w-[90%] bg-white px-5 pt-5 pb-10 text-center">
          <div className="modal-header text-right">
            <button
              type="button"
              className="backInStockModalClose"
              onClick={closeModal}
            >
              <IconClose />
            </button>
          </div>

          <h3>{replaceVariables(settings?.geoLocalization?.modalTitle)}</h3>
          <div className="modalBody">
            <ReactMarkdown
              rehypePlugins={[
                rehypeRaw,
                [wrap, { wrapper: 'ul.text-left.pl-0', selector: 'ul' }],
              ]}
            >
              {replaceVariables(settings?.geoLocalization?.modalBody)}
            </ReactMarkdown>
          </div>
          <div>
            <div className="select-wrap relative flex flex-col">
              <label
                className="sub-small mb-2 text-left font-gothamBold"
                htmlFor="modal-country-selector"
              >
                Select country
              </label>
              <select
                className="w-full cursor-pointer border border-grey-5 py-3 px-4 text-black placeholder-black transition-all placeholder-shown:border-grey-5 hover:border-black hover:placeholder-black focus:placeholder-black focus:outline-ada-outline"
                name="modal-country-selector"
                id="modal-country-selector"
                ref={countrySelector}
                onChange={changeSelectedCountry}
                defaultValue={customerCountry}
              >
                {countries.map((loopCountry) => {
                  return (
                    <option
                      key={loopCountry.isoCode}
                      value={loopCountry.isoCode}
                    >
                      {`${loopCountry.name} (${loopCountry.currency.isoCode} ${loopCountry.currency.symbol})`}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="mt-5 flex flex-col items-center">
            <button
              type="button"
              onClick={() => {
                if (selectedCountry?.isoCode === country?.isoCode) {
                  closeModal();
                } else {
                  changeCountry(countrySelector.current.value);
                }
              }}
              className="button button--primary"
            >
              {selectedCountry?.isoCode === country?.isoCode
                ? 'Continue'
                : 'Change Country'}
            </button>
          </div>
        </div>
      </div>
    )
  );
}

