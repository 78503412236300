export function ThinArrowLeft(props) {
  return (
    <svg
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6.68421 13L1 7L6.68421 1" stroke="currentColor" />
    </svg>
  );
}