import Link from 'next/link';
import { useCart } from '@backpackjs/storefront';
import { newLineToBr, getApplicableOverride } from '@/utils/Helper';

export function FullWidthText({ cms }) {
  const cart = useCart();

  let { sectionTitle, sectionDescription, cta1, cta2, bgColor, lightText } =
    cms;
  const { overrides } = cms;
  const applicableOverride = getApplicableOverride(cart, overrides);

  if (applicableOverride?.length) {
    sectionTitle = applicableOverride[0]?.sectionTitle;
    sectionDescription = applicableOverride[0].sectionDescription;
    cta1 = applicableOverride[0].cta1;
    cta2 = applicableOverride[0].cta2;
    bgColor = applicableOverride[0].bgColor;
    lightText = applicableOverride[0].lightText;
  }

  return (
    <section
      id={`${FullWidthText.Schema.key}-${cms.id || cms.cmsId || cms.tinaId}`}
      data-comp={FullWidthText.key}
      className={bgColor}
    >
      <style>
        {`#${FullWidthText.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
            margin-top: ${cms.mobileSpacing}px;
          }
          @media(min-width: 1024px) {
            #${FullWidthText.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
              margin-top: ${cms.desktopSpacing}px;
            }
          }`}
      </style>
      <div
        className={`mx-auto max-w-[1200px] py-9 px-5 text-center lg:py-16 ${
          lightText ? 'text-white' : 'text-black'
        }`}
      >
        <h2 className={`font-tiempos ${cms?.fontSize} mb-4`}>
          {' '}
          {sectionTitle}
        </h2>
        {sectionDescription && (
          <p
            className="mb-0 text-h6-m lg:text-h6"
            dangerouslySetInnerHTML={{
              __html: newLineToBr(sectionDescription),
            }}
          />
        )}
        {(cta1?.link?.text || cta2?.link?.text) && (
          <div className="mt-6 flex flex-wrap items-center justify-center gap-5 lg:mt-7">
            {cta1?.link?.text && cta1?.link?.url && (
              <Link href={cms.cta1.link.url}>
                <a
                  className={`${cta1.style} no-underline`}
                  target={cta1.link.newTab ? '_blank' : null}
                >
                  {cta1.link.text}
                </a>
              </Link>
            )}
            {cta2?.link?.text && cta2?.link?.url && (
              <Link href={cta2.link.url}>
                <a
                  className={`${cta2.style} no-underline`}
                  target={cta2.link.newTab ? '_blank' : null}
                >
                  {cta2.link.text}
                </a>
              </Link>
            )}
          </div>
        )}
      </div>
    </section>
  );
}

FullWidthText.displayName = 'Full Width Text';

FullWidthText.Schema = {
  category: 'Modules',
  label: FullWidthText.displayName,
  key: 'fullWidthText',
  fields: [
    {
      component: 'number',
      name: 'mobileSpacing',
      label: 'Section Top Margin - Mobile',
      defaultValue: 60,
    },
    {
      component: 'number',
      name: 'desktopSpacing',
      label: 'Section Top Margin - Desktop',
      defaultValue: 80,
    },
    {
      component: 'text',
      label: 'Section Title',
      name: 'sectionTitle',
    },
    {
      component: 'textarea',
      label: 'Section Description',
      name: 'sectionDescription',
    },
    {
      label: 'CTA 1',
      name: 'cta1',
      component: 'group',
      fields: [
        {
          label: 'CTA URL',
          name: 'link',
          component: 'link',
        },
        {
          component: 'select',
          name: 'style',
          label: 'Cta Style',
          options: [
            { label: 'Primary', value: 'button button--primary inline-block' },
            {
              label: 'Secondary',
              value: 'button button--secondary inline-block',
            },
            {
              label: 'Tertiary',
              value: 'button button--tertiary inline-block',
            },
            { label: 'Focus Link', value: 'focus-link' },
            { label: 'Focus Link White', value: 'focus-link-white' },
            {
              label: 'checkout',
              value: 'button button--checkout inline-block',
            },
          ],
          defaultValue: 'button button--primary inline-block',
        },
      ],
    },
    {
      label: 'CTA 2',
      name: 'cta2',
      component: 'group',
      fields: [
        {
          label: 'CTA URL',
          name: 'link',
          component: 'link',
        },
        {
          component: 'select',
          name: 'style',
          label: 'Cta Style',
          options: [
            { label: 'Primary', value: 'button button--primary inline-block' },
            {
              label: 'Secondary',
              value: 'button button--secondary inline-block',
            },
            {
              label: 'Tertiary',
              value: 'button button--tertiary inline-block',
            },
            { label: 'Focus Link', value: 'focus-link' },
            { label: 'Focus Link White', value: 'focus-link-white' },
            {
              label: 'checkout',
              value: 'button button--checkout inline-block',
            },
          ],
          defaultValue: 'button button--primary inline-block',
        },
      ],
    },
    {
      name: 'bgColor',
      label: 'Background Color',
      component: 'select',
      description:
        'For section background color, select one from preselected brand colors.',
      options: [
        { label: 'White', value: 'bg-white' },
        { label: 'Grey', value: 'bg-grey-1' },
        { label: 'Light Grey', value: 'bg-grey-9' },
        { label: 'Blue 1', value: 'bg-quinary-1' },
        { label: 'Blue 2', value: 'bg-quinary-2' },
        { label: 'Blue 3', value: 'bg-light-blue' },
        { label: 'Cream 1', value: 'bg-tertiary-2' },
        { label: 'Cream 2', value: 'bg-senary-4' },
        { label: 'Purple', value: 'bg-tertiary-1' },
        { label: 'Green', value: 'bg-quaternary-1' },
        { label: 'Pink 1', value: 'bg-primary-2' },
        { label: 'Pink 2', value: 'bg-secondary-1' },
        { label: 'Yellow', value: 'bg-yellow' },
        { label: 'Orange', value: 'bg-secondary-2' },
        { label: 'Seafoam 1', value: 'bg-quaternary-2' },
        { label: 'Seafoam 2', value: 'bg-senary-3' },
        { label: 'Seafoam 3', value: 'bg-senary-5'},
        { label: 'Air Dry Cream', value: 'bg-stylers-1' },
        { label: 'Sculpting Cream', value: 'bg-stylers-2' },
        { label: 'Texture Foam', value: 'bg-stylers-3' },
        { label: 'Sleek Stick', value: 'bg-stylers-4' }
      ],
      defaultValue: 'bg-white',
    },
    {
      name: 'lightText',
      label: 'Text Color',
      component: 'toggle',
      toggleLabels: {
        true: 'Light',
        false: 'Dark',
      },
    },
    {
      name: 'fontSize',
      label: 'Title Font Size',
      component: 'select',
      options: [
        { label: 'H1', value: 'text-h1-m lg:text-h1' },
        { label: 'H2', value: 'text-h2-m lg:text-h2' },
        { label: 'H3', value: 'text-h3-m lg:text-h3' },
      ],
      defaultValue: 'text-h1-m lg:text-h1',
    },
    {
      component: 'group-list',
      name: 'overrides',
      label: 'Full Width Text overrides',
      description:
        'Describes the text cases when a discount code is applied on the cart',
      fields: [
        {
          component: 'text',
          name: 'discountCode',
          label: 'Discount code reference',
          description:
            'Discount code that needs to be present on the cart in order to trigger this override',
        },
        {
          name: 'sectionTitle',
          label: 'Section Title',
          component: 'text',
          defaultValue: 'Clean chemistry. Healthy hair.',
        },
        {
          name: 'sectionDescription',
          label: 'Section Description',
          component: 'textarea',
          defaultValue:
            'We create powerful, naturally-derived solutions that are proven to improve hair’s health, for every type and texture.',
        },
        {
          label: 'CTA 1',
          name: 'cta1',
          component: 'group',
          fields: [
            {
              label: 'CTA URL',
              name: 'link',
              component: 'link',
            },
            {
              component: 'select',
              name: 'style',
              label: 'Cta Style',
              options: [
                {
                  label: 'Primary',
                  value: 'button button--primary inline-block',
                },
                {
                  label: 'Secondary',
                  value: 'button button--secondary inline-block',
                },
                {
                  label: 'Tertiary',
                  value: 'button button--tertiary inline-block',
                },
                { label: 'Focus Link', value: 'focus-link' },
                { label: 'Focus Link White', value: 'focus-link-white' },
                {
                  label: 'checkout',
                  value: 'button button--checkout inline-block',
                },
              ],
              defaultValue: 'button button--primary inline-block',
            },
          ],
        },
        {
          label: 'CTA 2',
          name: 'cta2',
          component: 'group',
          fields: [
            {
              label: 'CTA URL',
              name: 'link',
              component: 'link',
            },
            {
              component: 'select',
              name: 'style',
              label: 'Cta Style',
              options: [
                {
                  label: 'Primary',
                  value: 'button button--primary inline-block',
                },
                {
                  label: 'Secondary',
                  value: 'button button--secondary inline-block',
                },
                {
                  label: 'Tertiary',
                  value: 'button button--tertiary inline-block',
                },
                { label: 'Focus Link', value: 'focus-link' },
                { label: 'Focus Link White', value: 'focus-link-white' },
                {
                  label: 'checkout',
                  value: 'button button--checkout inline-block',
                },
              ],
              defaultValue: 'button button--primary inline-block',
            },
          ],
        },
        {
          name: 'bgColor',
          label: 'Background Color',
          component: 'select',
          description:
            'For section background color, select one from preselected brand colors.',
          options: [
            { label: 'White', value: 'bg-white' },
            { label: 'Grey', value: 'bg-grey-1' },
            { label: 'Light Grey', value: 'bg-grey-9' },
            { label: 'Blue 1', value: 'bg-quinary-1' },
            { label: 'Blue 2', value: 'bg-quinary-2' },
            { label: 'Blue 3', value: 'bg-light-blue' },
            { label: 'Cream 1', value: 'bg-tertiary-2' },
            { label: 'Cream 2', value: 'bg-senary-4' },
            { label: 'Purple', value: 'bg-tertiary-1' },
            { label: 'Green', value: 'bg-quaternary-1' },
            { label: 'Pink 1', value: 'bg-primary-2' },
            { label: 'Pink 2', value: 'bg-secondary-1' },
            { label: 'Yellow', value: 'bg-yellow' },
            { label: 'Orange', value: 'bg-secondary-2' },
            { label: 'Seafoam 1', value: 'bg-quaternary-2' },
            { label: 'Seafoam 2', value: 'bg-senary-3' },
{ label: 'Seafoam 3', value: 'bg-senary-5'},
            { label: 'Air Dry Cream', value: 'bg-stylers-1' },
            { label: 'Sculpting Cream', value: 'bg-stylers-2' },
            { label: 'Texture Foam', value: 'bg-stylers-3' },
            { label: 'Sleek Stick', value: 'bg-stylers-4' }
          ],
          defaultValue: 'bg-white',
        },
        {
          name: 'lightText',
          label: 'Text Color',
          component: 'toggle',
          toggleLabels: {
            true: 'Light',
            false: 'Dark',
          },
        },
      ],
    },
  ],
};
