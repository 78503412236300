import { useState, useEffect } from 'react';

export const useProductsFromHandles = ({ handles } = { handles: null }) => {
  if (!handles) throw new Error('[handles] prop not provided');

  const [products, setProducts] = useState({});
  const [fetched, setFetched] = useState(false);

  const fetchBundleProductsFromHandles = () => {
    let unMounting;

    const fetchProductFromHandle = (handle) => {
      // if we have already fetched return it, else fetch it
      return (
        products?.[handle] ??
        fetch(`/json/products/${handle}.json`)
          .then((response) => response.json())
          .catch((error) => {
            console.error('Problem fetching', handle, error.message);
            return { handle };
          })
      );
    };

    const bundleProductsPromises = handles.map(fetchProductFromHandle);

    const setBundleProducts = (_products) => {
      if (!_products?.length) {
        // didn't fetch
        if (unMounting) return;
        setFetched(true);
        return;
      }

      // have fetched products
      if (unMounting) return;
      setFetched(true);

      // products setState updater function
      const mergedProducts = (prevProducts) => {
        const newProducts = _products.reduce((byHandle, product) => {
          byHandle[product.handle] = product;
          return byHandle;
        }, {});

        return {
          ...prevProducts,
          ...newProducts,
        };
      };

      if (unMounting) return;
      setProducts(mergedProducts);
    };

    Promise.all(bundleProductsPromises).then(setBundleProducts);

    return () => {
      unMounting = true;
    };
  };

  useEffect(fetchBundleProductsFromHandles, [handles?.join('|')]);

  return {
    products,
    fetched,
    success: Object.keys(products).length === handles.length,
  };
};
