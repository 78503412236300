import { useEffect, useRef, useState, useMemo } from 'react';
import {
  useCartAddItem,
  useProductInventoryByHandle,
  useSettings,
  useCountry,
} from '@backpackjs/storefront';
import Link from 'next/link';
import ProductGallery from '@/snippets/products/ProductGallery';
import ProductInformation from '@/snippets/products/ProductInformation';
import { IconClose } from '../..';
import ProductPrice from './ProductPrice';
import { ProductOptions } from '@/snippets/components/productOptions';
import { useWindowDimensions } from '@/utils/Helper';
import { getMetafieldMap } from '@/utils/Metafields';
import DarkCircleCheckmark from '@/snippets/icons/DarkCircleCheckmark';

export function QuickViewModal() {
  const settings = useSettings();
  const [isOpen, setIsOpen] = useState(false);
  const [product, setProduct] = useState(null);
  const [selectedPlanId, setSelectedPlanId] = useState(null);
  const { cartAddItem } = useCartAddItem();
  const { inventory } = useProductInventoryByHandle({
    handle: product?.handle || 'empty',
  });
  const metafieldMap = useMemo(() => {
    return getMetafieldMap(product);
  }, [product?.id]);
  const promo = metafieldMap.promo_message;
  const modalFooter = useRef(null);
  const { vpWidth } = useWindowDimensions();
  const { country } = useCountry();
  const countryCode = country?.isoCode || 'US';
  const isUs = countryCode === 'US';
  const firstUpsellProduct = metafieldMap.product_upsell_1;
  const isComingSoon = metafieldMap.coming_soon;

  const getInitialVariant = (product) => {
    if (!product) return null;

    //! We will find the first avaiable, and set that as the variant
    const firstAvailableVariant = product.variants.find(
      ({ availableForSale }) => availableForSale
    );

    if (firstAvailableVariant) {
      return firstAvailableVariant;
    }
    if (product?.variants?.length) {
      return product.variants[0];
    }
  };

  const [currentVariant, setCurrentVariant] = useState(
    getInitialVariant(product)
  );

  const openModal = (evt) => {
    setProduct(evt.detail.product);
    setIsOpen(true);
  };

  const closeModal = () => {
    setProduct(null);
    setIsOpen(false);
    setSelectedPlanId(null);
  };

  const onVariantChange = (variant) => {
    setCurrentVariant(variant);
  };

  const buildValueProps = () => {
    const valueProps = [];

    for (let i = 1; i <= 3; i++) {
      const index = `subscription_value_prop_${i}`;
      const propValue = metafieldMap[index];

      if (propValue) {
        valueProps.push(
          <div className="flex">
            <span className="mr-4 lg:mr-2">
              <DarkCircleCheckmark />
            </span>
            <p className="text-small mb-0">{propValue}</p>
          </div>
        );
      }
    }

    return (
      <div className="mt-8 !hidden flex-col gap-2 md:!flex lg:flex-row justify-between">
        {valueProps}
      </div>
    );
  };

  if (typeof window !== 'undefined') {
    const observer = new IntersectionObserver(
      ([e]) => {
        let action = 'remove';
        if (e.intersectionRatio < 1) {
          action = 'add';
        }
        e.target.classList.toggle('mt-4', e.intersectionRatio === 1);
        e.target.classList[action](
          'border-t',
          'border-black',
          'pt-4',
          'px-6',
          '-mx-6'
        );
      },
      { threshold: [1] }
    );
  }

  useEffect(() => {
    document.addEventListener('quickViewModal:open', openModal);

    return () => {
      document.removeEventListener('quickViewModal:open', openModal);
    };
  });

  useEffect(() => {
    setCurrentVariant(getInitialVariant(product));
    if (typeof window !== 'undefined' && typeof observer !== 'undefined' && modalFooter.current) {
      observer.observe(modalFooter.current);
    }
  }, [product]);

  useEffect(() => {
    let action = 'remove';
    if (isOpen) {
      action = 'add';
    }
    document.querySelector('body').classList[action]('overflow-hidden');
  }, [isOpen]);

  const hasInventory =
    !product?.tracksInventory || (inventory?.totalInventory || 0) > 0;
  const variantIsAvailable =
    (inventory?.variants[currentVariant?.id]?.availableForSale &&
      hasInventory) ||
    !product?.tracksInventory;

  const selectedPlan = currentVariant?.sellingPlanAllocations?.find(
    (plan) => plan?.sellingPlan.id === selectedPlanId
  );

  const firstPlan = currentVariant?.sellingPlanAllocations?.[0];
  const planAmount =
    selectedPlan?.priceAdjustments?.[0]?.price?.amount ||
    firstPlan?.priceAdjustments?.[0]?.price?.amount;
  const planPrice = planAmount ? parseFloat(planAmount).toFixed(2) : null;

  const planOptions = currentVariant?.sellingPlanAllocations?.map((plan) => ({
    label: plan?.sellingPlan?.options?.[0]?.value,
    value: plan?.sellingPlan?.id,
  }));

  useEffect(() => {
    if (currentVariant) {
      const { search } = window.location;
      const searchParams = new URLSearchParams(search);
      const planId = searchParams.get('selling_plan');
      const activePlan = currentVariant?.sellingPlanAllocations?.find(
        (plan) => {
          return plan.sellingPlan.id.includes(planId);
        }
      );
      if (activePlan) setSelectedPlanId(planId);

      if(product.enableSubscription && currentVariant?.sellingPlanAllocations.length) {
        setSelectedPlanId(currentVariant?.sellingPlanAllocations?.[0]?.sellingPlan?.id);
      }
    }
  }, [currentVariant]);

  let compareValue = currentVariant?.compareAtPriceV2?.amount || 0;
  let variantPrice = currentVariant?.priceV2.amount || 0;
  if (selectedPlan) {
    compareValue = currentVariant?.compareAtPriceV2?.amount || selectedPlan?.priceAdjustments?.[0]?.compareAtPrice?.amount;
    variantPrice = selectedPlan?.priceAdjustments?.[0]?.price?.amount;
  }


  let discountValue = null;

  if (compareValue > 0) {
    discountValue = Math.floor(((compareValue - variantPrice) / compareValue) * 100);
  }

  const discountHighlight =
    settings?.discountHighlight?.enableDiscountHighlight &&
    settings?.discountHighlight?.pdpDiscountMessage &&
    discountValue !== 0 &&
    discountValue !== null;

  useEffect(() => {
    if (vpWidth < 1023) {
      closeModal();
    }
  }, [vpWidth]);
  return (
    <div
      className={`QuickViewModal fixed top-0 left-0 z-[10000] flex h-full w-full items-center justify-center ${
        isOpen
          ? 'pointer-events-auto opacity-100'
          : 'pointer-events-none opacity-0'
      }`}
    >
      <div className="modal-body relative z-[101] mx-auto h-full w-[1075px] max-w-[80%] justify-self-center bg-white  p-6 pr-0 pb-12 md:max-h-[590px] md:overflow-y-hidden">
        <div className="modal-header mb-2 w-full pr-4 text-right">
          <button
            type="button"
            className="quickViewModalClose relative -top-2"
            onClick={closeModal}
          >
            <IconClose />
          </button>
        </div>
        {product && (
          <div className="modal-body-content quickview-modal-content-container flex h-full">
            <div className="w-6/12">
              <ProductGallery product={product} quickView />
            </div>
            <div className="h-full w-6/12 overflow-y-auto overflow-x-hidden border-b-transparent px-6 text-left">
              <ProductInformation product={product} />
              {!discountHighlight && promo && isUs && (
                <p className="sub-xsmall md:sub-small text-pink mb-4 quickview-promo-message">{promo}</p>
              )}
              {discountHighlight && (
                <p className="sub-xsmall md:sub-small text-pink mb-4 quickview-discount-message">
                  {settings?.discountHighlight?.pdpDiscountMessage.replace(
                    '[discount]',
                    `${discountValue}%`
                  )}
                </p>
              )}
              <ProductOptions
                product={product}
                updateUrl={false}
                onVariantChange={onVariantChange}
                activeVariant={currentVariant}
              />
              {isUs &&
                currentVariant?.sellingPlanAllocations &&
                currentVariant?.sellingPlanAllocations?.length > 0 && (
                  <div className="mb-7 overflow-hidden border">
                    <button
                      className={`grid w-full grid-cols-[auto_auto_1fr] border-b px-[12px] py-[15px] md:px-[20px] quickViewATC__onetime bg-[#F5F5F5]  ${
                        !selectedPlanId && 'bg-[#FFFFFF]'
                      }`}
                      type="button"
                      onClick={() => {
                        setSelectedPlanId(null);
                        window.dataLayer.push({
                          'event': 'Quick View - One time select'
                        });
                      }}
                    >
                      <div className="relative top-[2px] mr-[10px] h-[18px] w-[18px] cursor-pointer rounded-full border md:top-0 md:mr-[12px] md:h-[20px] md:w-[20px]">
                        {!selectedPlanId && (
                          <div className="relative top-[2px] left-[2px] h-[12px] w-[12px] rounded-full bg-grey-1 md:h-[14px] md:w-[14px]" />
                        )}
                      </div>
                      <p className="mb-0 font-gothamBold text-[14px]">
                        One-time purchase
                      </p>
                      <span className="ml-auto font-gothamBold text-[14px]">{`$${currentVariant?.price?.replace(
                        '.00',
                        ''
                      )}`}</span>
                    </button>

                    <div className={`${selectedPlanId ? 'bg-[#FFFFFF]' : 'bg-[#F5F5F5]'}`}>
                      <button
                        className="grid w-full grid-cols-[auto_auto_1fr] px-[12px] py-[15px] md:px-[20px] quickViewATC__subscription"
                        onClick={() =>{
                          setSelectedPlanId(firstPlan?.sellingPlan?.id);
                          window.dataLayer.push({
                            'event': 'Quick View - Subscribe & Save select'
                          });
                        }}
                        type="button"
                      >
                        <div className="relative top-[2px] mr-[10px] h-[18px] w-[18px] cursor-pointer rounded-full border md:top-0 md:mr-[12px] md:h-[20px] md:w-[20px]">
                          {selectedPlanId && (
                            <div className="relative top-[2px] left-[2px] h-[12px] w-[12px] rounded-full bg-grey-1 md:h-[14px] md:w-[14px]" />
                          )}
                        </div>
                        <div>
                          <p className="mb-0 font-gothamBold text-[14px]">
                            <span className="whitespace-nowrap">
                              {firstPlan?.sellingPlan?.priceAdjustments[0]?.adjustmentValue ? (
                                `Subscribe & save ${firstPlan?.sellingPlan?.priceAdjustments[0].adjustmentValue?.adjustmentPercentage}%`
                              ): (
                                `Subscribe`
                              )}
                            </span>
                            {settings?.subscriptions?.freeShippingText && (
                            <span className="ml-[20px] hidden whitespace-nowrap bg-[#E61A4F] px-[7px] py-[4px] text-[11px] text-white xl:inline">
                              {settings?.subscriptions?.freeShippingText ||
                                'Free Shipping'}
                            </span>
                            )}
                          </p>
                          {settings?.subscriptions?.freeShippingText && (
                          <span className="whitespace-nowrap bg-[#E61A4F] px-[7px] py-[4px] font-gothamBold text-[11px] text-white xl:hidden">
                            {settings?.subscriptions?.freeShippingText ||
                              'Free Shipping'}
                          </span>
                          )}
                        </div>
                        {planPrice ? (

                          <div className="ml-auto">
                            <span className="product-original-price ml-4 font-gothamBold text-[14px] text-[#757575] line-through">
                              {`$${currentVariant.price?.replace('.00', '')}`}
                            </span>
                            <span className="ml-2 font-gothamBold text-[14px]">
                              {`$${planPrice}`}
                            </span>
                          </div>
                        ):(
                          <div className="ml-auto">
                            <span className="font-gothamBold text-[14px]">
                              {`$${currentVariant.price?.replace('.00', '')}`}
                            </span>
                          </div>
                        )}
                      </button>
                      {selectedPlanId && (
                        <div className="px-[12px] pb-[15px] md:px-[20px]">
                          <div className="mb-[8px] flex items-end whitespace-nowrap">
                            <span className="text-[12px] text-black">
                              Ships every:
                            </span>
                            <div className="select-wrap-2 relative flex flex-col">
                              <select
                                className="ml-2 cursor-pointer border-0 border-b bg-transparent px-2 pb-0 pr-4 pt-0 font-gothamBold text-[12px] text-black text-black placeholder-black outline-0 hover:placeholder-black focus:placeholder-black"
                                name="selling-plan-select"
                                id="selling-plan-select"
                                value={selectedPlanId}
                                onChange={(e) => {
                                  setSelectedPlanId(e.target.value);
                                }}
                              >
                                {planOptions.map((option, optionIndex) => (
                                  <option
                                    key={`option-selling-plan-select-${optionIndex}`}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="mt-[10px] flex items-baseline whitespace-nowrap">
                            <span className="text-[10px] text-black ">
                              {settings?.subscriptions?.cancelText ||
                                'Cancel anytime + membership perks.'}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              <div
                className="quick-view__footer sticky bottom-[-2px] mt-6 bg-white"
                ref={modalFooter}
              >
                {variantIsAvailable ? (
                  <button
                    type="button"
                    className="button button--checkout flex w-full justify-center md:px-4 md:text-[12px] lg:text-[16px]"
                    onClick={async () => {
                      try {
                        // custom attribute needed for Pack to properly serparate line items in the cart
                        const addItem = selectedPlanId
                          ? {
                              merchandiseId: currentVariant.id,
                              sellingPlanId: btoa(selectedPlanId),
                              quantity: 1,
                              attributes: [
                                { key: '_sellingPlan', value: selectedPlanId },
                              ],
                            }
                          : {
                              merchandiseId: currentVariant.id,
                              quantity: 1,
                              attributes: [],
                            };
                        if (firstUpsellProduct) {
                          addItem.attributes.push({
                            key: '_upsellProduct',
                            value: firstUpsellProduct,
                          });
                        }
                        if(typeof window.ShipScoutLineItemProperty !== 'undefined') {
                          addItem.attributes.push({
                            key: '_sc',
                            value: window.ShipScoutLineItemProperty,
                          });
                        }

                        await cartAddItem(addItem, () => {
                          window.dispatchEvent(new CustomEvent('openSideCart'));
                          closeModal();

                          if (selectedPlanId) {
                            window.dataLayer.push({
                              'event': 'Quick View - Add_to_cart Subscribe & Save'
                            });
                          } else {
                            window.dataLayer.push({
                              'event': 'Quick View - Add_to_cart One Time'});
                          }
                        });
                      } catch (error) {
                        console.error(error);
                        alert(
                          'Something whent wrong while adding to cart, please try again.'
                        );
                      }
                    }}
                  >
                    <span className="font-gotham">add to cart</span>
                    <ProductPrice
                      variant={currentVariant}
                      className="ml-4 !text-base !text-currentcolor"
                      compareStyles="!text-currentcolor !mr-2"
                      selectedPlan={selectedPlan}
                      planPrice={planPrice}
                    />
                  </button>
                ) : (
                  <button
                    type="button"
                    disabled
                    className="button button--primary flex w-full justify-center md:px-4 md:text-[12px] lg:text-[16px]"
                  >
                    {isComingSoon !== 'true' ? (
                      <span className="font-gotham">
                        {hasInventory ? 'unavilable' : 'out of stock'}
                      </span>
                    ) : (
                      <span className="font-gotham">Coming Soon</span>
                    )}
                  </button>
                )}
                <div className="mt-2 text-center">
                  <Link
                    href={`/products/${product.handle}${
                      isUs === true && product.enableSubscription === true
                        ? '?subscription'
                        : ''
                    }`}
                  >
                    <a
                      className="focus-link text-sm"
                      onClick={() => {
                        closeModal();
                      }}
                    >
                      more details
                    </a>
                  </Link>
                </div>
                {isUs === true &&
                  product.enableSubscription === true &&
                  buildValueProps()}
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        className={`modalBackdrop z-103 fixed top-0 left-0 h-full w-full cursor-pointer bg-black ${
          isOpen ? 'pointer-events-auto opacity-50' : 'opacity-0'
        }`}
        onClick={closeModal}
      >
        <span
          className="sr-only"
          onClick={() => {
            closeModal();
          }}
        >
          Close Quick View
        </span>
      </div>
    </div>
  );
}