import Script from 'next/script';

export function FueledAccessibleScript() {
  return (
    <Script
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
        (function(){ var s = document.createElement('script'), e = ! document.body ? document.querySelector('head') : document.body; s.src = 'https://acsbapp.com/apps/app/dist/js/app.js'; s.async = true; s.onload = function(){ acsbJS.init({ statementLink : '', footerHtml : '', hideMobile : false, hideTrigger : false, language : 'en', position : 'left', leadColor : '#36454f', triggerColor : '#ffb6c1', triggerRadius : '50%', triggerPositionX : 'left', triggerPositionY : 'bottom', triggerIcon : 'people', triggerSize : 'small', triggerOffsetX : 20, triggerOffsetY : 80, mobile : { triggerSize : 'small', triggerPositionX : 'right', triggerPositionY : 'bottom', triggerOffsetX : 10, triggerOffsetY : 80, triggerRadius : '50%' } }); }; e.appendChild(s);}());
        `
      }}
    />
  )
}
