export function Filters() {
  return (
    <svg
      width="15"
      height="10"
      viewBox="0 0 15 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.54167 9.5V7.91667H8.70833V9.5H5.54167ZM2.375 5.54167V3.95833H11.875V5.54167H2.375ZM0 1.58333V0H14.25V1.58333H0Z"
        fill="currentcolor"
      />
    </svg>
  );
}
