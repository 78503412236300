import { useMemo } from 'react';
import { FueledAccessibleScript } from './FueledAccessibleScript';
import { FueledBingScript } from './FueledBingScript';
import { FueledClientScript } from './FueledClientScript';
import { FueledGtmScript } from './FueledGtmScript';
import { FueledImpactScript } from './FueledImpactScript';
import { FueledKlaviyoScript } from './FueledKlavyioScript';
import { FueledMntnScript } from './FueledMntnScript';
//import { FueledPinterestScript } from './FueledPinterestScript';
// import { FueledTikTokScript } from './FueledTikTokScript';
import { FueledCuVimeoTracking } from './FueledCuVimeoTracking';

const gtmContainerId = 'GTM-WPGVRLTQ';
const bingId = '5797150';
const impactPixelId = 'A1806740-b579-4dc0-ad40-78a08792fad51';
const klaviyoId = 'H6yLPD';
const mntnPixelId = '34984';
//const pinterestPixelId = '2612642693140';
// const tiktokPixelId = 'C594AM16C8J46GAV3TH0';

export const Fueled = () => {
  // const userCanBeTracked = useTrackingConsent();

  const scripts = useMemo(
    () => [
      { Component: FueledAccessibleScript },
      { withKey: gtmContainerId, Component: FueledGtmScript },
      { withKey: bingId, Component: FueledBingScript },
      { withKey: impactPixelId, Component: FueledImpactScript },
      { withKey: klaviyoId, Component: FueledKlaviyoScript },
      { withKey: mntnPixelId, Component: FueledMntnScript },
      //{ withKey: pinterestPixelId, Component: FueledPinterestScript },
      // { withKey: tiktokPixelId, Component: FueledTikTokScript },
      { Component: FueledCuVimeoTracking },
    ],
    []
  );

  // if (!userCanBeTracked) return null;

  return (
    <>
      <FueledClientScript />
      {/* Uncomment the following line to enable the FueledDlDebug component */}
      {/* <FueledDlDebug /> */}
      {scripts
        .map(({ withKey, Component, key }, index) => (
          <Component withKey={withKey ? withKey : ""} key={key ? key : index} />
        ))}
    </>
  );
};
