import { useBuilderContext } from "./BundleBuildercontext";

export default function BuilderFilters() {
  const context = useBuilderContext();
  const filterList = context.builderSettings.filters;
  const { activeFilter } = context.builderData;


  const filters = filterList.map((filter, index) => {
    const isAll = filter.value === undefined;

    return (
      <div key={filter.name} className="bundle-builder__filter">
        <input
          type="radio"
          id={filter.name}
          name="products-filter"
          value={filter.value || ''}
          onChange={(e) =>
            context.updateBuilderData({
              activeFilter: filter.value || '',
              page: 1,
            })
          }
          defaultChecked={
            isAll && activeFilter === '' ? true : activeFilter === filter.value
          }
          className="peer hidden"
        />
        <label
          htmlFor={filter.name}
          className={`inline-block rounded-full border-2 border-black py-1 px-4 font-gothamBold text-xs transition-all duration-150 ease-in-out
          peer-checked:border-quinary-1 peer-checked:bg-quinary-1 peer-checked:text-white lg:hover:bg-black lg:hover:text-white `}
        >
          {filter.name}
        </label>
      </div>
    );
  });

  if (filters.length === 0) {
    return null;
  }

  return <div className="bundle-builder__filters-container flex flex-wrap gap-2 mb-6">{filters}</div>;
}