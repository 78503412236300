import { useEffect, useCallback, useState } from 'react';

export const useCustomerDetails = ({ email }) => {
  const [customerDetails, setCustomerDetails] = useState(null);

  const fetchCustomerDetails = useCallback(async () => {
    try {
      if (!email) return;

      const endpoint = '/api/yotpo';
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'getCustomerDetails',
          email,
        }),
      };

      const response = await fetch(endpoint, options);
      const data = await response.json();
      if (!data || !data?.response) {
        setCustomerDetails(null);
      }
      setCustomerDetails(data);
      return;
    } catch (error) {
      console.error(`fetchYotpoBottomline error: ${error.message}`);
    }
  }, []);

  useEffect(() => {
    if (!email) return;
    fetchCustomerDetails(email);
  }, [email]);

  return { customerDetails };
};
