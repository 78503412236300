import { useCustomer } from '@backpackjs/storefront';
import { useState, useEffect } from 'react';

function SwellPoints() {
  const customer = useCustomer();
  const [swellCustomer, setSwellCustomer] = useState(null);
  const customerMatches =
    customer && swellCustomer && customer?.email === swellCustomer?.email;

  const refreshSwell = () => {
    if (typeof swellAPI === 'undefined') return;
    if (!customer) {
      setSwellCustomer(null);
      return;
    }

    const customerDetails = swellAPI?.getCustomerDetails();
    setSwellCustomer(customerDetails);

    // swellAPI doesn't refresh the customer data correctly when an old user signs out and a new users signs in
    // force refresh if the customer info doesn't match
    if (
      customer?.email &&
      customerDetails?.email &&
      customer.email !== customerDetails.email
    ) {
      if (typeof location !== 'undefined') location.reload();
    }
  };

  useEffect(() => {
    refreshSwell();
    document.addEventListener('swell:setup', refreshSwell);
    return () => {
      document.removeEventListener('swell:setup', refreshSwell);
    };
  }, [customer]);

  return (
    <>
      {customer && (
        <div
          id="swell-customer-identification"
          data-authenticated="true"
          data-email={customer?.email}
          data-id={customer?.id?.replace('gid://shopify/Customer/', '')}
          data-tags='"[]"'
          style={{ display: 'none' }}
        />
      )}
      <span
        style={{
          display:
            customerMatches && swellCustomer?.pointsBalance ? '' : 'none',
          marginLeft: '5px',
        }}
      >
        (
        <span className="swell-point-balance">
          {swellCustomer?.pointsBalance}
        </span>
        pts)
      </span>
    </>
  );
}

export default SwellPoints;
