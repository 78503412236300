function SortBy(props) {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M5 16V3" stroke="currentColor" strokeWidth="1.5" />
      <path d="M14 2L14 15" stroke="currentColor" strokeWidth="1.5" />
      <path d="M1 6L5 2L9 6" stroke="currentColor" strokeWidth="1.5" />
      <path d="M18 12L14 16L10 12" stroke="currentColor" strokeWidth="1.5" />
    </svg>
  );
}

export default SortBy;