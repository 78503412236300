import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import remarkDirective from 'remark-directive';
import { visit } from 'unist-util-visit';
import { h } from 'hastscript/html.js';
import wrap from 'rehype-wrap';

import styles from './Markdown.module.css';

const components = {
  iframe: ({ node, ...props }) => {
    return props.src.includes('youtube.com') ? (
      <div className="responsive-video pb-youtube">
        <iframe {...props} />
      </div>
    ) : (
      <iframe {...props} />
    );
  },
};

// remark plugin to add a custom tag to the AST
function htmlDirectives() {
  function ondirective(node) {
    const data = node.data || (node.data = {});
    const hast = h(node.name, node.attributes);
    const attributes = node.attributes || {};
    if (node.name !== 'youtube' && node.type !== 'textDirective') {
      data.hName = hast.tagname;
      data.hProperties = hast.properties;
    } else {
      const { id } = attributes;
      data.hName = 'iframe';
      data.hProperties = {
        src: `https://www.youtube.com/embed/${id}`,
        width: '100%',
        height: 200,
        frameBorder: 0,
        allow:
          'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
        allowFullScreen: true,
      };
    }
  }

  function transform(tree) {
    visit(
      tree,
      ['textDirective', 'leafDirective', 'containerDirective'],
      ondirective
    );
  }

  return transform;
}

export function Markdown({ cms }) {
  return (
    <div
      id={`${Markdown.Schema.key}-${cms.id || cms.cmsId || cms.tinaId}`}
      data-comp={Markdown.displayName}
      className={styles.markdownContainer}
    >
      <style>
        {`#${Markdown.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
            margin-top: ${cms.mobileSpacing}px;
          }
          @media(min-width: 1024px) {
            #${Markdown.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
              margin-top: ${cms.desktopSpacing}px;
            }
          }`}
      </style>
      <ReactMarkdown
        remarkPlugins={[remarkDirective, remarkGfm, htmlDirectives]}
        rehypePlugins={[
          rehypeRaw,
          [wrap, { wrapper: 'div.table-wrapper', selector: 'table' }],
        ]}
        components={components}
      >
        {cms?.content}
      </ReactMarkdown>
    </div>
  );
}

Markdown.displayName = 'Markdown';

Markdown.Schema = {
  category: 'Page',
  label: Markdown.displayName,
  key: 'markdown',
  fields: [
    {
      component: 'number',
      name: 'mobileSpacing',
      label: 'Section Top Margin - Mobile',
      defaultValue: 60,
    },
    {
      component: 'number',
      name: 'desktopSpacing',
      label: 'Section Top Margin - Desktop',
      defaultValue: 80,
    },
    {
      name: 'content',
      label: 'Content',
      component: 'markdown',
      description: 'This is a markdown field',
    },
  ],
};
