import { useCallback } from 'react';

import { useLocalizedSelectedVariant } from './useLocalizedSelectedVariant';

export const useLocalizedProducts = () => {
  const { getLocalizedVariant } = useLocalizedSelectedVariant();

  const getLocalizedProductsWithFirstVariant = useCallback(
    async ({ countryCode, products }) => {
      try {
        if (!products?.length) return [];
        if (!countryCode) return products;

        const localizedPromises = products.map(async (product) => {
          if (!product?.variants?.[0]) return null;
          const localizedVariant = await getLocalizedVariant({
            variant: product.variants[0],
            countryCode,
          });
          const amount = localizedVariant.priceV2?.amount;
          const currencyCode = localizedVariant.priceV2?.currencyCode;
          return {
            ...product,
            priceRange: {
              max: amount,
              maxVariantPrice: { amount, currencyCode },
              min: amount,
              minVariantPrice: { amount, currencyCode },
            },
            variants: [localizedVariant],
          };
        });
        return await Promise.all(localizedPromises);
      } catch (error) {
        console.error(
          'getLocalizedProductsWithFirstVariant:error',
          error.message
        );
        return products;
      }
    },
    []
  );

  return { getLocalizedProductsWithFirstVariant };
};
