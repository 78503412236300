import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import styles from './PageErrorBoundary.module.css';

function PageError() {
  return (
    <div
      role="alert"
      style={{
        margin: 0,
        width: '100%',
        height: '100%',
        minWidth: 0,
        padding: '16px',
        boxSizing: 'border-box',
      }}
    >
      <div
        className={styles.skeletonBox}
        style={{
          width: '100%',
          height: '320px',
          marginBottom: '24px',
        }}
      />
      <div
        className={styles.skeletonBox}
        style={{
          width: '100%',
          height: '24px',
          marginBottom: '8px',
        }}
      />
      <div
        className={styles.skeletonBox}
        style={{
          width: '80%',
          height: '24px',
          marginBottom: '8px',
        }}
      />
      <div
        className={styles.skeletonBox}
        style={{
          width: '60%',
          height: '24px',
          marginBottom: '8px',
        }}
      />
      <div
        className={styles.skeletonBox}
        style={{
          width: '60%',
          height: '24px',
          marginBottom: '8px',
        }}
      />
      <div
        className={styles.skeletonBox}
        style={{
          width: '100%',
          height: '24px',
          marginBottom: '24px',
        }}
      />

      <div
        style={{
          display: 'flex',
          gap: '16px',
          marginBottom: '24px',
        }}
      >
        <div
          className={styles.skeletonBox}
          style={{
            width: '100%',
            height: '100%',
            aspectRatio: '1 / 1',
          }}
        />
        <div
          className={styles.skeletonBox}
          style={{
            width: '100%',
            height: '100%',
            aspectRatio: '1 / 1',
          }}
        />
      </div>
      <div
        className={styles.skeletonBox}
        style={{
          width: '100%',
          height: '24px',
          marginBottom: '24px',
        }}
      />
      <div
        className={styles.skeletonBox}
        style={{
          width: '100%',
          height: '320px',
          marginBottom: '24px',
        }}
      />
    </div>
  );
}

export function PageErrorBoundary({ children }) {
  return (
    <ErrorBoundary data-comp="PageErrorBoundary" FallbackComponent={PageError}>
      {children}
    </ErrorBoundary>
  );
}
