import { useEffect, useMemo } from 'react';
import { useProductByHandle } from '@backpackjs/storefront';
import ProductGallery from '@/snippets/products/ProductGallery';
import ProductInformation from '@/snippets/products/ProductInformation';
import { useBuilderContext } from './BundleBuildercontext';
import { Close } from '@/snippets/icons/Close';
import { useWindowDimensions } from '@/utils/Helper';
import { getMetafieldMap } from '@/utils/Metafields';


export default function BuilderQuickView() {
  const context = useBuilderContext();
  const { builderData, builderSettings } = context;
  const { vpWidth } = useWindowDimensions();

  const { product } = useProductByHandle({
    handle: builderData.quickViewProduct,
  });

  const metafieldMap = useMemo(() => {
    return getMetafieldMap(product);
  }, [product?.id]);

  const selectedProduct = builderData.selectedProducts.some((loopProduct) => {
    return loopProduct.handle === builderData.quickViewProduct;
  });

  const closeQuickview = () => {
    context.updateBuilderData({ quickViewProduct: null });
  };

  const updateBundle = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    let { selectedProducts } = builderData;

    if (selectedProduct) {
      selectedProducts = selectedProducts.filter(
        (loopProduct) => loopProduct.handle !== product.handle
      );
    } else {
      selectedProducts.push(product);
    }

    context.updateBuilderData({
      selectedProducts,
      quickViewProduct: null,
     });
  };

  useEffect(() => {
    const currentScroll = window.scrollY;
    document.body.style.setProperty('--topPosition', `-${currentScroll}px`);
    document.body.classList.add('overflow-hidden', 'bg-overlay');

    return () => {
      document.body.classList.remove('overflow-hidden', 'bg-overlay');
      const scrollTop = document.body.style.getPropertyValue('--topPosition');
      if (scrollTop) {
        window.scrollTo({
          top: parseInt(scrollTop.replace('px', '') || '0', 10) * -1,
        });
      }
    };
  }, []);

  if (!product) return null;

  return (
    <>
      <div
        className={`builder__quick-view fixed bottom-0 z-[1000000] h-[90%] w-full overflow-y-auto rounded-t-[30px] bg-white lg:top-1/2
        lg:left-1/2 lg:h-[590px] lg:max-h-[90%] lg:w-[1075px] lg:max-w-[80%] lg:-translate-y-1/2 lg:-translate-x-1/2 lg:rounded-none lg:px-5 lg:pb-12 lg:pt-4`}
      >
        <div className="quick-view__modal-header sticky top-0 z-10 mb-4 border-b border-grey-9 bg-white py-4 px-4 text-right lg:relative lg:border-b-0 lg:px-0 lg:py-0">
          <button
            type="button"
            onClick={closeQuickview}
            className="ml-auto flex items-center gap-1"
          >
            <span className="lg:sr-only font-gothamBold text-sm">close</span>
            <Close className="h-4 w-4" />
          </button>
        </div>
        <div className="quick-view__modal-body flex w-full flex-col px-4 lg:flex-row lg:px-0">
          {vpWidth <= 992 && (
            <ProductInformation
              product={product}
              hideDiscount
            />
          )}
          <div className="-mt-6 lg:mt-0 lg:w-6/12">
            <ProductGallery product={product} quickView bundleBuilder />
          </div>
          <div className="h-full overflow-x-hidden lg:w-6/12 lg:overflow-y-auto lg:pl-5">
            {vpWidth > 992 ? (
              <>
                <ProductInformation product={product} hideDiscount />
                <button
                  type="button"
                  className={`button sticky bottom-0 w-full lg:relative
                  ${
                    selectedProduct
                      ? 'button--secondary hover:!bg-white hover:!text-black lg:hover:!bg-black lg:hover:!text-white'
                      : 'button--checkout'
                  }
                  ${
                    !selectedProduct &&
                    builderData.selectedProducts.length ===
                      builderSettings.bundleSteps.length
                      ? '!border-primary-2 !bg-primary-2 !text-white opacity-50'
                      : ''
                  }`}
                  disabled={
                    !selectedProduct &&
                    builderData.selectedProducts.length ===
                      builderSettings.bundleSteps.length
                  }
                  onClick={updateBundle}
                >
                  {selectedProduct ? 'remove' : 'add to bundle'}
                </button>
              </>
            ) : (
              <p className="mt-5">{metafieldMap.short_description}</p>
            )}
          </div>
          {vpWidth <= 992 && (
            <button
              type="button"
              className={`button mb-4 lg:relative
                ${
                  selectedProduct
                    ? 'button--secondary !bg-white hover:!bg-white hover:!text-black lg:hover:!bg-black lg:hover:!text-white'
                    : 'button--checkout'
                }
                ${
                  !selectedProduct &&
                  builderData.selectedProducts.length ===
                    builderSettings.bundleSteps.length
                    ? '!border-primary-2 !bg-primary-2 !text-white opacity-50'
                    : ''
                }`}
              disabled={
                !selectedProduct &&
                builderData.selectedProducts.length ===
                  builderSettings.bundleSteps.length
              }
              onClick={updateBundle}
            >
              {selectedProduct ? 'remove' : 'add to bundle'}
            </button>
          )}
        </div>
      </div>
      <button
        type="button"
        onClick={closeQuickview}
        className="fixed top-0 left-0 z-[999999] h-full w-full bg-black opacity-30"
        aria-label="Close Quick View"
      />
    </>
  );
}
