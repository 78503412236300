import { useState } from 'react';

function BasicInput({
  name,
  label,
  type = 'text',
  required = 'false',
  message = '',
  hideLabel,
  placeholder,
  noBorder,
  onChangeEvent = () => {},
}) {
  const [isInvalid, setIsInvalid] = useState(false);
  const inputStateClasses = isInvalid
    ? 'border-error-state outline outline-1 outline-error-state text-error-state'
    : 'focus:outline-ada-outline focus:rounded-none border-black placeholder-shown:text-black text-black hover:text-black focus:text-black';

  return (
    <div className="form-container flex flex-col">
      {!hideLabel && (
        <label className="sub-small mb-2 font-gothamBold" htmlFor={name}>
          {label}
        </label>
      )}
      <input
        name={name}
        id={name}
        type={type}
        placeholder={placeholder}
        required={required === 'true'}
        className={`${inputStateClasses} ${
          noBorder === 'true' ? 'border-0' : 'border'
        } w-full cursor-pointer py-3 px-4 placeholder-grey-7 transition-all placeholder-shown:border-grey-7 hover:border-black hover:placeholder-black focus:placeholder-black`}
        onInvalid={() => setIsInvalid(true)}
        onChange={() => {
          onChangeEvent(event);
          setIsInvalid(false);
        }}
        aria-label={label}
      />
      {isInvalid && (
        <p className="text-xsmall mt-2 w-full text-error-state">{message}</p>
      )}
    </div>
  );
}

export default BasicInput;
