import { useRef, useState, useEffect } from 'react';
import Link from 'next/link';
import Slider from 'react-slick';
import { newLineToBr } from '../../utilities/Helper';
import { IconArrowLeft, IconArrowRight } from '@/snippets/index';
import styles from './StudyStatistics.module.css';

function Section({ cms }) {
  const studyItems = cms?.study || [];
  const scrollRef = useRef(null);
  const arrowNext = useRef(null);
  const arrowPrev = useRef(null);
  const [enableArrows, setEnableArrows] = useState(false);

  const updateSliderHeight = () => {
    const firstSlideHeight = scrollRef.current.innerSlider.list.querySelector('.slick-active').clientHeight;
    const sliderList = scrollRef.current.innerSlider.list;
    console.log(firstSlideHeight);
    sliderList.style.height = firstSlideHeight + 'px'
  }

  useEffect(() => {
    if (scrollRef.current) {
      const child = scrollRef.current.innerSlider.props.children;
      if (!child) return;
      setEnableArrows(child.length > scrollRef.current.props.slidesToShow);
    }
  }, []);

  const slideSettings = {
    infinite: true,
    centerMode: false,
    variableWidth: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    swipeToSlide: true,
    swipe: true,
    adaptiveHeight: true,
  };
  const slickNext = () => {
    scrollRef.current.slickNext();
  };
  const slickPrev = () => {
    scrollRef.current.slickPrev();
  };

  return (
    <div
      id={`${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId}`}
      data-comp={Section.displayName}
      className={`${styles.studyStatistics} px-4 md-:mx-12 md-:px-12`}
    >
      <style>
        {`#${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
            margin-top: ${cms.mobileSpacing}px;
          }
          @media(min-width: 1024px) {
            #${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
              margin-top: ${cms.desktopSpacing}px;
            }
          }`}
      </style>
      <div className="relative mx-auto max-w-[1220px]">
        {enableArrows && (
          <div className="mb-5 flex justify-end lg:mb-0 lg:block lg:justify-between">
            <button
              type="button"
              onClick={() => slickPrev()}
              className="z-1 top-1/2 -left-1 flex h-11 w-11 items-center justify-center border border-black text-black hover:bg-grey-1 hover:text-white lg:absolute lg:-translate-y-1/2 lg:-translate-x-full xl:-left-6"
              ref={arrowPrev}
            >
              <IconArrowLeft />
              <span className="sr-only">Prev</span>
            </button>
            <button
              type="button"
              onClick={() => slickNext()}
              className="z-1 top-1/2 -right-1 flex h-11 w-11 items-center justify-center border border-l-0 border-black text-black hover:bg-grey-1 hover:text-white lg:absolute lg:-translate-y-1/2 lg:translate-x-full lg:border-l xl:-right-6"
              ref={arrowNext}
            >
              <IconArrowRight />
              <span className="sr-only">Next</span>
            </button>
          </div>
        )}

        <div>
          <Slider {...slideSettings}
          ref={scrollRef}>
            {studyItems.map((item, index) => {
              return (
                <div
                  key={index}
                  className="mb-0.5 flex w-full shrink-0 border border-grey-1"
                >
                  <Study item={item} cms={cms} index={index} onLoadHandler={updateSliderHeight} />
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
}

function Study({ item, cms, index, onLoadHandler }) {
  const studyCol = useRef([]);
  const gridClasses = {
    1: 'md:grid-rows-1 md:grid-cols-1',
    2: 'md:grid-rows-1 md:grid-cols-2',
    3: 'md:grid-rows-2 md:grid-cols-2',
  };
  const columnClasses = {
    1: ['row-span-1 col-span-1'],
    2: [
      'row-span-1 col-span-1 md:border-r border-b md:border-b-0',
      'row-span-1 col-span-1',
    ],
    3: [
      'row-span-2 col-span-1 md:border-r border-b md:border-b-0',
      'row-span-1 col-span-1 border-b md:border-b-0',
      'row-span-1 col-span-1 md:border-t',
    ],
  };

  const columns =
    item?.column?.filter((_, i) => {
      return i < 3;
    }) || [];
  const columnCount = columns.length;
  return (
    <div
      className={`flex flex-col md:grid ${gridClasses[columnCount]} h-full gap-0`}
    >
      {columns.map((column, i) => {
        const image_d = column?.image_d?.src;
        const image_m = column?.image_m?.src;
        const {
          title,
          subtitle,
          description,
          titleImage,
          cta,
          ctaStyle,
          productTitle,
          image_alt,
        } = column;
        let orderClasses = `order-0`;
        if (i === 1 && (image_d || columns[i + 1]?.image_d?.src)) {
          orderClasses = `order-1 border-b-0`;
        }
        if (i === 2 && (image_d || columns[i - 1]?.image_d?.src)) {
          orderClasses += ' md:border-t-0 border-b';
        }
        return (
          <div
            key={`study-col-${cms.id || cms.cmsId || cms.tinaId}-${i}`}
            className={`relative flex flex-col ${columnClasses[columnCount][i]} ${orderClasses}`}
            ref={(ref) => studyCol.current.push(ref)}
          >
            {productTitle ? (
              <div
                className={`${
                  image_d ? 'md:absolute md:top-0 md:left-0' : ''
                } w-full border-b border-grey-1 bg-white text-center md:w-3/4 md:border-r md:text-left`}
              >
                <p className="m-0 px-6 py-3 font-gothamBold text-sm lg:text-h5-m">
                  {productTitle}
                </p>
              </div>
            ) : null}

            {image_d ? (
              <div className="study__media md:h-full">
                <div className="pointer-events-none h-full w-full">
                  <picture className="object-fit h-full w-full object-cover">
                    {image_m ? (
                      <source srcSet={image_m} media="(max-width: 768px)" />
                    ) : null}
                    <img
                      src={image_d || image_m}
                      loading="lazy"
                      alt={image_alt}
                      onLoad={() => {
                        if(index === 0) {
                          onLoadHandler()
                        }
                      }}
                      className="object-fit top-0 left-0 h-full w-full object-cover"
                    />
                  </picture>
                </div>
              </div>
            ) : null}
            {subtitle || title || description || cta ? (
              <div
                className={`${
                  image_d
                    ? 'z-1 absolute left-0 top-0 flex h-full items-center'
                    : 'flex flex-grow flex-col justify-center'
                } w-full`}
              >
                <div className="study__content mx-auto flex w-full max-w-[460px] items-center">
                  <div className="w-full overflow-hidden p-7 text-center md:p-8 md:text-left">
                    <div
                      className={`flex items-start ${
                        titleImage && titleImage?.src
                          ? 'mx-auto max-w-[270px] justify-between md:max-w-full md:justify-start'
                          : 'justify-center md:justify-start'
                      }`}
                    >
                      {titleImage && titleImage?.src ? (
                        <div className="flex-shrink-0 pt-2">
                          <img
                            src={titleImage?.src}
                            alt=""
                            className="w-[50px] shrink-0"
                            loading="lazy"
                          />
                        </div>
                      ) : null}
                      <div
                        className={`${
                          titleImage?.src ? 'pl-6 text-left md:pl-5' : ''
                        } flex-grow`}
                      >
                        {subtitle ? (
                          <p
                            className={`mt-0 font-gothamBold text-h6-m font-normal md:text-h6 ${
                              titleImage?.src
                                ? 'mb-1 md:mb-0'
                                : 'mb-3 md:mb-2.5 lg+:mb-9'
                            }`}
                          >
                            {subtitle}
                          </p>
                        ) : null}
                        {title ? (
                          <h3
                            className={`font-tiempos ${
                              titleImage?.src
                                ? 'text-[22px] md:text-h4'
                                : 'text-h2-m md:text-h2'
                            }  m-0`}
                          >
                            {title}
                          </h3>
                        ) : null}
                      </div>
                    </div>

                    {description ? (
                      <div
                        className="mt-2 font-normal"
                        dangerouslySetInnerHTML={{
                          __html: newLineToBr(description),
                        }}
                      />
                    ) : null}
                    {cta && cta?.text && cta?.url ? (
                      <div className="z-2 relative mt-5 md:mt-2 lg:mt-7">
                        <Link href={cta?.url}>
                          <a
                            className={`cursor-pointer ${
                              ctaStyle !== 'focus-link'
                                ? `button min-w-[190px] ${ctaStyle}`
                                : ctaStyle
                            } mb-4 inline-block text-center no-underline`}
                          >
                            {cta?.text}
                          </a>
                        </Link>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
}

Section.displayName = 'Study Statistics';
Study.displayName = 'Study';

Section.Schema = {
  category: 'Modules',
  label: Section.displayName,
  key: 'study-statistics',
  fields: [
    {
      component: 'number',
      name: 'mobileSpacing',
      label: 'Section Top Margin - Mobile',
      defaultValue: 60,
    },
    {
      component: 'number',
      name: 'desktopSpacing',
      label: 'Section Top Margin - Desktop',
      defaultValue: 80,
    },
    {
      component: 'group-list',
      name: 'study',
      label: 'Study',
      description: 'Study',
      fields: [
        {
          component: 'group-list',
          name: 'column',
          label: 'Column',
          description: 'Column',
          fields: [
            {
              label: 'Image (Desktop)',
              name: 'image_d',
              component: 'image',
              description:
                'Recommend image size: 1092px x 1336px (column 1), 1140px x 668px (column 2, 3)',
            },
            {
              label: 'Image (Mobile)',
              name: 'image_m',
              component: 'image',
              description:
                'Recommend a square aspect ratio image with 684px x 684px',
            },
            {
              name: 'image_alt',
              label: 'Image alt text',
              component: 'text',
            },
            {
              label: 'Title Image',
              name: 'titleImage',
              component: 'image',
              description:
                'Recommend a square aspect ratio image with 100px x 100px',
            },
            {
              name: 'productTitle',
              label: 'Product Title',
              component: 'text',
            },
            {
              name: 'subtitle',
              label: 'Sub-title',
              component: 'text',
            },
            {
              label: 'Title',
              name: 'title',
              component: 'text',
            },
            {
              name: 'description',
              label: 'Description',
              component: 'textarea',
            },
            {
              label: 'CTA',
              name: 'cta',
              component: 'link',
            },
            {
              component: 'select',
              name: 'ctaStyle',
              label: 'CTA Style',
              options: [
                { label: 'Primary', value: 'button--primary' },
                { label: 'Secondary', value: 'button--secondary' },
                { label: 'Focus Link', value: 'focus-link' },
                { label: 'checkout', value: 'button--checkout' },
              ],
              defaultValue: 'button--primary',
            },
          ],
        },
      ],
    },
  ],
};

export const StudyStatistics = Section;