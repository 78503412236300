import Script from 'next/script';

export function BuyWithPrimePLP({ cms }) {
  return (
    <div
      id={`${BuyWithPrimePLP.Schema.key}-${cms.id || cms.cmsId || cms.tinaId}`}
      className="buy-with-prime-plp"
    >
      <style>
        {`#${BuyWithPrimePLP.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
            margin-top: ${cms.mobileSpacing}px;
          }
          @media(min-width: 1024px) {
            #${BuyWithPrimePLP.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
              margin-top: ${cms.desktopSpacing}px;
            }
          }`}
      </style>

      <div className="mr-auto ml-auto w-full max-w-[1440px]">
        <div className="mr-[24px] mb-[60px] ml-[24px]">
          <Script 
            data-target-id="9huhml8i1e" 
            src="https://cdn.us-east-1.prod.moon.dubai.aws.dev/fOslgRaZeZE9/REaTi65ZYr0K/latest/scripts/elements.js"
          />
          <bwp-collection 
            content-item-id="gq7kvrxvmk"
          />
        </div>
      </div>
    </div>
  );
}

BuyWithPrimePLP.displayName = 'BuyWithPrimePLP';

BuyWithPrimePLP.Schema = {
  category: 'BuyWithPrimePLP',
  label: BuyWithPrimePLP.displayName,
  key: 'buyWithPrimePLP',
  fields: [
    {
      component: 'number',
      name: 'mobileSpacing',
      label: 'Section Top Margin - Mobile',
      defaultValue: 60,
    },
    {
      component: 'number',
      name: 'desktopSpacing',
      label: 'Section Top Margin - Desktop',
      defaultValue: 80,
    },
  ],
};
