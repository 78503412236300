import { Spinner } from './Spinner';

export function RoutingSpinner() {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'fixed',
        zIndex: '2147483647',
        pointerEvents: 'none',
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#374151cc',
        }}
      >
        <Spinner color="white" />
      </div>
    </div>
  );
}
