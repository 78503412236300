import { getMetafieldMap } from "@/utils/Metafields";
import { useProductByHandle } from "@backpackjs/storefront"


export default function GiftProductcard({giftProduct, selectedProduct, setSelectedProduct}) {
  const {product} = useProductByHandle({handle: giftProduct.handle})

  const metafieldMap = getMetafieldMap(product);

  if (!product) return null;

  return (
    <label label={metafieldMap?.custom_title} key={giftProduct.handle} className="flex md:items-center cursor-pointer border border-primary-1 px-5 py-3 mb-[-1px]">
      <div className="input-container flex-shrink-0 pr-3 mt-4 md:mt-0 leading-none">
        <input type="radio" name="gift"
          value={product.id}
          defaultChecked={product.id == selectedProduct?.id}
          onChange={() => {
          setSelectedProduct({id: product.id, object: product})
        }}/>
      </div>
      <div className="grid grid-cols-[1fr] md:grid-cols-[56px_1fr_auto] items-center w-full">
        <div className="image-container aspect-square w-14 self-start">
          <img src={product.images[0].src} alt={product.images[0].altText} />
        </div>
        <div className="content-container -ml-9 md:ml-0 mt-2 md:mt-0 md:px-5">
          <h2 className="text-sm font-gothamBold mb-0">{metafieldMap?.custom_product_title || product.title}</h2>
          {metafieldMap.custom_description && (
            <p className="text-xs font-primary mb-0">{metafieldMap?.custom_description}</p>
          )}
        </div>
        <div className="price-container -ml-9 md:ml-0 font-gothamBold text-base pl-0 ">
          Free
        </div>
      </div>
    </label>
  )
}