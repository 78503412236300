import { useEffect, useState } from 'react';
import equal from 'fast-deep-equal';
import { useCart, useCartCount, useCartItems } from '@backpackjs/storefront';

export function useShopifyAnalyticsAddToCart({
  basePayload,
  sendShopifyAnalyticsEvent,
}) {
  const cart = useCart();
  const cartCount = useCartCount();
  const cartItems = useCartItems();

  const [previousCartCount, setPreviousCartCount] = useState(null);
  const [previousCartItems, setPreviousCartItems] = useState(null);
  const [previousCartItemsMap, setPreviousCartItemsMap] = useState(null);

  useEffect(() => {
    if (!basePayload || !cart) return;

    const cartItemsMap = cartItems.reduce((acc, line) => {
      if (!line.variant) return acc;
      const variantId = line.variant.id;
      if (!acc[variantId]) {
        return { ...acc, [variantId]: [line] };
      }
      return { ...acc, [variantId]: [...acc[variantId], line] };
    }, {});

    if (!previousCartItems || cartCount <= previousCartCount) {
      setPreviousCartItems(cartItems);
      setPreviousCartCount(cartCount);
      setPreviousCartItemsMap(cartItemsMap);
      return;
    }

    const isAddedItems = [];
    const isIncreasedItems = [];

    cartItems.forEach((line, index) => {
      const variantId = line.variant?.id;
      if (!variantId) return;

      const previousLine = previousCartItemsMap[variantId]?.find((prevLine) => {
        return equal(
          prevLine.attributes.filter((attr) => attr.key !== '_addedAt'),
          line.attributes.filter((attr) => attr.key !== '_addedAt')
        );
      });
      if (!previousLine) {
        isAddedItems.push({
          ...line,
          index,
          quantityAdded: line.quantity,
          totalPrice: line.estimatedCost?.totalAmount?.amount,
        });
        return;
      }
      if (line.quantity > previousLine.quantity) {
        const quantityAdded = line.quantity - previousLine.quantity;
        const totalAmount = line.estimatedCost?.totalAmount?.amount || '0';
        isIncreasedItems.push({
          ...line,
          index,
          quantityAdded,
          totalPrice: (
            (parseFloat(totalAmount) / line.quantity) *
            quantityAdded
          ).toFixed(2),
        });
      }
    });

    const updatedCartItems = [...isAddedItems, ...isIncreasedItems];

    if (!updatedCartItems.length) return;

    const totalValue = updatedCartItems.reduce((acc, line) => {
      return acc + parseFloat(line.totalPrice || '0');
    }, 0);
    const products = updatedCartItems.map((line) => {
      return {
        productGid: line.variant.product.id,
        name: line.variant.product.title,
        price: line.totalPrice,
        variantGid: line.variant.id,
        variantName: line.variant.title,
        category: line.variant.product.productType,
        brand: process.env.NEXT_PUBLIC_SITE_TITLE || 'Storefront',
        ...(line.variant.sku ? { sku: line.variant.sku } : null),
        quantity: line.quantityAdded,
      };
    });

    sendShopifyAnalyticsEvent(
      // payload
      {
        ...basePayload,
        cartId: cart.id,
        totalValue,
        products,
      },
      // event name
      'ADD_TO_CART',
      // callback
      () => {
        setPreviousCartItems(cartItems);
        setPreviousCartCount(cartCount);
        setPreviousCartItemsMap(cartItemsMap);
      }
    );
    if(typeof window.ShipScoutTrackAddToCart === 'function') {
      ShipScoutTrackAddToCart(updatedCartItems);
    }
  }, [basePayload, cart?.updatedAt]);
}
