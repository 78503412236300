import { BuilderRemove } from '@/snippets/icons/BuilderRemove';
import { Image } from '@/snippets/components/Image';
import { useBuilderContext } from './BundleBuildercontext';

export default function BuilderStep({ product, removeProduct }) {
  const context = useBuilderContext();
  const { builderSettings } = context;
  return product.handle === builderSettings.heroProduct ? (
    <div className="bundle-builder__step-image-container rounded-sm">
      <Image
        className="bundle-builder__step-image rounded-md"
        src={product?.images?.[0].src}
        alt={product?.images?.[0].alt}
        loading="lazy"
        fill
      />
    </div>
  ) : (
    <button
      type="button"
      onClick={() => {
        removeProduct(product.handle);
      }}
    >
      <div className="bundle-builder__step-image-container rounded-sm">
        <span className="absolute -top-2.5 -left-2.5 z-[2] flex h-5 w-5 items-center justify-center rounded-full bg-primary-2 text-center">
          <BuilderRemove />
          <div className="sr-only">Remove Product</div>
        </span>
        <Image
          className="bundle-builder__step-image rounded-md"
          src={product?.images?.[0].src}
          alt={product?.images?.[0].alt}
          loading="lazy"
          fill
        />
      </div>
    </button>
  );
}
