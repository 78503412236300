import { forwardRef } from 'react';
import PropTypes from 'prop-types';

export const Svg = forwardRef(({ src, title, viewBox, ...props }, ref) => {
  return (
    <svg ref={ref} viewBox={viewBox} className="h-full w-full" {...props}>
      {title && <title>{title}</title>}

      <use href={src} className="pointer-events-none" />
    </svg>
  );
});

Svg.displayName = 'Svg';
Svg.propTypes = {
  src(props, propName, componentName) {
    if (!props[propName]) {
      return new Error(
        `The prop \`${propName}\` is marked as required in \`${componentName}\`, but its value is \`${props[propName]}\`.`
      );
    }
    if (!/.+#[a-zA-Z][a-zA-Z0-9-_:.]+$/.test(props[propName])) {
      return new Error(
        `Invalid prop \`${propName}\` supplied to' \`${componentName}\`. Must end with \`#\` followed by the \`id\` attributed to the svg, e.g. \`icon-name.svg#icon-name\`.`
      );
    }
    return undefined;
  },
  title: PropTypes.string,
  viewBox: PropTypes.string.isRequired,
};
