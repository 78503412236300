import Link from 'next/link';
import { useRouter } from '@backpackjs/storefront';
import styles from './Contentwithsidebar.module.css';

function SidebarNav(props) {
  const route = useRouter();
  const onSelectPage = (evt) => {
    if (evt.target.value) {
      window.location.href = evt.target.value;
    }
  };
  return (
    <nav className={styles.sidebar__nav}>
      <ul className="hidden lg:block">
        {props?.menu?.map(({ link }, index) => {
          return (
            <li
              key={Math.random()}
              className={`${
                route?.asPath === link?.url ? styles[`is-active`] : ''
              }`}
            >
              {link?.text && <Link href={link?.url || '#'}>{link?.text}</Link>}
            </li>
          );
        })}
      </ul>
      <div
        className={`${
          styles[`sidebar__nav-m`]
        } relative z-0 mx-auto block w-full md:w-3/4 lg:hidden`}
      >
        <select data-page-nav onChange={onSelectPage}>
          {props?.menu?.map(({ link }, index) => {
            return (
              <option key={Math.random()} value={link?.url || '#'}>
                {link?.text}
              </option>
            );
          })}
        </select>
      </div>
    </nav>
  );
}

export default SidebarNav;
