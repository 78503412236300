import { useState, useMemo, useEffect, useRef } from 'react';
import { useYotpoProductBottomline } from '@hooks';
import { getMetafieldMap, getMetafieldColor, getMetafieldsByNamespace } from '@/utils/Metafields';
import EmptyStar from '../icons/EmptyStar.jsx';
import { YotpoStars } from '@/snippets/components/YotpoStars';
import { useCountry, useSettings } from '@backpackjs/storefront';
import { InfoPopup } from './InfoPopup.jsx';
import { Image } from '../components/Image/index.js';

import { Info } from "../icons/Info";
import Styles from './InfoPopup.module.css';
import { Close } from "../icons/Close";
import DiscountMessage from './DiscountMessage';

function ProductInformation(props) {
  const { product, selectedVariant, selectedPlan, hideDiscount } = props;
  const { bottomline } = useYotpoProductBottomline({
    legacyResourceId: product?.legacyResourceId,
  });

  const closeButton = useRef();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if(isOpen == true) {
      closeButton.current.focus();
    }
  }, [isOpen])

  const metafieldMap = useMemo(() => {
    return getMetafieldMap(product);
  }, [product?.id]);

  const popupData = useMemo(() => {
    return metafieldMap.getMetafieldsByNamespace('tooltip');
  }, [product?.id])


  const siteSettings = useSettings();
  const collectionTitle = metafieldMap.custom_collection_title;
  const collectionUrl = metafieldMap.custom_collection_url;
  const collectionLinkActive = !!(collectionUrl && collectionTitle?.length);
  const ingredient = metafieldMap.custom_ingredient_title;
  const productTitle = metafieldMap.custom_product_title || product.title;
  const promo = metafieldMap.promo_message;
  const shortDescription = metafieldMap.short_description;

  const { country } = useCountry();
  const countryCode = country?.isoCode || 'US';
  const isUs = countryCode === 'US';
  let HasInfoPopup = false;

  if (popupData?.tooltip_label && popupData?.body_content) {
    HasInfoPopup = true;
  }


  const buildProductTitle = () => {
    let title;
    if (props.LinkToProduct === true) {
      title = (
        <a
          href={`/products/${product.handle}`}
          className="md:h3 !mb-4 font-tiempos text-h2-m no-underline md:!mb-4"
          onClick={props.onTitleClick}
        >
          {productTitle}
        </a>
      );
    } else {
      title = (
        <p className="md:h3 !mb-4 font-tiempos text-h2-m md:!mb-4">
          {productTitle}
        </p>
      );
    }

    return title;
  };

  let compareValue = selectedVariant?.compareAtPriceV2?.amount || 0;
  let variantPrice = selectedVariant?.priceV2?.amount || 0;
  if (selectedPlan) {
    compareValue = selectedVariant?.compareAtPriceV2?.amount || selectedPlan?.priceAdjustments?.[0]?.compareAtPrice?.amount;
    variantPrice = selectedPlan?.priceAdjustments?.[0]?.price?.amount;
  }


  let discountValue = null;

  if (compareValue > 0) {
    discountValue = Math.floor(((compareValue - variantPrice) / compareValue) * 100);
  }

  const discountHighlight =
    siteSettings?.discountHighlight?.enableDiscountHighlight &&
    siteSettings?.discountHighlight?.pdpDiscountMessage &&
    discountValue !== 0 &&
    discountValue !== null;

  const ingredientsPills = metafieldMap['ingredients_pills'] ? JSON.parse(metafieldMap['ingredients_pills']) : null;

  return (
    <div className="product-info-container--header mb-6 lg:relative">
      {!collectionLinkActive && !collectionTitle && (
        <span className="hidden h-3 lg:mb-4 lg:block" />
      )}
      {!collectionUrl && collectionTitle && (
        <p
          className={`sub-xsmall mb-2 md:mb-4 ${getMetafieldColor(
            metafieldMap
          )}`}
        >
          {collectionTitle}
        </p>
      )}
      {collectionLinkActive && (
        <a
          href={collectionUrl}
          className={`sub-xsmall !mb-2 flex no-underline md:!mb-4 ${getMetafieldColor(
            metafieldMap
          )}`}
        >
          {collectionTitle}
        </a>
      )}
      {ingredient && (
        <p className="ingredient-label mb-2 font-tiempos text-h3-m italic text-black md:mb-2 md:text-[28px]">
          {ingredient}
        </p>
      )}
      {productTitle && buildProductTitle()}
      <div className="yotpo-badge-container lg:absolute lg:top-0 lg:right-0 lg:bottom-0">
        {bottomline && bottomline.total_reviews > 0 ? (
          <a
            href="#pdp-yotpo-reviews-widget"
            className="flex flex-wrap items-center gap-1.5"
            aria-label="Scroll to product reviews"
          >
            {bottomline.total_reviews ? (
              <>
                <YotpoStars rating={bottomline.average_score} />
                <p className="mb-0 flex-1 text-[13px] leading-[17.5px] underline">
                  {bottomline.total_reviews} Review
                  {bottomline.total_reviews === 1 ? '' : 's'}
                </p>
              </>
            ) : (
              <p className="mb-0 text-[13px] leading-[17.5px] underline">
                No reviews
              </p>
            )}
          </a>
        ) : (
          <a
            href="#pdp-yotpo-reviews-widget"
            className="yotpo-no-reviews text-xsmall flex cursor-pointer items-center leading-none text-black underline"
          >
            <div className="mr-2 flex">
              <EmptyStar />
              <EmptyStar />
              <EmptyStar />
              <EmptyStar />
              <EmptyStar />
            </div>
            <span>Write a Review</span>
          </a>
        )}
      </div>
      {Array.isArray(ingredientsPills) && ingredientsPills.length > 0 && (
        <div className="ingredients-badges mb-6 flex hidden flex-wrap gap-x-2 gap-y-3">
          {ingredientsPills.map((pill, index) => (
            <span
              className="ingredients-badge flex items-center justify-center rounded-full border-2 border-grey-4 bg-grey-9 px-3.5 py-1 font-gothamBold text-sm"
              key={`ingredient-${index}`}
            >
              <div className="ingredients-badge__image mr-2 h-5 w-5 shrink-0 overflow-hidden rounded-full border border-black">
                <Image
                  src={pill.image}
                  width="20"
                  height="20"
                  className="block h-full w-full object-cover"
                  alt={pill.image?.alt}
                />
              </div>
              {pill['ingredient_name']}
            </span>
          ))}
        </div>
      )}

      <DiscountMessage
        siteSettings={siteSettings}
        promo={promo}
        discountHighlight={discountHighlight}
        hideDiscount={hideDiscount}
        isUs={isUs}
        handle={product.handle}
        discountValue={discountValue}
      />

      {shortDescription && (
        <p className="text-xsmall md:text-small mb-2 flex flex-col text-black">
          {shortDescription}
        </p>
      )}

      {HasInfoPopup == true && <InfoPopup settings={popupData}/>}
    </div>
  );
}

export default ProductInformation;
