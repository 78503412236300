import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkDirective from 'remark-directive';
import { useBuilderContext } from './BundleBuildercontext';
import { Image } from '@/snippets/components/Image';

import styles from './BuilderHeader.module.css';

export default function BuilderHeader({ scrollTop }) {
  const context = useBuilderContext();
  const {
    title,
    description,
    ctaTitle,
    disclaimer,
    desktopBgImage,
    mobileBgImage,
    darkText,
  } = context.builderSettings;

  if (!title && !description && !ctaTitle && !disclaimer) return null;

  const hasImage = !!desktopBgImage && !!mobileBgImage;

  return (
    <div
      className={`bundle-builder__header flex flex-col items-center justify-center py-14 px-10 text-center lg:min-h-[454px] lg:py-10
      ${hasImage ? 'relative overflow-hidden' : 'bg-light-blue'}`}
    >
      {hasImage && (
        <>
          <Image
            className="bundle-builder__desktop-header-image absolute top-1/2 hidden h-full w-auto max-w-none -translate-y-1/2 md:block xl:h-auto xl:w-full"
            src={desktopBgImage?.src}
            alt={desktopBgImage?.alt}
            width={desktopBgImage.width}
            height={desktopBgImage.height}
            priority
          />
          <Image
            className="bundle-builder__mobile-header-image absolute top-1/2 block h-auto min-h-full w-auto min-w-full -translate-y-1/2 md:hidden"
            src={mobileBgImage?.src}
            alt={mobileBgImage?.alt}
            width={desktopBgImage.width}
            height={desktopBgImage.height}
            priority
          />
        </>
      )}
      <div
        className={`bundle-builder__header-container z-10 flex max-w-xl flex-col justify-center
        ${hasImage && !darkText ? 'text-white' : ''}
      `}
      >
        {title && <h1 className="bundle-builder__title mb-4">{title}</h1>}
        {description && (
          <ReactMarkdown
            remarkPlugins={[remarkDirective, remarkGfm]}
            className="bundle-builder__description text-base"
          >
            {description}
          </ReactMarkdown>
        )}
        {ctaTitle && (
          <button
            type="button"
            className="bundle-builder__cta button button--checkout mx-auto mb-4 inline-block w-auto"
            onClick={scrollTop}
          >
            {ctaTitle}
          </button>
        )}
        {disclaimer && (
          <ReactMarkdown
            remarkPlugins={[remarkDirective, remarkGfm]}
            className={`${styles['bundle-builder__disclaimer']} bundle-builder__disclaimer mb-0 font-gothamBold text-xs italic`}
          >
            {disclaimer}
          </ReactMarkdown>
        )}
      </div>
    </div>
  );
}
