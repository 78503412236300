import { useEffect, useState } from "react";
import GiftProductcard from "./GiftProductCard";
import { useCartAddItem } from "@backpackjs/storefront";

export default function GiftSelector() {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [errrorMessage, setErrorMessage] = useState(null);
  const { cartAddItem } = useCartAddItem();

  const buildProductCards = () => {
    return modalData.gwpProduct.map((product) => {
      return <GiftProductcard key={`product-${product.id}`} giftProduct={product} selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} />
    })
  }

  const closeModal = () => {
    setModalData(null);
  }

  const optOutFreeGift = () => {
    closeModal();
    document.dispatchEvent(
      new CustomEvent('gwp:optOutFreeGift', {
        detail: {
          campaign: modalData.campaign,
        },
      })
    );
  }

  const addFreeGiftToCart = () => {
    if(selectedProduct === null) {
      setErrorMessage('Please select a product');
    } else {
      cartAddItem({
        merchandiseId: selectedProduct.object.variants[0].id,
        quantity: 1,
        attributes: [
          {
            key: '_campaign',
            value: modalData.campaign,
          },
          {
            key: '_gwp',
            value: 'true',
          },
          {
            key: '_sc',
            value:
              typeof window.ShipScoutLineItemProperty !== 'undefined'
                ? window.ShipScoutLineItemProperty
                : 'null',
          },
        ],
      }).then(() => {
        closeModal();
        document.dispatchEvent(
          new CustomEvent('openSideCart', { bubbles: true })
        );
      });
    }
  }

  useEffect(() => {
    if(selectedProduct !== null) {
      setErrorMessage(null);
    }
  }, [selectedProduct])

  useEffect(() => {
    document.addEventListener('bwp:openGiftSelector', async (evt) => {
      setModalOpen(true);
      setModalData(evt.detail);
    });
  }, [])

  useEffect(() => {
    if(!modalOpen) {
      setModalData(null);
    }
  }, [modalOpen])

  if(modalData == null) {
    return;
  }

  return (
    <>
      <div
        className={`before-after__product-modal fixed bottom-0 top-1/2 left-1/2 z-[1000000] max-h-[90dvh] w-full max-w-[90%] -translate-y-1/2
          -translate-x-1/2 overflow-y-auto bg-white px-5 pt-7 pb-5 h-min lg:w-max lg:max-w-2xl lg:px-12 lg:pt-12`}
      >
        <div className="modal-header text-center mb-7">
          <h3 className="mb-4 font-tiempos text-h3-m lg:text-h3">{modalData.modalSettings.title}</h3>
          {modalData.modalSettings.subtitle && (
            <p className="mb-0">{modalData.modalSettings.subtitle}</p>
          )}
        </div>

        <div className="modal-body">
          {buildProductCards()}
        </div>

        <div className="modal-footer flex flex-col justify-center items-center mt-4 lg:mt-5 gap-3 lg:gap-4">
          {errrorMessage && <p className="text-error">{errrorMessage}</p>}
          <button className={`button ${selectedProduct ? 'button--checkout' : 'button--disabled'}`}
                  onClick={addFreeGiftToCart}
                  disabled={!selectedProduct}>
            {modalData.modalSettings.atcText}
          </button>
          <button className="focus-link" onClick={optOutFreeGift}>
            {modalData.modalSettings.skipButtonText}
          </button>
        </div>
      </div>
      <button onClick={optOutFreeGift} className={`modalBackdrop z-[2002] fixed top-0 left-0 h-full w-full cursor-pointer bg-black opacity-50`} />
    </>
  )
}