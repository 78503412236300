import IconMenu from '@/icons/icon-menu.svg';
import IconSearch from '@/icons/icon-search.svg';
import IconAccount from '@/icons/icon-account.svg';
import IconCart from '@/icons/icon-cart.svg';
import IconChevronDown from '@/icons/icon-chevron-down.svg';
import IconClose from '@/icons/icon-close.svg';
import IconArrowDown from '@/icons/arrow-down.svg';
import IconArrowLeft from '@/icons/arrow-left.svg';
import IconArrowRight from '@/icons/arrow-right.svg';
import IconZoomIn from '@/icons/icon-zoom-in.svg';
import IconRemove from '@/icons/icon-remove.svg';
import IconPlus from '@/icons/icon-plus.svg';
import IconMinus from '@/icons/icon-minus.svg';
import IconStar from '@/icons/icon-star.svg';
import IconError from '@/icons/error-icon.svg';

export {
  IconMenu,
  IconSearch,
  IconAccount,
  IconCart,
  IconChevronDown,
  IconClose,
  IconArrowDown,
  IconArrowLeft,
  IconArrowRight,
  IconZoomIn,
  IconRemove,
  IconPlus,
  IconMinus,
  IconStar,
  IconError,
};
