export function Info(props) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 14 14"
      version="1.1"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      {...props}
    >
      <g transform="matrix(1,0,0,1,-0.073459,-0.0462351)">
        <g transform="matrix(0.840453,0,0,0.95131,-570.136,-281.721)">
          <g transform="matrix(1.18984,0,0,1.05118,678.368,296.141)">
            <path
              d="M13.164,6.592C13.164,2.977 10.234,0.046 6.619,0.046C3.004,0.046 0.073,2.977 0.073,6.592C0.073,10.207 3.004,13.137 6.619,13.137C10.234,13.137 13.164,10.207 13.164,6.592ZM12.164,6.592C12.164,9.654 9.682,12.137 6.619,12.137C3.556,12.137 1.073,9.654 1.073,6.592C1.073,3.529 3.556,1.046 6.619,1.046C9.682,1.046 12.164,3.529 12.164,6.592ZM6.219,9.251L7.187,9.251L7.187,5.027L6.219,5.027L6.219,9.251ZM6.179,4.379L7.219,4.379L7.219,3.459L6.179,3.459L6.179,4.379Z"
              fill="currentColor"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}