import { useInView } from 'react-intersection-observer';

import { Image } from "@/snippets/components/Image";
import Link from 'next/link';

export function CollectionPromoTile({ tile }) {
  const { ref, inView } = useInView({
    rootMargin: '0px',
    triggerOnce: true,
  });

  const { aspectRatio, background, link } = tile;
  const { alt, d_image, m_image } = {
    ...background,
  };

  return (
    <div className="h-full">
    {link?.url ? (
      <div className={`relative ${aspectRatio} overflow-hidden plp-promo-image-cta`}>
        <Link href={link?.url}>
          <a className={`no-underline hover:underline`}>
            {d_image?.src && (
              <Image
                alt={alt}
                fill
                sizes="(min-width: 768px) 33vw, 50vw"
                src={d_image.src}
                className="hidden md:block"
              />
            )}
           {m_image?.src && (
              <Image
                alt={alt}
                fill
                sizes="(max-width: 767px) 33vw, 50vw"
                src={m_image.src}
                className="block md:hidden"
              />
            )}
            {link?.text && (
            <div className="absolute bottom-0 w-full p-4">
              <button type="button" className="button button--primary !min-w-full">
                {link?.text}
              </button>
            </div>
            )}
          </a>
        </Link>
      </div>
    ) : (
      <div className={`relative ${aspectRatio} overflow-hidden plp-promo-image-only`}>
       {d_image?.src && (
          <Image
            alt={alt}
            fill
            sizes="(min-width: 768px) 33vw, 50vw"
            src={d_image.src}
            className="hidden md:block"
          />
        )}
       {m_image?.src && (
          <Image
            alt={alt}
            fill
            sizes="(max-width: 767px) 33vw, 50vw"
            src={m_image.src}
            className="block md:hidden"
          />
        )}
      </div>
    )}
    </div>
  );
}

CollectionPromoTile.displayName = 'CollectionPromoTile';