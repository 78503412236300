export function Pause(props) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 63 63"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      {...props}
    >
      <g>
        <path
          d="M31.123,3.123C15.669,3.123 3.123,15.669 3.123,31.123C3.123,46.577 15.669,59.123 31.123,59.123C46.577,59.123 59.123,46.577 59.123,31.123C59.123,15.669 46.577,3.123 31.123,3.123ZM31.123,5.123C45.473,5.123 57.123,16.773 57.123,31.123C57.123,45.473 45.473,57.123 31.123,57.123C16.773,57.123 5.123,45.473 5.123,31.123C5.123,16.773 16.773,5.123 31.123,5.123ZM27.123,21.123L23.123,21.123L23.123,41.123L27.123,41.123L27.123,21.123ZM39.123,21.123L35.123,21.123L35.123,41.123L39.123,41.123L39.123,21.123Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}