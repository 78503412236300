import { useCallback, useEffect, useState } from 'react';
import { nanoid } from 'nanoid';

export function useDataLayerSubscribe({
  baseEcommerce,
  DEBUG,
  userDataEventTriggered,
}) {
  const [subscribedEmail, setSubscribedEmail] = useState('');
  const [subscribedPhone, setSubscribedPhone] = useState('');

  const subscribeEmailEvent = useCallback(({ ecommerce, email }) => {
    if (!email) return;
    const event = {
      event: 'dl_subscribe',
      event_id: nanoid(),
      event_time: new Date().toISOString(),
      lead_type: 'email',
      user_properties: { customer_email: email },
      ecommerce,
    };

    window.dispatchEvent(
      new CustomEvent(event.event, {
        detail: event,
      })
    );
    if (DEBUG) console.log(`DataLayer:dispatch:${event.event}`, event);
  }, []);

  const subscribePhoneEvent = useCallback(({ ecommerce, phone }) => {
    if (!phone) return;
    const event = {
      event: 'dl_subscribe',
      event_id: nanoid(),
      event_time: new Date().toISOString(),
      lead_type: 'phone',
      user_properties: { customer_phone: phone },
      ecommerce,
    };

    window.dispatchEvent(
      new CustomEvent(event.event, {
        detail: event,
      })
    );
    if (DEBUG) console.log(`DataLayer:dispatch:${event.event}`, event);
  }, []);

  // Subscribe to PubSub topic for 'dl_subscribe' event
  useEffect(() => {
    const subscribedWithEmail = PubSub.subscribe(
      'SUBSCRIBE_EMAIL',
      async (event, email) => {
        setSubscribedEmail(email);
      }
    );
    const subscribedWithPhone = PubSub.subscribe(
      'SUBSCRIBE_PHONE',
      async (event, phone) => {
        setSubscribedPhone(phone);
      }
    );
    return () => {
      if (subscribedWithEmail) {
        PubSub.unsubscribe(subscribedWithEmail);
      }
      if (subscribedWithPhone) {
        PubSub.unsubscribe(subscribedWithPhone);
      }
    };
  }, []);

  // Trigger 'dl_subscribe' event after email submission
  useEffect(() => {
    if (!baseEcommerce || !subscribedEmail || !userDataEventTriggered) return;
    subscribeEmailEvent({ ecommerce: baseEcommerce, email: subscribedEmail });
  }, [!!baseEcommerce, subscribedEmail, userDataEventTriggered]);

  // Trigger 'dl_subscribe' event after phone submission
  useEffect(() => {
    if (!baseEcommerce || !subscribedPhone || !userDataEventTriggered) return;
    subscribePhoneEvent({ ecommerce: baseEcommerce, phone: subscribedPhone });
  }, [!!baseEcommerce, subscribedPhone, userDataEventTriggered]);
}
