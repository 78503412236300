import {
  useProductByHandle,
  useSettings,
  useLocalizedVariant,
  useCountry,
  useCartAddItem,
} from '@backpackjs/storefront';
import Link from 'next/link';
import styles from './SideCart.module.css';
import { IconRemove, IconMinus, IconPlus } from '@/snippets/index.js';
import { getSizedImageUrl } from '../../utilities/Images.js';
import { formatMoney } from '../../utilities/Helper.js';

const SideCartItem = ({ item, cartUpdateItem, cartRemoveItem, isUs }) => {
  const siteSettings = useSettings();
  const { country } = useCountry();
  const { cartAddItem } = useCartAddItem();
  const { product } = useProductByHandle({
    handle: item?.variant?.product?.handle,
  });
  const sellingPlans = product?.sellingPlanGroups?.[0]?.sellingPlans;
  const hasSellingPlan = item?.sellingPlanAllocation;
  const planAmount =
    parseFloat(item?.sellingPlanAllocation?.priceAdjustments?.[0]?.price?.amount);
  const planText =
    item?.sellingPlanAllocation?.sellingPlan?.options?.[0]?.value;
  const planPrice = planAmount ? Math.min([parseFloat(planAmount), parseFloat(item.estimatedCost.totalAmount.amount)]) : null;
  const planOptions = sellingPlans?.map((plan) => ({
    label: plan?.name,
    value: plan?.id,
  }));
  const sellingPlanOptions = planOptions?.length
    ? [{ label: 'One-time Purchase', value: 'one-time' }, ...planOptions]
    : [];

  const { localized: localizedVariant } = useLocalizedVariant({
    variant: item.variant,
  });

  const selectedVariant = { ...item.variant, ...localizedVariant };
  const hasDiscount =
    Number.parseFloat(item.estimatedCost.totalAmount.amount) <
    Number.parseFloat(item.estimatedCost.subtotalAmount.amount);
  const lineDiscount = Number.parseFloat(item?.discountAllocations?.[0]?.discountedAmount.amount) / item.quantity;
  const variantAmount = item.estimatedCost.totalAmount.amount / item.quantity;

  let compareAmount = hasDiscount
    ? Number.parseFloat(item.estimatedCost.subtotalAmount.amount) / item.quantity
    : Number.parseFloat(selectedVariant?.compareAtPriceV2?.amount);
  if (hasSellingPlan !== null) compareAmount = Number.parseFloat(selectedVariant?.compareAtPriceV2?.amount) || Number.parseFloat(selectedVariant?.priceV2?.amount);
  if (compareAmount < Number.parseFloat(selectedVariant?.compareAtPriceV2?.amount)) compareAmount = Number.parseFloat(selectedVariant.compareAtPriceV2.amount);

  const isLocalized = !!selectedVariant?.priceV2?.locale;
  let currencySymbol = isLocalized ? country?.currency?.symbol || '$' : '$';

  const comparePrice = compareAmount
    ? compareAmount
    : null;
  const variantPrice = variantAmount

  const handlePlanChange = async (e) => {
    if (e.target.value == 'one-time') {
      await cartAddItem({
        merchandiseId: item.variant.id,
        quantity: item.quantity,
        attributes: [
          {
            key: '_sc',
            value: (typeof window.ShipScoutLineItemProperty !== 'undefined' ? window.ShipScoutLineItemProperty : 'null'),
          }
        ],
      });

      await cartRemoveItem({ lineId: item.id });
    } else {
      await cartUpdateItem({
        lineId: item.id,
        sellingPlanId: btoa(e.target.value),
      });
    }
  };

  const upgradeToPlan = async () => {
    const firstPlan = sellingPlans?.[0];

    if (firstPlan) {
      await cartUpdateItem({
        lineId: item.id,
        sellingPlanId: btoa(firstPlan?.id),
      });
    }
  };

  const ItemQuantity = ({ className }) => {
    if (!className) className = '';
    return (
      <div
        className={`${className} rounded-xs flex w-[114px] justify-between border border-black`}
      >
        <span
          className={`flex cursor-pointer items-center pl-3`}
          onClick={() => {
            if (item.quantity === 1) {
              return cartRemoveItem({ lineId: item.id });
            }
            cartUpdateItem({
              lineId: item.id,
              quantity: item.quantity - 1,
            });
          }}
        >
          <IconMinus />
        </span>
        <span className={`flex-grow py-2 px-4 text-center`}>
          {item.quantity}
        </span>
        <span
          className={`flex cursor-pointer items-center pr-3 ${
            item.quantity >= 5 ? 'pointer-events-none opacity-50' : ''
          }`}
          onClick={() => {
            if (item.quantity >= 5) return;
            cartUpdateItem({
              lineId: item.id,
              quantity: item.quantity + 1,
            });
          }}
        >
          <IconPlus />
        </span>
      </div>
    );
  };

  const ItemTotal = ({ className, currencySymbol }) => {
    if (!className) className = '';

    return (
      <div className={`${styles.lineItemPricing} ${className}`}>
        {comparePrice > variantPrice && (
          <span className={styles.lineItemCompareAtPrice}>{formatMoney(comparePrice?.toFixed(2)).replace('$', currencySymbol)}</span>
        )}
        {planPrice ? (
          <span>{formatMoney(planPrice?.toFixed(2) || 0).replace('$', currencySymbol)}</span>
        ) : (
          <span>{formatMoney(variantPrice?.toFixed(2)).replace('$', currencySymbol)}</span>
        )}
      </div>
    );
  };

  const calcDiscountValue = () => {
    let discountValue = null;
    let currentAmount = item.estimatedCost.totalAmount.amount;

    if(planPrice) {
      currentAmount = parseFloat(item.estimatedCost.totalAmount.amount) < parseFloat(planPrice) ? parseFloat(item.estimatedCost.totalAmount.amount) : parseFloat(planPrice);
    }
    if(compareAmount) {
      discountValue = (compareAmount - currentAmount) / compareAmount * 100;
      discountValue = Math.round(discountValue);
    }

    return discountValue;
  }

  const discountValue = calcDiscountValue();

  const discountHighlight =
    siteSettings?.discountHighlight?.enableDiscountHighlight &&
    discountValue !== 0 &&
    discountValue !== null;

  return (
    <div
      className={`flex items-start justify-between border-b border-grey-4 py-5 px-4`}
    >
      <div className={`w-20 shrink-0`}>
        <Link href={`/products/${item?.variant?.product?.handle}`}>
          <a className={`responsive-image pb-full bg-grey-9 no-underline`}>
            <img
              src={getSizedImageUrl(item?.variant?.image?.src, '100x100@2x')}
              alt={item?.variant?.product?.title}
            />
          </a>
        </Link>
      </div>
      <div className={`relative flex-grow pl-3`}>
        <Link href={`/products/${item?.variant?.product?.handle}`}>
          <a
            className={`my-0 inline-block pr-8 font-gothamBold text-xs no-underline`}
          >
            {item?.variant?.product?.title}
          </a>
        </Link>
        {item?.variant?.title != 'Default Title' ? (
          <p className={`mt-1 mb-0 text-xs`}>{item?.variant?.title}</p>
        ) : null}
        {planText ? (
          <p className={`mt-1 mb-0 text-xs`}>{`Ships Every ${planText}`}
          {discountHighlight && discountValue > 0 &&
            <span className='lineItem__discount-percentage text-sub-xx-small border-primary-2 border border-solid rounded-sm text-primary-2 ml-1 inline-block px-1 py-[1px] font-gothamBold'>{discountValue}% off</span>
          }
          </p>
        ) : (
          <p className={`mt-1 mb-0 text-xs`}>One-time Purchase
          {discountHighlight &&
            <span className='lineItem__discount-percentage text-sub-xx-small border-primary-2 border border-solid rounded-sm text-primary-2 ml-1 inline-block px-1 py-[1px] font-gothamBold'>{discountValue}% off</span>
          }
          </p>
        )}

        <div className={`mt-6 flex items-center justify-between`}>
          <ItemQuantity />
          <ItemTotal className={`text-right font-gothamBold text-sm`} currencySymbol={currencySymbol} />
        </div>
        <span
          className={`absolute right-0 top-0 inline-block cursor-pointer`}
          onClick={() => cartRemoveItem({ lineId: item.id })}
        >
          <IconRemove />
        </span>

        {hasSellingPlan && (
          <div className="select-wrap-3 relative flex flex-col">
            <select
              className="mt-[16px] h-[40px] w-full cursor-pointer border border-grey-5 py-2 px-3 text-[12px] text-black placeholder-black outline-0 transition-all placeholder-shown:border-grey-5 hover:border-black hover:placeholder-black focus:placeholder-black"
              name={`selling-plan-select-${item?.id}`}
              id={`selling-plan-select-${item?.id}`}
              value={item?.sellingPlanAllocation?.sellingPlan?.id}
              onChange={handlePlanChange}
            >
              {sellingPlanOptions?.map((option, optionIndex) => (
                <option
                  key={`option-${item?.id}-${optionIndex}`}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        )}
        {!hasSellingPlan && sellingPlans && isUs && (
          <div
            className="sidecart-upgrade-sub mt-5 inline-block cursor-pointer border-b-2 font-gothamBold text-sm"
            onClick={upgradeToPlan}
          >
            {`update to subscription & save ${sellingPlans?.[0]?.priceAdjustments?.[0]?.adjustmentValue?.adjustmentPercentage}%`}
          </div>
        )}
      </div>
    </div>
  );
};

export default SideCartItem;
