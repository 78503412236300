import { useEffect } from 'react';
import Script from 'next/script';

export function FueledImpactScript({ withKey: impactPixelId }) {
  useEffect(() => {
    // Function to hash email using SHA-1
    const hashEmail = async (email) => {
      const encoder = new TextEncoder();
      const data = encoder.encode(email);
      const hash = await crypto.subtle.digest('SHA-1', data);
      return Array.from(new Uint8Array(hash))
        .map((b) => b.toString(16).padStart(2, '0'))
        .join('');
    };

    // Ensure that the ire function is ready to be called
    const ensureIreReady = async (callback) => {
      const hasIreLoaded = () => typeof window.ire === 'function';
      if (hasIreLoaded()) {
        callback();
      } else {
        setTimeout(() => ensureIreReady(callback), 100); // Check every 100ms
      }
    };

    const userDataListener = async (event) => {
      console.log('User properties:', event.detail.user_properties);
      const userProps = event.detail.user_properties;
      if (userProps?.customer_email) {
        const hashedEmail = await hashEmail(userProps.customer_email);
        ensureIreReady(() => {
          window.ire('identify', {
            customerId: userProps?.customer_id,
            customerEmail: hashedEmail,
          });
          console.log('Firing identify event', {
            customerId: userProps?.customer_id,
            customerEmail: hashedEmail,
          });
        });
      } else {
        ensureIreReady(() => {
          window.ire('identify', { customerId: '', customerEmail: '' });
          console.log('Firing identify event with empty data');
        });
      }
    };

    window.addEventListener('dl_route_update', userDataListener);

    return () => {
      window.removeEventListener('dl_route_update', userDataListener);
    };
  }, []);

  return (
    <Script
      src={`https://utt.impactcdn.com/${impactPixelId}.js`}
      strategy="afterInteractive"
      onLoad={() => console.log('Impact script loaded successfully')}
      onError={(e) => console.error('Failed to load the impact script', e)}
    />
  );
}