import Script from 'next/script';

export function StoreLocator({ cms }) {
  return (
    <div
      id={`${StoreLocator.Schema.key}-${cms.id || cms.cmsId || cms.tinaId}`}
      className="store-locator-container px-4 py-10 lg:px-12"
    >
      <style>
        {`#${StoreLocator.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
            margin-top: ${cms.mobileSpacing}px;
          }
          @media(min-width: 1024px) {
            #${StoreLocator.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
              margin-top: ${cms.desktopSpacing}px;
            }
          }`}
      </style>
      <div className="m-auto lg:container">
        <Script
          type="text/javascript"
          src="https://storelocator.w3apps.co/widget/bundle.js"
        />
        <w3-store-locator
          default-distance="25"
          distances="5,25,100,250,-1"
          search-limit="100"
        />
      </div>
    </div>
  );
}

StoreLocator.displayName = 'StoreLocator';

StoreLocator.Schema = {
  category: 'StoreLocator',
  label: StoreLocator.displayName,
  key: 'storelocator',
  fields: [
    {
      component: 'number',
      name: 'mobileSpacing',
      label: 'Section Top Margin - Mobile',
      defaultValue: 60,
    },
    {
      component: 'number',
      name: 'desktopSpacing',
      label: 'Section Top Margin - Desktop',
      defaultValue: 80,
    },
  ],
};
