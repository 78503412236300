import { useMemo, useRef, useState, useEffect } from 'react';
import { useCollectionByHandle, useCountry, useSettings } from '@backpackjs/storefront';
import { useProductsFromHandles } from '@hooks';
import FilterBar from '@/snippets/globals/layout/FilterBar';
import ProductGrid from '@/snippets/globals/layout/ProductGrid';
import Pagination from '@/snippets/globals/layout/Pagination';
import CollectionHeader from '@/snippets/components/CollectionHeader';
import LoadMore from '@/snippets/globals/layout/LoadMore';

function Section({ cms }) {
  const settings = useSettings();
  const {
    campaigns,
  } = { ...settings?.collectionTile };

  const {
    collectionHandle,
    enableFilters,
    sectionTitle,
    sectionSubtitle,
    enableLoadMore,
    scrollThreshold,
  } = cms;
  let { showSubscriptionPrice } = cms;
  const { collection } = useCollectionByHandle({
    handle: collectionHandle || '',
  });
  const productHandles = collection?.products?.map((product) => product.handle);
  const { products: productsData } = useProductsFromHandles({
    handles: productHandles || [],
  });
  const productsPerPage = 24;
  const collectionContainer = useRef();
  const { country } = useCountry();
  const countryCode = country?.isoCode || 'US';
  const isUs = countryCode === 'US';

  const promoTiles = useMemo(() => {
    if (!campaigns?.length) return [];
    const campaign = campaigns.find(( campaign ) => {
      if (!campaign?.enabled) {
        return false;
      }
      return campaign?.collections?.some((colHandle) => colHandle.trim() === collection?.handle);
    });
    return campaign?.promoTiles || [];
  }, [collection, campaigns]);

  const [layoutParams, setLayoutParams] = useState({
    filters: [],
    sort: 'def:def',
    page: 1,
  });

  if (!isUs) {
    showSubscriptionPrice = false;
  }

  const getLoadedPageCTAs = () => {
    const currentPageCTAs = promoTiles?.filter((tile) => {
      if (cms.enableLoadMore) {
        return Math.ceil(tile.position / productsPerPage) <= layoutParams.page;
      }
      return Math.ceil(tile.position / productsPerPage) === layoutParams.page;
    });

    const loadedPreviousCTAs = promoTiles?.filter((tile) => {
      if (enableLoadMore) {
        return false;
      }

      return Math.ceil(tile.position / productsPerPage) < layoutParams.page;
    });

    return { currentPageCTAs, loadedPreviousCTAs };
  };

  const tileCtas = getLoadedPageCTAs();

  const sortItems = () => {
    const sortPieces = layoutParams?.sort?.split(':') || [];
    const sortedItems = [...products].sort((a, b) => {
      switch (sortPieces[0]) {
        case 'price':
          if (sortPieces[1] === 'asc') {
            return (a.priceRange.min || 0) - (b.priceRange.min || 0);
          }
          return (b.priceRange.max || 0) - (a.priceRange.max || 0);

        case 'alpha':
          if (sortPieces[1] === 'asc') {
            return a.title.toUpperCase() < b.title.toUpperCase() ? '-1' : '1';
          }
          return a.title.toUpperCase() > b.title.toUpperCase() ? '-1' : '1';
        default:
          return 0;
      }
    });
    return sortedItems;
  };

  const applyFilters = () => {
    const products = Object.keys(productsData).map((key) => productsData[key]);
    let filteredProducts = products;

    if (layoutParams?.filters?.length > 0) {
      filteredProducts = products?.filter((product) => {
        return product?.tags.some((tag) => layoutParams.filters.includes(tag));
      });
    }

    return filteredProducts || [];
  };

  const products = applyFilters();

  const sortedItems = sortItems(products) || [];

  const getCurrentPageItems = () => {
    let currentPageItems = [];
    const productList = [...sortedItems];
    currentPageItems = enableLoadMore
      ? productList.slice(
          0,
          productsPerPage * (layoutParams?.page || 1) -
            tileCtas.loadedPreviousCTAs.length -
            tileCtas.currentPageCTAs.length
        )
      : productList.slice(
          productsPerPage * ((layoutParams?.page || 1) - 1) -
            tileCtas.loadedPreviousCTAs.length,
          productsPerPage * (layoutParams?.page || 1) -
            tileCtas.loadedPreviousCTAs.length -
            tileCtas.currentPageCTAs.length
        );

    if (tileCtas.currentPageCTAs.length) {
      tileCtas.currentPageCTAs.forEach((item) => {
        let pageCorrection = 0;
        if (layoutParams.page > 1 && !enableLoadMore) {
          pageCorrection = productsPerPage * (layoutParams.page - 1);
        }
        currentPageItems.splice(item.position - 1 - pageCorrection, 0, item)
      });
    }
    return currentPageItems;
  };

  const buildSectionHeader = () => {
    if (!sectionTitle && !sectionSubtitle) {
      return null;
    }

    return (
      <div className="container text-center">
        {sectionTitle && (
          <h2
            className={`h3 collection-grid__title ${
              sectionSubtitle !== undefined ? 'mb-0' : 'mb-5'
            } `}
          >
            {sectionTitle}
          </h2>
        )}
        {sectionSubtitle && (
          <p className="collection-grid__subtitle">{sectionSubtitle}</p>
        )}
      </div>
    );
  };

  const currentPageItems = getCurrentPageItems();

  const [mobileFilterOpen, setMobileFilterOpen] = useState(false);
  const scrollEffectParams = !enableLoadMore
    ? [layoutParams]
    : [layoutParams.filters, layoutParams.sort];

  useEffect(() => {
    if (typeof document === 'undefined') return undefined;
    const offsetTop = collectionContainer?.current?.offsetTop;
    const headerHeight = document.querySelector('.site-header').offsetHeight;

    if (window.scrollY > offsetTop && !window.location.hash) {
      window.scrollTo({
        top: offsetTop - headerHeight,
        behavior: 'smooth',
      });
    }
  }, scrollEffectParams);

  return (
    <>
      {buildSectionHeader()}
      {collection && (
        <div
          key="collection-container"
          ref={collectionContainer}
          id={`${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId}`}
        >
          {enableFilters && (
            <FilterBar
              mobileFilterOpen={mobileFilterOpen}
              setMobileFilterOpen={setMobileFilterOpen}
              currentFilters={layoutParams.filters}
              setLayoutParams={setLayoutParams}
              items={products}
              browserHistory={false}
            />
          )}
          <div className="lg:mx-auto lg:container px-5 collection__product-grid-container w-full">
            <CollectionHeader
              sort={layoutParams?.sort}
              setMobileFilterOpen={setMobileFilterOpen}
              setLayoutParams={setLayoutParams}
              currentFilters={layoutParams?.filters}
              browserHistory={false}
              itemCount={products.length}
              productCount={currentPageItems.length - tileCtas.currentPageCTAs.length}
              itemsPerPage={productsPerPage}
              enableFilters={enableFilters}
              currentPage={layoutParams?.page}
              enableLoadMore={enableLoadMore}
            />
            <ProductGrid
              productList={currentPageItems}
              showSubscriptionPrice={showSubscriptionPrice}
              promoTiles={promoTiles}
            />
            {enableLoadMore === true ? (
              <LoadMore
                currentPage={layoutParams?.page}
                itemsPerPage={productsPerPage}
                currentPageItems={currentPageItems}
                items={products}
                setLayoutParams={setLayoutParams}
                scrollThreshold={scrollThreshold || 0}
              />
            ) : (
              <Pagination
                currentPage={layoutParams?.page}
                itemsPerPage={productsPerPage}
                items={products}
                setLayoutParams={setLayoutParams}
                browserHistory={false}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}

Section.displayName = 'Collection Grid Module';

Section.Schema = {
  category: 'Modules',
  label: 'Collection Grid Module',
  key: 'collection-grid',
  fields: [
    {
      component: 'text',
      name: 'sectionTitle',
      label: 'Section Title',
    },
    {
      component: 'textarea',
      name: 'sectionSubtitle',
      label: 'Section Subtitle',
    },
    {
      component: 'text',
      name: 'collectionHandle',
      label: 'Collection Handle',
    },
    {
      component: 'toggle',
      name: 'enableFilters',
      label: 'Toggle Filters',
      toggleLabels: {
        true: 'Enable',
        false: 'Disable',
      },
      defaultValue: true,
    },
    {
      name: 'enableLoadMore',
      component: 'toggle',
      label: 'Enable Load More',
      defaultValue: true,
    },
    {
      name: 'scrollThreshold',
      component: 'number',
      label: 'Scroll Threshold',
      description:
        'What percentage of the page has the customer to scroll before loading more products?, this will depend on the position of the module within the page, please use the scroll bar to figure out the most optimal value for this field.',
      defaultValue: 80,
    },
    {
      component: 'toggle',
      name: 'showSubscriptionPrice',
      label: 'Price Shown',
      toggleLabels: {
        true: 'Subscription Price',
        false: 'Regular Price',
      },
      defaultValue: true,
    },
  ],
};

export const CollectionGrid = Section;
