import { useState, useEffect } from 'react';
import {
  useCartAddItems,
  useCountry,
  useLocalizedVariant,
  useCart,
  useSettings
} from '@backpackjs/storefront';
import { useRouter } from 'next/router';
import { useBuilderContext } from './BundleBuildercontext';
import { Lock } from '@/snippets/icons/Lock';
import styles from './BuilderSteps.module.css';
import BagFull from '@/snippets/icons/BagFull';
import BuilderStep from './BuilderStep';

export default function BuilderSteps() {
  const context = useBuilderContext();
  const { cartAddItems } = useCartAddItems();
  const { localize: localizeVariant } = useLocalizedVariant();
  const { builderSettings } = context;
  const { builderData } = context;
  const currentStepIndex = builderData.selectedProducts.length - 1;
  const currentStep = builderSettings.bundleSteps?.[currentStepIndex] || {};
  const currentDiscount = currentStep?.discountValue || 0;
  const { country } = useCountry();
  const currencySymbol = country?.currency?.symbol || '$';
  const [localizedVariants, setLocalizedVariants] = useState([]);
  const [bundleHasFreeGift, setBundleHasFreeGift] = useState(false);
  const router = useRouter();
  const settings = useSettings();
  const {newFreeProducts: freeProductSettings} = settings ?? {};
  const currentCampaign = freeProductSettings?.gwpScenarios?.find((campaignObject) => campaignObject?.campaign === builderSettings.campaign && campaignObject?.enabled);
  const cart = useCart();

  const hasBundleItems = cart?.lines?.some((line) =>
    line.attributes.find((attr) => attr.key === '_bundleId')
  );

  const localizeVariants = async () => {
    if (builderData.selectedProducts.length === 0) return [];

    const localizationRequest = builderData.selectedProducts.map((product) => {
      return localizeVariant({
        ...product.variants[0],
        handle: product.handle,
      });
    });

    await Promise.all(localizationRequest).then((variants) => {
      setLocalizedVariants(variants);
    });
  };

  const removeProduct = (handle) => {
    const selectedProducts = builderData.selectedProducts.filter(
      (loopProduct) => loopProduct.handle !== handle
    );

    context.updateBuilderData({ selectedProducts });
  };

  const addBundleToCart = async () => {
    const { selectedProducts } = builderData;
    const productIds = selectedProducts.map(
      (product) => product.variants[0].id
    );

    const lineItems = productIds.map((id) => {
      const itemAttributes = [
        {
          key: '_discount',
          value: `${currentStep.discountValue}`,
        },
        {
          key: '_bundleId',
          value: `${new Date().getTime()}`,
        },
        {
          key: '_bundleCount',
          value: `${selectedProducts.length}`,
        },
        {
          key: '_sc',
          value: (typeof window.ShipScoutLineItemProperty !== 'undefined' ? window.ShipScoutLineItemProperty : 'null'),
        }
      ];

      if(builderSettings.campaign){
        itemAttributes.push({
          key: '_campaign',
          value: builderSettings.campaign,
        })
      };

      return {
        merchandiseId: id,
        quantity: 1,
        attributes: itemAttributes,
      };
    });

    await cartAddItems(lineItems, () => {
      const newState = {
        ...window.history.state,
        as: router.pathname.replace('[handle]', router.query.handle),
        url: `${router.pathname}?handle=${router.query.handle}`,
      }

      window.history.replaceState(newState, '', newState.as);
      document.location.href = '/cart';
    });
  };

  useEffect(() => {
    if (currentCampaign) {
      const bundleRequiresProduct = !!currentCampaign?.gwpProductQualify;
      const bundleHasProduct = builderData.selectedProducts.some((product) => product.handle === currentCampaign?.gwpProductQualify?.handle);

      if(bundleRequiresProduct && !bundleHasProduct) {
        setBundleHasFreeGift(false);
        return;
      }

      setBundleHasFreeGift(true);
    }
  }, [builderData.selectedProducts.length, currentCampaign]);

  useEffect(() => {
    localizeVariants();
  }, [builderData.selectedProducts.length, country]);

  const steps = builderSettings.bundleSteps.map((step, index) => {
    let product = null;
    if (builderData.selectedProducts[index] !== undefined) {
      product = builderData.selectedProducts[index];
    }

    return (
      <div
        key={`step${index}`}
        className={`bundle-builder__step relative h-[50px] w-[50px] rounded-md bg-grey-9 font-gothamBold before:absolute before:top-1/2
        before:left-full before:block before:w-full before:border-b last:before:hidden lg:h-[100px] lg:w-[100px] lg:before:top-full
        lg:before:left-1/2 lg:before:h-[33px] lg:before:w-0 lg:before:border-l
        ${
          product
            ? 'border-2 border-solid border-primary-2 before:!border-b-2 before:border-primary-2 lg:before:border-l-2'
            : 'border border-dashed'
        }
        `}
      >
        {product ? (
          <BuilderStep
            product={product}
            removeProduct={removeProduct}
            country={country}
          />
        ) : (
          <div className="bundle-builder__step-content relative flex h-full flex-col items-center justify-center gap-1 lg:gap-2.5">
            {index > 0 && (
              <>
                <div className="bundle-builder__step-icon align-center inline-flex h-5 w-5 flex-shrink-0 items-center justify-center rounded-full bg-black tracking-normal text-white lg:h-7 lg:w-7">
                  <Lock className="block h-auto w-2 lg:w-2.5" />
                </div>
                {step?.discountValue > 0 && (
                  <div className="bundle-builder__step-discount text-sub-xx-small lg:text-sm">
                    {step?.discountValue}% off
                  </div>
                )}
              </>
            )}
          </div>
        )}

        {bundleHasFreeGift && index == currentCampaign?.gwpBundleCount - 1 && (
          <div className={`bundle-builder__step-free-gift absolute -bottom-3.5 lg:-bottom-2.5 left-1/2 w-fit max-w-none -translate-x-1/2 whitespace-nowrap rounded-full border py-1 px-1.5 leading-none text-sub-xx-small
            ${product ? 'border-primary-2 bg-primary-2 text-white' :'border-black bg-quinary-2'}
          `}>
            + Free Gift!
          </div>
        )}
      </div>
    );
  });

  const bundleTotal = localizedVariants?.reduce((total, variant) => {
    return total + parseFloat(variant?.priceV2?.amount);
  }, 0);

  const bundleDiscountedPrice = bundleTotal * (1 - currentDiscount / 100);
  return (
    <div
      className={`${styles['bundle-builder__sidebar-container']} bundle-builder__sidebar-container sticky bottom-0 rounded-tl-[20px] rounded-tr-[20px] bg-grey-9 p-5 lg:relative lg:bottom-auto lg:!ml-0 lg:!w-full lg:rounded-tr-none lg:rounded-tl-none lg:!shadow-none lg:filter-none`}
    >
      <div className="bundle-builder__step-info text-center font-gothamBold text-xs leading-4 lg:text-lg lg:leading-normal">
        {currentStepIndex === -1
          ? builderSettings.defaultBundleMessage
          : currentStep.stepInfo}
      </div>
      <div className={`bundle-builder__steps-container mt-3 flex w-full flex-row items-center justify-center gap-[30px] lg:mt-[30px] lg:mb-[40px] lg:flex-col ${currentCampaign ? 'mb-5' : 'mb-4'}`}>
        {steps}
      </div>

      <button
        type="button"
        className={`bundle-builder__add-to-cart-button button button--checkout group w-full !px-2.5
        ${
          (builderData.selectedProducts.length <= 1 ||
            hasBundleItems === true) &&
          'hover:border-grey-7 hover:bg-grey-7'
        }`}
        disabled={
          builderData.selectedProducts.length <= 1 || hasBundleItems === true
        }
        onClick={addBundleToCart}
      >
        {builderData.selectedProducts.length <= 1 ? (
          'bundle & save'
        ) : (
          <>
            <span className="relative mr-2 inline-block w-6">
              <span
                className={`transition-color relative z-[2] text-sm duration-150
              ${
                hasBundleItems
                  ? 'text-grey-7'
                  : 'text-primary-2 group-hover:opacity-50'
              }`}
              >
                {builderData.selectedProducts.length}
              </span>
              <BagFull className="absolute -top-1.5 h-auto w-6 leading-none" />
            </span>
            add to cart
            <s className="ml-1.5 inline-block">{`${currencySymbol}${bundleTotal
              .toFixed(2)
              .replace('.00', '')}`}</s>
            <span className="dinline-block ml-1.5">
              {`${currencySymbol}${bundleDiscountedPrice
                .toFixed(2)
                .replace('.00', '')}`}
            </span>
          </>
        )}
      </button>
      {hasBundleItems === true && (
        <div className="bundle-builder__error-message mt-2 text-center font-gothamBold text-xs leading-4 text-error">
          You already have a bundle in your cart, please remove it before adding
          a new one.
        </div>
      )}
    </div>
  );
}
