export function ThinArrowRight(props) {
  return (
    <svg
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0.999871 0.999999L6.68408 7L0.99987 13" stroke="currentColor" />
    </svg>
  );
}