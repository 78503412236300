export function Play(props) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 63 63"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      {...props}
    >
      <g transform="matrix(1,0,0,1,3.13253,3.17877)">
        <path
          d="M28,0C12.546,0 0,12.546 0,28C0,43.454 12.546,56 28,56C43.454,56 56,43.454 56,28C56,12.546 43.454,0 28,0ZM28,2C42.35,2 54,13.65 54,28C54,42.35 42.35,54 28,54C13.65,54 2,42.35 2,28C2,13.65 13.65,2 28,2ZM37.961,27.206C38.485,27.606 38.485,28.394 37.961,28.794L23.607,39.771C22.949,40.274 22,39.805 22,38.976L22,17.024C22,16.195 22.949,15.726 23.608,16.229L37.961,27.206Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}