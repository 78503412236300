import { useEffect, useState } from 'react';
import { useCart } from '@backpackjs/storefront';

export const useCartDiscountPrices = () => {
  const cart = useCart();

  const [discountAmount, setDiscountAmount] = useState(0);

  // discountAmount used for cart level discount such as discount
  // code that applies to the whole order
  useEffect(() => {
    const _discountAmount = cart?.discountAllocations?.reduce(
      (carry, discount) => {
        if (discount?.discountedAmount?.amount) {
          return carry + Number(discount.discountedAmount.amount);
        }
        return carry;
      },
      0
    );
    setDiscountAmount(Number(_discountAmount || 0));
  }, [cart?.updatedAt]);

  return { discountAmount };
};
