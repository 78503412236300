import { useBuilderContext } from './BundleBuildercontext';
import BuilderProductCard from './BuilderProductCard';

export default function BuilderGrid() {
  const context = useBuilderContext();
  const productsData = context.builderData.paginatedProducts;

  const buildProductItems = () => {
    const products = productsData?.map((product, index) => {
      return (
        <BuilderProductCard
          handle={product.handle}
          index={index}
          isSearch={false}
          key={product.handle}
        />
      );
    });
    return products;
  };

  const productItems = buildProductItems();

  return (
    <div className="builder-grid grid grid-cols-2 gap-5 lg:grid-cols-3">
      {productItems}
    </div>
  );
}
