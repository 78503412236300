import Script from 'next/script';

export function BeamCommunityImpact({ cms }) {
  return (
    <div
      id={`${BeamCommunityImpact.Schema.key}-${cms.id || cms.cmsId || cms.tinaId}`}
      className="store-locator-container px-4 pb-12 lg:px-12"
    >
      <style>
        {`#${BeamCommunityImpact.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
            margin-top: ${cms.mobileSpacing}px;
          }
          @media(min-width: 1024px) {
            #${BeamCommunityImpact.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
              margin-top: ${cms.desktopSpacing}px;
            }
          }
          #beam-community-widget-wrapper {
            display: block;
            max-width: 870px;
            height: auto;
            margin: 20px auto 20px auto;
            padding:0 20px;
            postion: relative;
          }
          .beam-community-title {
            text-align: center;
            font-size: 30px;
            line-height: 39px;
            font-family: Tiempos, serif;
            font-weight: normal;
            text-transform: capitalize;
            margin-bottom: 12px;
          }
          .beam-community-description {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            margin-top: 0px;
            font-family: Gotham, sans-serif;
            margin-bottom: 0;
            text-align: center;
            color: #4A4A4A;
          }

          @media screen and (max-width: 430px) {
            .beam-community-description {
            font-size: 14px;
            line-height: 21px;
          }
          }
          .beam-community-impact-container {
            margin: 15px 0
        }`}
      </style>

      <Script
        type="module"
        async
        crossorigin
        src="https://production-beam-widgets.beamimpact.com/web-sdk/v1.39.2/dist/components/community-impact.esm.js"
      />

      <div>
        <div
          id="beam-community-widget-wrapper"
          className="beamContainer"
          name="impact"
        >
          <h2 className="beam-community-title">
            The Briogeo Community's Impact
          </h2>
          <p className="beam-community-description">
            1% of every Briogeo purchase is donated to a nonprofit of your choice.
          </p>

          <p className="beam-community-description">
            Check out the impact the Briogeo community is making below.
          </p>

          <div id="community" className="beam-community-impact-container">
            <beam-community-impact
              apiKey="1FZ6VRy197TU.0e43c50d-caa7-4d36-a559-6a893b37fba6"
              chainId="270"
              storeId="439"
              baseUrl="https://production-central-backend-ne.beamimpact.com"
              cardStyle="image"
              class="custom"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

BeamCommunityImpact.displayName = 'BeamCommunityImpact';

BeamCommunityImpact.Schema = {
  category: 'BeamCommunityImpact',
  label: BeamCommunityImpact.displayName,
  key: 'beamcommunityimpact',
  fields: [
    {
      component: 'number',
      name: 'mobileSpacing',
      label: 'Section Top Margin - Mobile',
      defaultValue: 60,
    },
    {
      component: 'number',
      name: 'desktopSpacing',
      label: 'Section Top Margin - Desktop',
      defaultValue: 80,
    },
  ],
};
