function WhiteTikTok() {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1325 3.63539C13.0157 3.57605 12.9019 3.51099 12.7917 3.44048C12.4715 3.23251 12.1779 2.98749 11.9172 2.71068C11.2642 1.9771 11.0204 1.23295 10.9311 0.712422H10.9342C10.8596 0.279529 10.8904 0 10.8958 0H7.92295V11.2892C7.92295 11.4403 7.92295 11.5906 7.91679 11.7387C7.91679 11.7569 7.91448 11.7735 7.91372 11.7939C7.91372 11.8014 7.91372 11.8105 7.91141 11.8188V11.8256C7.88019 12.2306 7.74812 12.6218 7.52682 12.9648C7.30551 13.3079 7.00176 13.5922 6.64229 13.7929C6.26727 14.0024 5.8431 14.1123 5.41163 14.1117C4.02714 14.1117 2.90417 13.0026 2.90417 11.633C2.90417 10.2625 4.02714 9.15345 5.41163 9.15345C5.67392 9.15345 5.93466 9.19425 6.18387 9.27433L6.18772 6.30074C5.43087 6.20483 4.662 6.26408 3.92964 6.47473C3.19728 6.68538 2.51733 7.04287 1.93272 7.52463C1.42034 7.96171 0.989561 8.48326 0.659755 9.06582C0.534382 9.27811 0.0605786 10.1326 0.0036607 11.5181C-0.0324899 12.3038 0.208258 13.1197 0.322863 13.4559V13.4635C0.394395 13.6614 0.674369 14.3383 1.12971 14.9087C1.49691 15.3666 1.93076 15.7688 2.41729 16.1024V16.0948L2.42421 16.1024C3.86254 17.0619 5.45855 16.9992 5.45855 16.9992C5.73468 16.9878 6.65998 16.9992 7.71066 16.5104C8.87594 15.9679 9.53895 15.1603 9.53895 15.1603C9.96285 14.6777 10.2999 14.1276 10.5358 13.5338C10.805 12.8387 10.8942 12.0062 10.8942 11.6737V5.68351C10.9304 5.70466 11.4111 6.01668 11.4111 6.01668C11.4111 6.01668 12.1033 6.4526 13.1825 6.7359C13.9562 6.93762 15 6.98068 15 6.98068V4.08188C14.6346 4.12117 13.8924 4.00785 13.1317 3.63615L13.1325 3.63539Z"
        className="fill-white hover:fill-grey-7"
      />
    </svg>
  );
}

export default WhiteTikTok;
