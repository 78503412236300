const BEAM_BASE_URL="https://production-central-backend-ne.beamimpact.com"
const BEAM_API_KEY="1FZ6VRy197TU.0e43c50d-caa7-4d36-a559-6a893b37fba6"
const BEAM_STORE_ID=439
const BEAM_CHAIN_ID=270

export {
  BEAM_BASE_URL,
  BEAM_API_KEY,
  BEAM_STORE_ID,
  BEAM_CHAIN_ID
}