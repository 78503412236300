import { useRef, useState } from 'react';
import Slider from 'react-slick';
import { Image } from "@/snippets/components/Image";

export function Quotes({cms}) {
  const slider = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const quoteSlides = cms.quotes.filter((slide) => {
    if(slide.authorImage || slide.author) {
      return true;
    }
  });

  const afterChangeHandler = (oldIndex, newIndex) => {
    setCurrentSlide(newIndex);
  }

  const buildSlides = () => {
    return quoteSlides.map((quoteObj) => {
      return (
        <div className='w-full'>
          <p className='quotes__quote font-tiempos text-h2-m lg:text-h2 mb-0'>{quoteObj.quote}</p>
          {quoteObj.authorImage ? (
            <Image
              src={quoteObj.authorImage?.src}
              alt={quoteObj.authorImage?.alt || ''}
              width={quoteObj.authorImage.width}
              height={quoteObj.authorImage.height}
              loading="lazy"
              sizes="100vw"
              className="h-20 w-auto md:!hidden mx-auto mt-6 mb-2"
            />
          ): (
            <p className='quotes__author font-gothamBold text-h5-m mb-2 mt-6 md:!hidden'>{quoteObj.author}</p>
          )}
        </div>
      )
    })
  }

  const buildQuotesButtons = () => {
    return quoteSlides.map((quoteObj, index) => {
      const markerClasses = "quotes__button transition-all duration-300 ease-in-out h-20 after:relative after:w-full after:border-b-2 after:border-currentcolor after:opacity-0 after:block after:mt-2.5"
      const activeClasses = 'after:opacity-100 opacity-100'

      if(quoteObj.authorImage) {
        return <button
        key={'slider-button-' + index}
        className={`quotes__button ${markerClasses} ${index === currentSlide ? activeClasses : 'opacity-40'}`}
        onClick={() => slider.current.slickGoTo(index)}
        >
          <Image
            src={quoteObj.authorImage?.src}
            alt={quoteObj.authorImage?.alt || ''}
            width={quoteObj.authorImage.width}
            height={quoteObj.authorImage.height}
            loading="lazy"
            sizes="100vw"
            className="h-20 w-auto mx-auto"
          />
        </button>
      } else {
        return (
          <button
            key={'slider-button-' + index}
            className={`quotes__button font-gothamBold text-h5 ${markerClasses} ${index === currentSlide ? activeClasses : 'opacity-50'}`}
            onClick={() => slider.current.slickGoTo(index)}>
              {quoteObj.author}
          </button>
        )
      }
    })
  }

  const slideSettings = {
    slidesToShow: 1,
    infinite: true,
    centerMode: true,
    dots: false,
    arrows: false,
    fade: false,
    adaptiveHeight: true,
    centerPadding: 0,
    beforeChange: afterChangeHandler,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          dots: true,
        }
      }
    ]
  }
  return (
    <section className={`quotes__container pt-12 pb-10 md:pt-16 md:pb-14 w-full px-5 ${cms.fgColor} ${cms.bgColor} [&_.slick-dots_li]:border-currentcolor [&_.slick-dots_li]:bg-currentcolor [&_.slick-dots_li.slick-active]:bg-transparent [&_.slick-dots_li.slick-active~li]:!bg-currentcolor`}>
      <div className="quotes__inner mx-auto text-center">
        <h2 class="font-gothamBold text-h6-m lg:text-h6 mb-4 md:mb-6">{cms.heading}</h2>
        {cms.quotes && (
          <>
            <Slider className="quotes__inner max-w-[990px] mt-5 lg:mb-12 mx-auto" {...slideSettings} ref={slider}>
              {buildSlides()}
            </Slider>
            <div className="quotes__custom-buttons hidden lg:flex flex-wrap gap-9 justify-center">
              {buildQuotesButtons()}
            </div>
          </>
        )}
      </div>
    </section>
  )
}

Quotes.displayName = "Quotes";

Quotes.Schema = {
  category: 'Modules',
  label: Quotes.displayName,
  key: 'quotesModule',
  fields: [
    {
      component: 'select',
      name: 'bgColor',
      label: 'Background Color',
      description: 'For section background color, select one from preselected brand colors.',
      options: [
        { label: 'White', value: 'bg-white' },
        { label: 'Grey', value: 'bg-grey-1' },
        { label: 'Light Grey', value: 'bg-grey-9' },
        { label: 'Blue 1', value: 'bg-quinary-1' },
        { label: 'Blue 2', value: 'bg-quinary-2' },
        { label: 'Blue 3', value: 'bg-light-blue' },
        { label: 'Cream 1', value: 'bg-tertiary-2' },
        { label: 'Cream 2', value: 'bg-senary-4' },
        { label: 'Purple', value: 'bg-tertiary-1' },
        { label: 'Green', value: 'bg-quaternary-1' },
        { label: 'Pink 1', value: 'bg-primary-2' },
        { label: 'Pink 2', value: 'bg-secondary-1' },
        { label: 'Yellow', value: 'bg-yellow' },
        { label: 'Orange', value: 'bg-secondary-2' },
        { label: 'Seafoam 1', value: 'bg-quaternary-2' },
        { label: 'Seafoam 2', value: 'bg-senary-3' },
        { label: 'Seafoam 3', value: 'bg-senary-5'},
        { label: 'Air Dry Cream', value: 'bg-stylers-1' },
        { label: 'Sculpting Cream', value: 'bg-stylers-2' },
        { label: 'Texture Foam', value: 'bg-stylers-3' },
        { label: 'Sleek Stick', value: 'bg-stylers-4' }
      ],
      defaultValue: 'bg-white',
    },
    {
      name: 'fgColor',
      label: 'Foreground Color',
      component: 'select',
      description:
        'For section foreground color, select one from preselected brand colors.',
      options: [
        { label: 'Light', value: 'text-white' },
        { label: 'Dark', value: 'text-grey-1' },
      ],
      defaultValue: 'text-grey-1',
    },
    {
      component: 'text',
      name: 'heading',
      label: 'Section Heading',
    },
    {
      component: 'group-list',
      name: 'quotes',
      label: 'Quotes',
      fields: [
        {
          component: 'textarea',
          name: 'quote',
          label: 'Quote',
          description: 'The quote text',
        },
        {
          component: 'text',
          name: 'author',
          label: 'Author',
          description: 'The author of the quote, if no author image is populated this value will be shown',
        },
        {
          component: 'image',
          name: 'authorImage',
          label: 'Author Image',
          description: 'Optional, used to populate the author\'s logo or image',
        }
      ]
    }
  ]
};