import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import wrap from 'rehype-wrap';

export function Markdown({content, ...props}) {
  return (
    <ReactMarkdown
        {...props}
        rehypePlugins={[
          rehypeRaw,
          [wrap, { wrapper: 'div.table-wrapper', selector: 'table' }],
        ]}
      >
        {content}
      </ReactMarkdown>
  )
}