import { useState, useEffect } from 'react';
import { useSettings, useRouter } from '@backpackjs/storefront';
import { useDataLayerActions, useDebounce } from '@hooks';
import { IconSearch } from '@/snippets/index';
import ProductCard from '@/snippets/globals/layout/ProductCard';

function Search({ searchOpen, closeSearch }) {
  const settings = useSettings();
  const router = useRouter();
  const [rawSearch, setRawSearch] = useState('');
  const [activeSearch, setActiveSearch] = useState('');
  const [searchProducts, setSearchProducts] = useState(null);
  const debouncedSearchTerm = useDebounce(rawSearch, 500);
  const { sendViewSearchResultsEvent } = useDataLayerActions();

  useEffect(() => {
    if (activeSearch !== debouncedSearchTerm) {
      setActiveSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (!searchOpen) {
      setRawSearch('');
      setActiveSearch('');
      setSearchProducts(null);
    }
  }, [searchOpen]);

  useEffect(() => {
    const searchShopify = async (term) => {
      try {
        const options = { method: 'POST', body: JSON.stringify({ term }) };
        const request = await fetch(`/api/shopify/search`, options);
        const { response } = await request.json();
        const shopifyProducts = response?.results?.products || [];
        const filtered = shopifyProducts?.filter(
          (p) => !p?.tags?.includes('findify-remove')
        );

        setSearchProducts(filtered);
      } catch (e) {
        console.error('searchShopify:error', e);
        return [];
      }
    };

    if (activeSearch && activeSearch.length > 1) {
      searchShopify(activeSearch);
    } else {
      setSearchProducts(null);
    }
  }, [activeSearch]);

  useEffect(() => {
    sendViewSearchResultsEvent({
      products: searchProducts,
      isSearchPage: false,
      searchTerm: activeSearch,
    });
  }, [searchProducts, sendViewSearchResultsEvent]);

  const handleButtonClick = () => {
    closeSearch();
    setRawSearch('');
    setActiveSearch('');
    setSearchProducts(null);

    if (location?.pathname.includes('/search')) {
      window.location.assign(`/search?search=${activeSearch}`);
    } else {
      router.push(
        {
          pathname: '/search',
          query: {
            search: activeSearch,
          },
        },
        undefined,
        { shallow: true }
      );
    }
  };

  const hasSearchProducts = activeSearch && searchProducts?.length > 0;
  const noProducts = searchProducts && searchProducts?.length === 0;

  const searchSettings = settings?.search;
  const terms = searchSettings?.terms || [];
  const products = searchSettings?.products || [];

  return (
    <>
      <div
        className={`absolute w-full border-t border-b border-grey-7 bg-white transition-all${
          searchOpen
            ? ' opacity-100'
            : ' pointer-events-none invisible opacity-0'
        }`}
        style={{ zIndex: 1 }}
      >
        <div className="w-full border-b border-grey-7 px-4 md:px-12">
          <div className="z-2 relative w-full px-6">
            <button
              type="button"
              aria-label="search"
              className="hidden py-2 px-5"
            >
              <IconSearch />
            </button>
            <input
              id="search-input"
              name="search"
              value={rawSearch}
              placeholder="search by typing keywords…"
              autoComplete="off"
              className="w-full py-3 px-4 placeholder-grey-5 transition-all placeholder-shown:border-grey-7 hover:border-black"
              style={{ outline: 'none', color: '#44444' }}
              onChange={(e) => setRawSearch(e.target.value)}
            />
            <button
              type="button"
              aria-label="cancel search"
              className="absolute left-0 top-1/2 z-0 h-full -translate-y-1/2 py-2 px-0"
              onClick={() => {
                if (activeSearch && hasSearchProducts) {
                  handleButtonClick();
                } else if (!activeSearch) {
                  closeSearch();
                }
              }}
            >
              <IconSearch />
            </button>
          </div>
        </div>

        <div className="px-4 pt-5 pr-0 md:px-10">
          {activeSearch && hasSearchProducts && (
            <div>
              <div className="overflow-auto">
                <div className="relative m-auto grid max-w-[1000px] grid-cols-[repeat(4,_160px)] gap-[0px] pt-5 pr-3 md:grid-cols-4 md:gap-[24px]">
                  {searchProducts?.slice(0, 4)?.map((product, index) => (
                    <div
                      key={index}
                      className="max-w-[250px] pr-3 md:max-w-none md:pr-0"
                      style={{
                        width: '100%',
                        margin: '0px auto',
                      }}
                    >
                      <ProductCard handle={product?.handle} isSearch searchTerm={activeSearch} />
                    </div>
                  ))}
                </div>
              </div>
              <div className="mt-4 w-full text-center">
                <button
                  type="button"
                  className="button button--outline mb-5"
                  onClick={handleButtonClick}
                >
                  view all results
                </button>
              </div>
            </div>
          )}
          {activeSearch && noProducts && (
            <div>
              <div>
                <p className="mb-[20px] text-[18px] text-primary-1 md:text-h4">
                  {`No results for "${activeSearch}"`}
                </p>
                <p className="mb-[40px] pr-5 font-gothamBold text-[16px] text-primary-1 md:text-[18px]">
                  {`We couldn’t find a match for "${activeSearch}" but try these popular products`}
                </p>
                <div className="overflow-auto pr-5">
                  <div className="relative grid max-w-[1000px] grid-cols-[repeat(4,_165px)] gap-[12px] pb-5 md:grid-cols-4 md:gap-[24px]">
                    {products?.slice(0, 4)?.map((product, index) => (
                      <div
                        key={index}
                        className="max-w-[220px] pr-2 md:max-w-none"
                        style={{
                          width: '100%',
                          margin: '0px auto',
                        }}
                      >
                        <ProductCard
                          handle={product?.product?.handle}
                          isSearch
                          searchTerm={activeSearch}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
          {(!activeSearch || !searchProducts) && (
            <div
              className="grid grid-cols-1 overflow-auto md:mt-5 md:grid-cols-[300px_1fr]"
              style={{ maxHeight: 'calc(100vh - 160px)' }}
            >
              <div>
                <p className="mb-2 font-gothamBold text-[12px] text-primary-1 md:mb-8 md:text-[18px]">
                  {searchSettings?.termsHeading || 'Popular Searches'}
                </p>
                {terms.map((term, index) => (
                  <button
                    key={index}
                    type="button"
                    className="block"
                    onClick={() => setRawSearch(term?.value)}
                  >
                    <p className="mb-[3px] inline-block cursor-pointer text-[14px] text-grey-5 hover:text-grey-1 md:text-[16px]">
                      {term?.label}
                    </p>
                  </button>
                ))}
              </div>
              <div>
                <p className="mt-5 font-gothamBold text-[12px] text-primary-1 md:mt-0 md:text-[18px]">
                  {searchSettings?.productsHeading || 'Popular Products'}
                </p>
                <div className="overflow-auto">
                  <div className="relative grid grid-cols-[repeat(4,_165px)] gap-[0px] md:flex md:grid-cols-4 md:gap-[24px]">
                    {products?.slice(0, 3)?.map((product, index) => (
                      <div
                        key={index}
                        className="max-w-[220px] pr-3 md:flex-1 md:pr-0"
                      >
                        <ProductCard
                          handle={product?.product?.handle}
                          isSearch
                          searchTerm={activeSearch}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {searchOpen && (
        <div
          style={{
            background: 'black',
            opacity: 0.4,
            position: 'absolute',
            bottom: 0,
            top: 100,
            left: 0,
            right: 0,
            width: '100%',
            height: '100vh',
            zIndex: 0,
          }}
        />
      )}
    </>
  );
}

export default Search;
