import { useCallback } from 'react';
import { useLocalizedVariant } from '@backpackjs/storefront';

export const useLocalizedSelectedVariant = () => {
  const { localize } = useLocalizedVariant();

  const getLocalizedVariant = useCallback(async ({ countryCode, variant }) => {
    try {
      if (!variant) return null;
      if (!countryCode) return variant;

      const localizedVariant = await localize({
        handle: variant.product.handle,
        selectedOptions: variant.selectedOptions,
        country: countryCode,
      });

      if (!localizedVariant) return variant;

      const localCurrencyCode = localizedVariant.priceV2?.currencyCode;
      const localizedAmount = localizedVariant.priceV2?.amount?.toFixed(2);
      const localizedCompareAtAmount =
        localizedVariant.compareAtPriceV2?.amount?.toFixed(2);

      return {
        ...variant,
        currencyCode: localCurrencyCode,
        priceV2: {
          amount: localizedAmount || variant.priceV2?.amount,
          currencyCode: localCurrencyCode,
        },
        compareAtPriceV2: {
          amount: localizedCompareAtAmount || variant.compareAtPriceV2?.amount,
          currencyCode: localCurrencyCode,
        },
        price: localizedAmount || variant.price,
        compareAtPrice: localizedCompareAtAmount || variant.compareAtPrice,
      };
    } catch (error) {
      console.error('getLocalizedVariant:error', error.message);
      return variant;
    }
  }, []);

  return { getLocalizedVariant };
};
