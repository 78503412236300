import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useThrottle } from '@hooks';

export default function LoadMore({
  currentPage,
  itemsPerPage,
  items,
  setLayoutParams,
  scrollThreshold,
  currentPageItems,
  browserHistory,
}) {
  const maxPages = Math.ceil(items.length / itemsPerPage);
  const isBrowser = typeof window !== 'undefined';
  const router = useRouter();
  const [scrollPosition, setScrollPosition] = useState(0);
  const throttledScrollPosition = useThrottle(scrollPosition, 20);

  const [loading, setLoading] = useState(false);
  const loadNewPage = () => {
    const nextPage = currentPage + 1;
    if (nextPage > maxPages || loading === true) return;
    setLayoutParams((layoutParams) => {
      const newParams = {
        ...layoutParams,
        page: currentPage + 1,
      };
      const urlParams = new URLSearchParams(newParams);
      if (isBrowser && browserHistory) {
        const newState = {
          ...window.history.state,
          url: router.pathname,
          as:
            router.pathname.replace('[handle]', router.query.handle) +
            '?' +
            urlParams.toString(),
        };
        window.history.replaceState(newState, null, newState.as);
      }
      return newParams;
    });
  };

  const scrollHandler = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    if (isBrowser && !!scrollThreshold && scrollThreshold > 0 && currentPage < maxPages) {
      window.addEventListener('scroll', scrollHandler);
    }
    if (currentPageItems.length === currentPage * itemsPerPage) {
      setLoading(false);
    }

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [currentPageItems.length]);

  useEffect(() => {
    const windowHeight = isBrowser ? document?.body?.offsetHeight : 0;
    const currentScrollThreshold = windowHeight * (scrollThreshold * 0.01);

    if (window.scrollY >= currentScrollThreshold) {
      loadNewPage();
    }
  }, [throttledScrollPosition]);

  return (
    (!scrollThreshold || scrollThreshold === 0) &&
    currentPage < maxPages && (
      <div className="load-more__container my-10 text-center">
        <button
          onClick={loadNewPage}
          type="button"
          className="button button--primary"
        >
          {loading ? 'loading...' : 'load more'}
        </button>
      </div>
    )
  );
}
