import { useCountry } from "@backpackjs/storefront"
import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { Markdown } from "../globals/layout/Markdown";
import { ThinArrowLeft } from "../icons/ThinArrowLeft";
import { ThinArrowRight } from "../icons/ThinArrowRight";

export default function PromoSlider({settings}) {
  const slider = useRef();
  const { country } = useCountry();
  const countryCode = country?.isoCode || 'US';
  const isUs = countryCode == 'US';
  const slides = settings.slide.filter((slideObject) => {
    const {customerBase} = slideObject;
    return isUs ? ['all', 'us'].includes(customerBase) : ['all', 'intl'].includes(customerBase);
  });

  const sliderSettings = {
    infinte: true,
    autoplay: settings.enableAutoplay || false,
    autoplaySpeed: settings.autoplaySpeed * 1000,
    arrows: false,
    adaptiveHeight: true,
  }

  useEffect(() => {
    if(sliderSettings.autoplay == true) {
      slider?.current?.slickPlay();
    }
  }, [settings]);

  if (slides.length == 0) {
    return null;
  }

  return (
    <div className="promo-slider relative" style={{backgroundColor: settings.backgroundColorHex}}>
      {slides.length > 1 && (
        <button
          type="button"
          className={`promo-slider-back-arrow absolute  left-2 top-1/2 z-[1] -translate-y-1/2 border-none shadow-none ${settings?.textColor}`}
          onClick={() => slider.current.slickPrev()}
        >
          <ThinArrowLeft className="h-auto w-1.5" />
          <div className="sr-only">move one slide back</div>
        </button>
      )}
      <Slider ref={slider} {...sliderSettings} className="leading-0 py-2">
        {slides.map((slideObject, index) => {
          return (
            <Markdown
              key={`promo-slide-${index}`}
              content={slideObject.text}
              className={`leading-0 px-5 text-center text-xs ${settings?.textColor}`}
            />
          );
        })}
      </Slider>
      {slides.length > 1 && (
        <button
          type="button"
          className={`promo-slider-forward-arrow absolute right-2 top-1/2 -translate-y-1/2 border-none shadow-none ${settings.textColor}`}
          onClick={() => slider.current.slickNext()}
        >
          <ThinArrowRight className="h-auto w-1.5" />
          <div className="sr-only">move one slide forward</div>
        </button>
      )}
    </div>
  );
}