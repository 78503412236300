import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import remarkDirective from 'remark-directive';
import { visit } from 'unist-util-visit';
import { h } from 'hastscript/html.js';
import wrap from 'rehype-wrap';
import styles from './Contentwithsidebar.module.css';
import SidebarNav from './Sidebar';

const components = {
  iframe: ({ node, ...props }) => {
    return props.src.includes('youtube.com') ? (
      <div className="responsive-video pb-youtube">
        <iframe {...props} />
      </div>
    ) : (
      <iframe {...props} />
    );
  },
};

// remark plugin to add a custom tag to the AST
function htmlDirectives() {
  function ondirective(node) {
    const data = node.data || (node.data = {});
    const hast = h(node.name, node.attributes);
    const attributes = node.attributes || {};
    if (node.name !== 'youtube' && node.type !== 'textDirective') {
      data.hName = hast.tagname;
      data.hProperties = hast.properties;
    } else {
      const { id } = attributes;
      data.hName = 'iframe';
      data.hProperties = {
        src: `https://www.youtube.com/embed/${id}`,
        width: '100%',
        height: 200,
        frameBorder: 0,
        allow:
          'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
        allowFullScreen: true,
      };
    }
  }

  function transform(tree) {
    visit(
      tree,
      ['textDirective', 'leafDirective', 'containerDirective'],
      ondirective
    );
  }

  return transform;
}

function Section({ cms }) {
  const has_sidebar = !!(cms?.enable_sidebar && cms?.menu?.length);
  return (
    <div
      id={`${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId}`}
      data-comp={Section.displayName}
      className="px-4 py-6 lg:px-12 lg:py-16"
    >
      <style>
        {`#${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
            margin-top: ${cms.mobileSpacing}px;
          }
          @media(min-width: 1024px) {
            #${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
              margin-top: ${cms.desktopSpacing}px;
            }
          }`}
      </style>
      <div className="relative m-auto flex max-w-full flex-wrap lg:container">
        {has_sidebar ? (
          <div className="w-full flex-shrink-0 lg:w-1/4">
            <SidebarNav menu={cms.menu} />
          </div>
        ) : (
          ''
        )}
        <div
          className={`${
            has_sidebar ? 'mt-6 lg:mt-0 lg:w-3/4 lg:pl-[12%]' : ''
          } w-full flex-grow ${styles.gctContainer}`}
        >
          <ReactMarkdown
            remarkPlugins={[remarkDirective, remarkGfm, htmlDirectives]}
            rehypePlugins={[
              rehypeRaw,
              [wrap, { wrapper: 'div.table-wrapper', selector: 'table' }],
            ]}
            components={components}
          >
            {cms?.content}
          </ReactMarkdown>
        </div>
      </div>
    </div>
  );
}

Section.displayName = 'Content with sidebar';
Section.Schema = {
  label: Section.displayName,
  category: 'Page',
  key: 'contentwithsidebar',
  fields: [
    {
      component: 'number',
      name: 'mobileSpacing',
      label: 'Section Top Margin - Mobile',
      defaultValue: 60,
    },
    {
      component: 'number',
      name: 'desktopSpacing',
      label: 'Section Top Margin - Desktop',
      defaultValue: 80,
    },
    {
      component: 'toggle',
      name: 'enable_sidebar',
      label: 'Enable Sidebar',
      toggleLabels: {
        true: 'Enable',
        false: 'Disable',
      },
      defaultValue: true,
    },
    {
      label: 'Sidebar Menu',
      name: 'menu',
      component: 'group-list',
      itemProps: {
        label: '{{item.link.text}}',
      },
      fields: [
        {
          label: 'Link',
          name: 'link',
          component: 'link',
        },
      ],
    },
    {
      name: 'content',
      label: 'Content',
      component: 'markdown',
      description: 'This is a markdown field',
    },
  ],
};

export const Contentwithsidebar = Section;
