import { useState, useEffect, useRef } from 'react';
import { useCustomer, useCountry, useSettings, } from '@backpackjs/storefront';
import Link from 'next/link';
import { IconClose } from '@/snippets/index';
import SwellPoints from './SwellPoints';

function NavMobile({ menu, navOpen, closeNav }) {
  const settings = useSettings();
  const customer = useCustomer();
  const menuDrawer = useRef(null);
  const [menuActive, setMenuActive] = useState(0);
  const [menuShopActive, setMenuShopActive] = useState(1);
  const { country } = useCountry();
  const countryCode = country?.isoCode || 'US';
  const isUs = countryCode === 'US';

  useEffect(() => {
    return () => {};
  }, []);

  if (!menu) return null;

  const itemsWOcol = menu.filter((item) => {
    return item?.column && !item?.column?.length;
  });
  const itemsWcol = menu.filter((item) => {
    return item?.column && item?.column?.length;
  });

  return (
    <div className="block lg:hidden">
      <div
        id="menu-drawer"
        className={`left-min fixed top-0 z-[2001] flex h-full w-full max-w-[375px] flex-col bg-white transition-all duration-300${
          navOpen ? ' opacity-1 translate-x-0' : ' -translate-x-full opacity-0'
        }`}
        ref={menuDrawer}
      >
        <div className="menu-drawer__header flex w-full items-start justify-between py-2.5 pl-4 pr-0">
          <ul role='menu' className="m-0 flex-grow p-0">
            {itemsWOcol.map((item, itemIndex) => (
              <li
                role='none'
                key={`m-menu-level1-${itemIndex}`}
                className="mt-1 flex items-center justify-between first:mt-0"
              >
                <Link href={item?.link?.url ? item?.link?.url : '/'}>
                  <a role='menuitem' className="relative block border-b-2 border-black font-gothamBold text-sm no-underline">
                    {item?.link?.text}
                  </a>
                </Link>
              </li>
            ))}
          </ul>
          <button
            type="button"
            className="px-4 py-2"
            onClick={() => {
              closeNav();
            }}
          >
            <IconClose />
            <span className="sr-only">Menu close</span>
          </button>
        </div>
        <div className="overflow-y-auto overflow-x-hidden">
          <div className="relative z-0 w-full bg-white">
            <ul role='menu' className="m-0 flex w-full snap-x snap-proximity list-none flex-nowrap overflow-x-auto p-0">
              {itemsWcol.map((item, itemIndex) => (
                <li
                  role='none'
                  key={`m-menu-level1-${itemIndex}`}
                  className="m-0 w-1/2 shrink-0 snap-center first:mt-0"
                >
                  <Link href={item?.link?.url ? item?.link?.url : '/'}>
                    <a role='menuitem'
                      className={`relative block border-t-2 border-b-2 border-yellow p-2.5 font-gothamBold text-base no-underline text-center${
                        menuActive === itemIndex ? ' bg-yellow text-black' : ''
                      }`}
                      onClick={(evt) => {
                        evt.preventDefault();
                        setMenuActive(itemIndex);
                        evt.target.innerText?.toLowerCase().includes('shop')
                          ? setMenuShopActive(1)
                          : setMenuShopActive(0);
                      }}
                    >
                      {item.link.text}
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
            <div className="m-0 w-full p-0">
              {itemsWcol.map((item, itemIndex) => {
                const columns = item?.column || [];
                return menuActive === itemIndex ? (
                  <ChildMenu
                    key={`m-menu-column-${itemIndex}`}
                    columns={columns}
                    index={itemIndex}
                  />
                ) : null;
              })}
            </div>
          </div>

          <div className="menu-drawer__footer px-4 py-6 pt-4">
            {!menuShopActive && settings?.header?.cta?.link && !settings?.header?.rewards_enabled ? (
                  <p className={`mb-8 block lg:hidden whitespace-nowrap text-center w-full`}>
                    <Link href={settings?.header?.cta?.link?.url}>
                      <a role='menuitem' className={`${settings?.header?.cta?.style} text-center no-underline !text-sm`}>
                        {settings?.header?.cta?.link?.text}
                      </a>
                    </Link>
                  </p>
                ) : null}

              {!menuShopActive && isUs && settings?.header?.rewards_enabled ? (
                <button
                type="button"
                className="button button--primary mb-8 block w-full whitespace-nowrap !border !px-5 !py-2 !text-sm lg:hidden"
              >
                {!customer ? (
                  <Link href="/pages/rewards">
                    <a role='menuitem' className="font-gothamBold no-underline">earn rewards</a>
                  </Link>
                ) : (
                  <Link href="/pages/rewards">
                    <a role='menuitem' className="font-gothamBold no-underline">redeem rewards
                    <SwellPoints key={customer.id} /></a>
                  </Link>
                )}
              </button>
              ) : null}
            <div className="mt-2">
              <Link href={`${customer ? '/account' : '/account/login'}`}>
                <a role='menuitem' className="text-sm text-black no-underline hover:text-black">{`${
                  customer ? 'Account' : 'Sign In'
                }`}</a>
              </Link>
            </div>
            <div className="mt-2">
              <Link href="/pages/privacy-policy">
                <a role='menuitem' className="text-sm text-black no-underline hover:text-black">
                  Return Policy
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ChildMenu({ columns }) {
  const { country } = useCountry();
  const isUs = country?.isoCode === 'US';
  // Note: added logic to hide virtual bundles from non-US countries (see line 228)

  const ColNumber = columns?.length || 0;
  if (!columns || !ColNumber) return false;
  const is_megamenu = ColNumber > 1 || (ColNumber === 1 && columns[0]?.cta);
  return (
    <div>
      {columns.map((col, colIndex) => {
        const menuItems = col?.menu || [];
        const cta = col?.cta || [];
        const has_cta = col?.cta && col?.cta?.image;

        return (
          <ul role='menu' key={colIndex} className="m-0 list-none p-0">
            {has_cta ? (
              <li
                role='none'
                className={`menu-item-cta${
                  menuItems.length ? ' border-b border-grey-7' : ''
                }`}
                key={`m-cta-${colIndex}`}
              >
                {cta.url ? (
                  <Link href={cta?.url}>
                    <a role='menuitem' className="no-underline hover:underline">
                      <img
                        src={cta?.image?.src}
                        loading="lazy"
                        className="h-full w-full object-cover"
                        alt={cta?.title}
                      />
                      {cta?.title && (
                        <span className="my-3 block px-4 font-gothamBold">
                          {cta?.title}
                        </span>
                      )}
                    </a>
                  </Link>
                ) : (
                  <>
                    <img
                      src={cta?.image?.src}
                      loading="lazy"
                      className="h-full w-full object-cover"
                      alt={cta?.title}
                    />
                    {cta?.title && (
                      <span className="my-3 block px-4 font-gothamBold">
                        {cta?.title}
                      </span>
                    )}
                  </>
                )}
              </li>
            ) : null}
            {menuItems.map((childmenu, childmenuIndex) => {
              const childItems = childmenu?.child_menu || [];
              return childmenu?.link?.text ? (
                <li
                  role='none'
                  key={`m-menu-level2-${childmenuIndex}`}
                  className={`level-2 m-0${
                    childItems.length
                      ? ' has-child border-b border-grey-7 p-0'
                      : ' first:mt-4'
                  } ${childmenu?.open_column ? 'is-open' : ' '}`}
                >
                  {childItems.length ? (
                    <>
                      <button
                        type="button"
                        className="relative z-0 m-0 w-full p-4 text-left font-gothamBold"
                        onClick={(evt) => {
                          evt.target.parentNode.classList.toggle('is-open');
                        }}
                      >
                        {childmenu?.link?.text}
                      </button>
                      <ul role='menu' className={`m-0 -mt-1 list-none p-0 pb-3 ${col.featured_column ? 'featured-column px-2' : ''}`}>
                        {childItems.map((item, itemIndex) => (
                          <li
                            role='none'
                            key={`m-menu-level3-${itemIndex}`}
                            className={`level-3 m-0 ${col.featured_column ? 'flex flex-col text-center w-1/3' : ''}
                            ${(!isUs && item?.link?.text.toLowerCase() === 'bundles') ? 'hidden' : ''}`}
                          >
                            <Link
                              href={item?.link?.url ? item?.link?.url : '/'}
                            >
                              <a role='menuitem'
                                className={`relative block px-4 py-1.5 text-sm no-underline leading-normal${
                                  item?.link_icon ? ' flex items-center' : ''
                                } ${col.featured_column ? 'flex-col px-2 text-xs' : ''}`}
                                style={{ color: item?.link_color }}
                              >
                                {item.link_icon && (
                                  <img
                                    className={`object-cover ${col.featured_column ? 'w-full block mb-2' : 'mr-3 h-6 w-6 '}`}
                                    src={item?.link_icon?.src}
                                    loading="lazy"
                                    alt={item?.link?.text}
                                  />
                                )}
                                {item?.bold_underline === true ? (
                                  <span className="border-b-2 border-black !pb-1 font-gothamBold hover:border-transparent">
                                    {item?.link?.text}
                                    {item?.promo_text ? (
                                      <span className="pl-2 uppercase text-primary-2">
                                        {item?.promo_text}
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                  </span>
                                ) : (
                                  <span
                                    className={`${
                                      col.featured_column
                                        ? 'font-gothamBold'
                                        : ''
                                    }`}
                                  >
                                    {item?.link?.text}
                                    {item?.promo_text ? (
                                      <span className="pl-2 uppercase text-primary-2">
                                        {item?.promo_text}
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                  </span>
                                )}
                              </a>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    <Link
                      href={childmenu?.link?.url ? childmenu?.link?.url : '/'}
                    >
                      <a role='menuitem' className="relative block px-4 py-1.5 text-sm leading-normal no-underline">
                        {childmenu?.link?.text}
                      </a>
                    </Link>
                  )}
                </li>
              ) : null;
            })}
          </ul>
        );
      })}
    </div>
  );
}

NavMobile.displayName = 'NavMobile';
ChildMenu.displayName = 'ChildMenu';

export default NavMobile;
