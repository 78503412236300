import { useMemo, useRef, useState, useEffect } from 'react';

function Section({ cms }) {
  const {
    enableCustomCursor
  } = cms;

  useEffect(() => {
    if (enableCustomCursor) {
      document.body.classList.add('show-custom-cursor');
    } else {
      document.body.classList.remove('show-custom-cursor');
    }
  }, [])

  return (<></>);
}

Section.displayName = 'Custom Cursor';

Section.Schema = {
  category: 'Modules',
  label: 'Custom Cursor',
  key: 'custom-cursor',
  fields: [
    {
      name: 'enableCustomCursor',
      component: 'toggle',
      label: 'Enable Custom Cursor',
      defaultValue: false,
    }
  ],
};

export const CustomCursor = Section;
