import { useState, useMemo } from 'react';
import { getMetafieldMap } from '@/utils/Metafields';

function ProductCustomBadges(props) {
  const { product } = props;

  const metafieldMap = useMemo(() => {
    return getMetafieldMap(product);
  }, [product?.id]);


  return (
    <div className="pdp-custom-badges flex flex-col items-end absolute top-0 right-[2px] md:right-0 mt-2 md:mt-6">
      {metafieldMap.custom_badge_label_1 && (
        <div className="pdp-custom-badge-container-2 z-[1]">
          <span 
            className="pdp-custom-badge-1 rounded-tl-md rounded-bl-md tracking-wide font-gothamBold text-[12px] pt-[8px] pr-[10px] pb-[8px] pl-[12px]"
            style={{ backgroundColor: metafieldMap.custom_badge_label_bg_1, color: metafieldMap.custom_badge_label_color_1}}>
            {metafieldMap.custom_badge_label_1}
          </span>
        </div>
      )}
      {metafieldMap.custom_badge_label_2 && (
        <div className="pdp-custom-badge-container-2 mt-2 md:mt-4 z-[1]">
          <span 
            className="pdp-custom-badge-2 rounded-tl-md rounded-bl-md tracking-wide font-gothamBold text-[12px] pt-[8px] pr-[10px] pb-[8px] pl-[12px]"
            style={{ backgroundColor: metafieldMap.custom_badge_label_bg_2, color: metafieldMap.custom_badge_label_color_2}}>
            {metafieldMap.custom_badge_label_2}
          </span>
        </div>
      )}
    </div>
  );
}

export default ProductCustomBadges;
