function Checkbox({
  label,
  name,
  value,
  disabled = 'false',
  checked,
  className,
  onClick,
  Icon,
}) {
  return (
    <div className={`my-3 flex items-center ${className}`}>
      <input
        type="checkbox"
        key={value}
        value={value}
        name={name}
        id={name}
        className="shrink-0"
        disabled={disabled === 'true'}
        checked={checked}
        readOnly
        onClick={onClick}
      />
      <label
        className="ml-4 flex items-center gap-3 pl-0.5 leading-none"
        htmlFor={name}
      >
        {Icon && <Icon />}
        {label}
      </label>
    </div>
  );
}

export default Checkbox;
