import { useMemo } from 'react';
import { useCurrency } from '@backpackjs/storefront';

import {
  useDataLayerAccount,
  useDataLayerCart,
  useDataLayerCollection,
  useDataLayerCustomer,
  useDataLayerInit,
  useDataLayerProduct,
  useDataLayerSearch,
  useDataLayerSubscribe,
} from './hooks';

// Envs to set:
// * NEXT_PUBLIC_SITE_TITLE // provides backup brand name
// * NEXT_PUBLIC_ENABLE_DL_DEBUGGER // enables console logs for event dispatches
// * NEXT_PUBLIC_SHOPIFY_CUSTOM_APP_ID

const DEBUG = process.env.NEXT_PUBLIC_ENABLE_DL_DEBUGGER === 'true';

export function useDataLayerEventListeners({
  page: pageProp,
  pageTitle,
  template,
}) {
  const currencyCode = useCurrency();

  const page = template === 'page' ? pageProp : undefined;

  const baseEcommerce = useMemo(() => {
    if (!currencyCode) return null;
    return {
      currency_code: currencyCode,
      app_id: process.env.NEXT_PUBLIC_SHOPIFY_CUSTOM_APP_ID,
    };
  }, [currencyCode]);

  const isPageAsProduct = useMemo(() => {
    if (!page) return false;
    const productTypeSections = [''];
    return page.sections?.some(({ _template }) =>
      productTypeSections.includes(_template)
    );
  }, [page?.id]);

  const isPageAsCollection = useMemo(() => {
    if (!page) return false;
    const colTypeSections = [''];
    return page.sections?.some(({ _template }) =>
      colTypeSections.includes(_template)
    );
  }, [page?.id]);

  const { generateUserProperties, userProperties } = useDataLayerInit({
    baseEcommerce,
    DEBUG,
    pageTitle,
    template,
  });

  const { userDataEvent, userDataEventTriggered } = useDataLayerCustomer({
    baseEcommerce,
    DEBUG,
    isPageAsProduct,
    isPageAsCollection,
    userProperties,
  });

  useDataLayerAccount({
    baseEcommerce,
    DEBUG,
    generateUserProperties,
    userDataEvent,
    userDataEventTriggered,
  });

  useDataLayerCart({
    baseEcommerce,
    DEBUG,
    userDataEvent,
    userDataEventTriggered,
    userProperties,
  });

  useDataLayerProduct({
    baseEcommerce,
    DEBUG,
    userDataEvent,
    userProperties,
  });

  useDataLayerCollection({
    baseEcommerce,
    DEBUG,
    isPageAsCollection,
    userDataEvent,
    userDataEventTriggered,
    userProperties,
  });

  useDataLayerSearch({
    baseEcommerce,
    DEBUG,
    userDataEvent,
    userDataEventTriggered,
    userProperties,
  });

  useDataLayerSubscribe({
    baseEcommerce,
    DEBUG,
    userDataEventTriggered,
  });
}
