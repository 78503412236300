function RadioButton({ label, name, value, id, disabled = 'false' }) {
  return (
    <div className="my-3 flex items-center">
      <input
        type="radio"
        value={value}
        name={name}
        id={id}
        disabled={disabled === 'true'}
      />
      <label className="ml-4 pl-0.5 text-black" htmlFor={id}>
        {label}
      </label>
    </div>
  );
}

export default RadioButton;
