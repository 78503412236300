export function BuilderRemove(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M11.5356 4.46449L4.46458 11.5356"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M11.5356 11.5356L4.46458 4.46448"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
}
