import { useRouter } from 'next/router';
import { ArrowLeft } from '@/snippets/icons/ArrowLeft';
import { ArrowRight } from '@/snippets/icons/ArrowRight';

function Pagination({
  currentPage,
  itemsPerPage,
  items,
  setLayoutParams,
  browserHistory,
}) {
  const router = useRouter();
  const maxPages = Math.ceil(items.length / itemsPerPage);

  const changeCurrentPage = (page) => {
    const urlParams = new URLSearchParams(document.location.search);
    if (page === 1) {
      urlParams.delete('page');
    } else {
      urlParams.set('page', page);
    }

    setLayoutParams((layoutParams) => {
      const newParams = {
        ...layoutParams,
        page,
      };
      if (browserHistory) {
        router.push(
          {
            query: {
              ...router.query,
              page: newParams.page,
            },
          },
          undefined,
          { shallow: true }
        );
      }

      return newParams;
    });
  };

  const buildPaginationItems = () => {
    const paginationItems = [];

    let startPage = 1;
    let endPage = maxPages;
    let showPrevSpacer = false;
    let showNextSpacer = false;

    if (currentPage > 3) {
      showPrevSpacer = true;
      startPage = currentPage - 1;
    }

    if (currentPage < maxPages - 2) {
      showNextSpacer = true;
      endPage = parseInt(currentPage, 10) + 1;
    }

    if (showPrevSpacer) {
      paginationItems.push(
        <li key="pagination-1" className="relative mt-0">
          <button
            type="button"
            className="h-8 w-8 before:absolute before:bottom-0 before:left-0 before:right-0 before:mx-auto before:block before:w-0 before:border-b-2 before:border-grey-1 before:transition-all before:duration-150 hover:before:w-3"
            onClick={() => changeCurrentPage(1)}
          >
            <span className="sr-only">Go to page</span>1
          </button>
        </li>
      );
      paginationItems.push(
        <li key="previousSpacer" className="mt-0">
          <span className="leading-loose">...</span>
        </li>
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationItems.push(
        <li key={`pagination-${i}`} className="pagination-item relative mt-0">
          <button
            type="button"
            className={`h-8 w-8 before:absolute before:bottom-0 before:left-0 before:right-0 before:mx-auto before:block before:w-0 before:border-b-2 before:border-grey-1 before:transition-all before:duration-150 hover:before:!w-3 ${
              currentPage === i ? ' before:!w-3' : ''
            }`}
            onClick={() => {
              changeCurrentPage(i);
            }}
          >
            <span aria-hidden>{i}</span>
            <span className="sr-only">Go to page {i}</span>
          </button>
        </li>
      );
    }

    if (showNextSpacer) {
      paginationItems.push(
        <li key="nextSpacer" className="mt-0 h-8 w-8 text-center">
          <span className="leading-loose">...</span>
        </li>
      );
      paginationItems.push(
        <li key={`pagination-${maxPages}`} className="relative mt-0">
          <button
            type="button"
            className="h-8 w-8 before:absolute before:bottom-0 before:left-0 before:right-0 before:mx-auto before:block before:w-0 before:border-b-2 before:border-grey-1 before:transition-all before:duration-150 hover:before:w-3"
            onClick={() => changeCurrentPage(maxPages)}
            disabled={currentPage === maxPages}
          >
            <span className="sr-only">Go to page</span>
            {maxPages}
          </button>
        </li>
      );
    }

    return paginationItems;
  };

  return (
    maxPages > 1 && (
      <ul className="pagination mt-10 mb-20 flex w-full list-none justify-center p-0 font-gothamBold">
        <li key="previous-button" className="pagination-item mt-0 mr-5">
          <button
            type="button"
            onClick={() =>
              currentPage > 1 &&
              changeCurrentPage(parseInt(currentPage, 10) - 1)
            }
            className={`flex h-8 w-8 items-center justify-center border border-grey-1 transition-all duration-150 ${
              currentPage === 1
                ? 'cursor-not-allowed opacity-50'
                : 'hover:bg-grey-1 hover:text-white'
            }`}
          >
            <span className="sr-only">Go to previous page</span>
            <ArrowLeft />
          </button>
        </li>
        {buildPaginationItems()}
        <li key="next-button" className="pagination-item mt-0 ml-5">
          <button
            type="button"
            onClick={() =>
              currentPage < maxPages &&
              changeCurrentPage(parseInt(currentPage, 10) + 1)
            }
            className={`flex h-8 w-8 items-center justify-center border border-grey-1 transition-all duration-150 ${
              currentPage === maxPages
                ? 'cursor-not-allowed opacity-50'
                : 'hover:bg-grey-1 hover:text-white'
            }`}
          >
            <span className="sr-only">Go to next page</span>
            <ArrowRight />
          </button>
        </li>
      </ul>
    )
  );
}

export default Pagination;
