import { useEffect, useState } from 'react';

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState({
    vpWidth: undefined,
    vpHeight: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowDimensions({
        vpWidth: window.innerWidth,
        vpHeight: window.innerHeight,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export const formatMoney = (cents, format) => {
  cents = amountToCents(cents);
  let value = '';
  const placeholderRegex = /\{\{\s*(\w+)\s*\}\}/;
  const formatString = format || '${{amount}}';

  function amountToCents(e) {
    return (
      e || (e = 0),
      typeof e !== 'string' && (e = e.toString()),
      (e =
        e.indexOf('.') !== -1 ? 100 * parseFloat(e).toFixed(2) : parseInt(e)),
      parseInt(e)
    );
  }

  function defaultOption(opt, def) {
    return typeof opt === 'undefined' ? def : opt;
  }

  function formatWithDelimiters(number, precision, thousands, decimal) {
    precision = defaultOption(precision, 2);
    thousands = defaultOption(thousands, ',');
    decimal = defaultOption(decimal, '.');
    if (isNaN(number) || number === null) {
      return 0;
    }

    number = (number / 100).toFixed(precision);

    const parts = number.split('.');
    const dollars = parts[0].replace(
      /(\d)(?=(\d\d\d)+(?!\d))/g,
      `$1${thousands}`
    );
    const cents = parts[1] ? decimal + parts[1] : '';

    return dollars + cents;
  }

  switch (formatString.match(placeholderRegex)[1]) {
    case 'amount':
      value = formatWithDelimiters(cents, 2);
      break;
    case 'amount_no_decimals':
      value = formatWithDelimiters(cents, 0);
      break;
    case 'amount_with_comma_separator':
      value = formatWithDelimiters(cents, 2, '.', ',');
      break;
    case 'amount_no_decimals_with_comma_separator':
      value = formatWithDelimiters(cents, 0, '.', ',');
      break;
  }
  value = value.replace('.00', '');
  return formatString.replace(placeholderRegex, value);
};

export const compact = (array) => {
  let index = -1;
  const length = array === null ? 0 : array.length;
  let resIndex = 0;
  const result = [];

  while (++index < length) {
    const value = array[index];
    if (value) {
      result[resIndex++] = value;
    }
  }
  return result;
};

export const handleize = (string) => {
  let handleizedValue;

  if (string) {
    handleizedValue = string
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/-$/, '')
      .replace(/^-/, '');
  }

  return handleizedValue;
};

export const getOffset = (el) => {
  const rect = el.getBoundingClientRect();
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY,
  };
};

export const newLineToBr = (text) => {
  if (!text) return '';

  return text.replace(/\n/gm, '<br />');
};

export const scrollToSection = (id) => {
  if (typeof window !== 'undefined' && typeof id !== 'undefined') {
    const hash = document.location.hash.replace('#', '');

    if (hash && hash === id) {
      const target = document.getElementById(id);
      const header = document.querySelector('.site-header');
      let headerOffset = 0;

      if (header) {
        headerOffset = header.clientHeight;
      }
      if (target) {
        console.warn(`Section with id ${id} has been rendered/re-rendered`);
        setTimeout(() => {
          window.scrollTo({
            top:
              target.offsetTop -
              window.getComputedStyle(target).marginTop.replace('px', '') -
              headerOffset,
          });
        }, 1000);
      }
    }
  }
};

export const getApplicableOverride = (cart, overrides) => {
  let override = null;
  if (cart?.discountCodes?.length) {
    override = overrides?.filter((overrideData) => {
      return (
        overrideData.discountCode?.toLowerCase() ===
        cart?.discountCodes[0].code.toLowerCase()
      );
    });
  }
  return override;
};
