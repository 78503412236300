export function Lock(props) {
  return (
    <svg
      width="8"
      height="11"
      viewBox="0 0 8 11"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0.5 5H7.5C7.77614 5 8 5.22386 8 5.5V10C8 10.2761 7.77614 10.5 7.5 10.5H0.5C0.223858 10.5 0 10.2761 0 10V5.5C0 5.22386 0.223858 5 0.5 5Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.53994 2.83155C2.28305 3.43427 2.24999 4.23617 2.25 4.99999L0.75 5.00001C0.749988 4.26383 0.766942 3.16571 1.16006 2.2434C1.36405 1.76483 1.68079 1.30328 2.17129 0.965229C2.66461 0.62523 3.27609 0.449951 4.00003 0.449951C4.72396 0.449951 5.33543 0.62523 5.82876 0.965231C6.31925 1.30328 6.63599 1.76483 6.83997 2.24341C7.23308 3.16571 7.25002 4.26384 7.25002 5H5.75002C5.75002 4.23616 5.71697 3.43426 5.46008 2.83155C5.33906 2.5476 5.1808 2.3404 4.97754 2.20031C4.77712 2.06218 4.47609 1.94995 4.00003 1.94995C3.52396 1.94995 3.22293 2.06218 3.0225 2.20031C2.81924 2.3404 2.66097 2.54761 2.53994 2.83155Z"
      />
    </svg>
  );
}
