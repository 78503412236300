import React from 'react';
import { Spinner } from './Spinner';

export function AccountsAuthLoader({ minHeight = 650, width = '100%' }) {
  return (
    <div
      style={{
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0,
        width: width || '100%',
        minWidth: 0,
        minHeight: minHeight || 650,
      }}
    >
      <div
        style={{
          boxSizing: 'border-box',
          margin: 0,
          minWidth: 0,
        }}
      >
        <Spinner />
      </div>
    </div>
  );
}
