import { useState } from "react";

export const useScrollLock = (isDrawer = false) => {
  const scrollLock = () => {
    const { body } = document;
    const currentScroll = window.scrollY;
    body.style.setProperty('--topPosition', `-${currentScroll}px`);
    body.classList.add('overflow-hidden', 'bg-overlay', isDrawer !== false && 'drawer-open');
  };

  const scrollUnlock = () => {
    const { body } = document;
    const scrollTop = body.style.getPropertyValue('--topPosition');
    body.classList.remove('overflow-hidden', 'bg-overlay', isDrawer !== false && 'drawer-open');
    body.style.removeProperty('--topPosition');

    if (scrollTop !== '') {
      window.scrollTo({
        top: parseInt(scrollTop.replace('px', '') || '0', 10) * -1,
      });
    }
    window.lastSpot = parseInt(scrollTop.replace('px', '') || '0', 10) * -1;

  };

  useState(() => {
    if(typeof window !== 'undefined') {
      window.lastSpot = window.scrollY;
    }
  }, [])

  return {
    scrollLock,
    scrollUnlock
  }
}