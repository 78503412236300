import { useEffect, useRef, useState } from 'react';
import { newLineToBr } from '@/utils/Helper';
import { Image } from '@/snippets/components/Image';

function Section({ cms }) {
  const slider = useRef();
  const ingredientsBox = useRef();
  const [ingredientsOpen, setIngredientsOpen] = useState(false);

  const scrollToSlide = (index) => {
    if (!slider.current) {
      return;
    }
    const slides = slider.current.querySelectorAll('[data-slide]');
    // Card center
    const cardCenter =
      slides[index].getBoundingClientRect().left +
      slider.current.scrollLeft +
      slides[index].offsetWidth / 2;
    // Slider Center
    const sliderCenter = slider.current.offsetWidth / 2;

    slider.current.scrollTo({
      left: cardCenter - sliderCenter,
      behavior: 'smooth',
    });
  };

  const buildDots = () => {
    const dots = cms?.ingredients?.map((item, index) => {
      return (
        <li
          key={`ingredient-${cms.id || cms.cmsId || cms.tinaId}-dot-${index}`}
          className="dot m-0 list-none"
        >
          <button
            type="button"
            className="h-3 w-3 cursor-pointer rounded-full border-2 border-grey-1 bg-grey-1"
            onClick={() => scrollToSlide(index)}
            data-dot
          >
            <span className="sr-only">Go to slide ${index}</span>
          </button>
        </li>
      );
    });

    return dots;
  };

  const updateActiveDot = (entries) => {
    if (!slider.current) {
      return;
    }

    const slides = slider.current.querySelectorAll('[data-slide]');
    const dots = slider.current.parentElement.querySelectorAll('[data-dot]');
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const index = [...slides].indexOf(entry.target);
        dots.forEach((dot) => {
          dot.classList.add('bg-grey-1');
        });
        dots[index].classList.remove('bg-grey-1');
      }
    });
  };

  const toggleIngredients = (evt) => {
    setIngredientsOpen((isOpen) => !isOpen);
  };

  const setIngredientsBoxSize = () => {
    if (ingredientsBox.current) {
      ingredientsBox.current.style.height = `${
        ingredientsBox.current.querySelector('p').offsetHeight
      }px`;
    }
  };

  useEffect(() => {
    if (!slider.current) {
      return undefined;
    }

    const observer = new IntersectionObserver(updateActiveDot, {
      root: slider.current,
      rootMargin: '0px',
      threshold: 0.7, // 70% of the card is visible to asume it active
    });

    const slides = slider.current.querySelectorAll('[data-slide]');
    slides.forEach((slide) => {
      observer.observe(slide);
    }, []);
    window.addEventListener('resize', setIngredientsBoxSize);
    return () => {
      window.removeEventListener('resize', setIngredientsBoxSize);
      slides.forEach((slide) => {
        observer.unobserve(slide);
      });
    };
  });

  return (
    <section
      id={`${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId}`}
      key={cms.cmsId}
      className="ingredients"
      data-comp={Section.displayName}
    >
      <style>
        {`#${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
            margin-top: ${cms.mobileSpacing}px;
          }
          @media(min-width: 1024px) {
            #${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
              margin-top: ${cms.desktopSpacing}px;
            }
          }`}
      </style>
      <div className="ingredients-header text-center">
        {cms?.section_optional_title && (
          <div className="mb-2 px-5 font-gothamBold text-base text-grey-1">
            {cms?.section_optional_title}
          </div>
        )}
        {cms?.section_title && (
          <h2 className="text-header mb-3 px-5 lg:mb-4">
            {cms?.section_title}
          </h2>
        )}
        {cms?.section_description && (
          <p
            className="mx-auto px-5 font-primary text-base font-light lg:max-w-[430px] lg:text-lg"
            dangerouslySetInnerHTML={{
              __html: newLineToBr(cms?.section_description),
            }}
          />
        )}
        {cms?.ingredients?.length > 0 && (
          <div className="ingredients-list">
            <ul
              className="mx-auto mt-6 mb-0 flex w-full flex-nowrap gap-x-4 overflow-x-auto px-5 lg:container lg:mt-12 lg:grid lg:grid-cols-2 lg:gap-y-8 lg:gap-x-6 lg:px-5"
              ref={slider}
            >
              {cms?.ingredients?.map((ingredient, index) => {
                return (
                  (ingredient?.name ||
                    ingredient?.image ||
                    ingredient?.description) && (
                    <li
                      className="ingredient m-0 flex shrink-0 basis-[90%] flex-col border border-black lg:w-full lg:flex-row"
                      data-slide
                      key={`ingredient-${
                        cms.id || cms.cmsId || cms.tinaId
                      }-${index}`}
                    >
                      <div className="ingredient-image relative w-full border-b border-black before:block before:aspect-[318/263] before:w-full lg:h-full lg:max-w-[220px] lg:border-b-0 lg:border-r xl:max-w-[320px]">
                        <Image
                          src={ingredient?.image?.src}
                          alt={ingredient?.image_alt}
                          fill
                          loading="lazy"
                          sizes="(max-width: 1023px) 90vw ,25vw"
                        />
                      </div>
                      <div className="ingredient-body h-full w-full">
                        {ingredient?.name && (
                          <div className="ingredient-header border-b border-black p-4 text-left lg:px-5">
                            <h3 className="ingredient-title mb-0 font-gothamBold text-base">
                              {ingredient?.name}
                            </h3>
                          </div>
                        )}

                        {ingredient?.description && (
                          <p
                            className="ingredient-description mb-4 px-4 pt-4 pb-0 text-left lg:px-5"
                            dangerouslySetInnerHTML={{
                              __html: newLineToBr(ingredient?.description),
                            }}
                          />
                        )}

                        {ingredient?.source && (
                          <small className="ingredient-source mb-4 block px-4 text-left font-primary text-xs lg:px-5">
                            Source: {ingredient?.source}
                          </small>
                        )}
                      </div>
                    </li>
                  )
                );
              })}
            </ul>
            <ul className="ingredients-dots-container m-0 mt-4 flex justify-center gap-2.5 p-0 lg:hidden">
              {buildDots()}
            </ul>
          </div>
        )}

        {cms?.ingredients_list && (
          <div className="more-ingredients container mx-auto mt-9 px-5 lg:mt-12">
            <div
              className={`ingredients-content mx-auto w-8/12 overflow-hidden transition-all duration-300 ease-in-out ${
                ingredientsOpen ? '' : '!h-0'
              }`}
              ref={ingredientsBox}
              aria-hidden={!ingredientsOpen}
            >
              <p
                className="mb-0 pb-4"
                dangerouslySetInnerHTML={{
                  __html: newLineToBr(cms.ingredients_list),
                }}
              />
            </div>
            <button
              type="button"
              className="focus-link"
              onClick={toggleIngredients}
            >
              {ingredientsOpen ? 'show less -' : 'view full ingredient list +'}
            </button>
          </div>
        )}
      </div>
    </section>
  );
}

Section.displayName = 'Ingredients';

Section.Schema = {
  category: 'Product',
  label: Section.displayName,
  key: 'ingredients',
  fields: [
    {
      component: 'number',
      name: 'mobileSpacing',
      label: 'Section Top Margin - Mobile',
      defaultValue: 60,
    },
    {
      component: 'number',
      name: 'desktopSpacing',
      label: 'Section Top Margin - Desktop',
      defaultValue: 80,
    },
    {
      name: 'section_optional_title',
      label: 'Section Pre-title',
      component: 'text',
    },
    {
      name: 'section_title',
      label: 'Section Title',
      component: 'text',
    },
    {
      name: 'section_description',
      label: 'Section Description',
      component: 'textarea',
    },
    {
      name: 'ingredients',
      label: 'Ingredients',
      component: 'group-list',
      fields: [
        {
          name: 'image',
          label: 'Image',
          component: 'image',
          description: 'Recomended image size: 638px x 526px',
        },
        {
          name: 'image_alt',
          label: 'Image alt text',
          component: 'text',
        },
        {
          name: 'name',
          label: 'Name',
          component: 'text',
        },
        {
          name: 'description',
          label: 'Description',
          component: 'textarea',
          description:
            'Recommended to keep the description under 180 characters, or have consistency across all ingredients description',
        },
        {
          name: 'source',
          label: 'Information source',
          component: 'text',
        },
      ],
    },
    {
      name: 'ingredients_list',
      label: 'Full ingredients list',
      component: 'textarea',
    },
  ],
};

export const Ingredients = Section;
