import { useCartAddDiscount } from '@backpackjs/storefront';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export default function DiscountCodeFromUrl() {
  const isBrowser = typeof window !== 'undefined';
  const { cartAddDiscount, ...addDiscountStatus } = useCartAddDiscount();
  const urlSearchParams = isBrowser
    ? new URLSearchParams(document.location.search)
    : null;
  const discountCode = urlSearchParams?.get('discount_code');
  const router = useRouter();
  const addDiscount = async (discount) => {
    await cartAddDiscount({
      discountCode: discount,
    });
  };

  useEffect(() => {
    if (discountCode) {
      addDiscount(discountCode.toLowerCase());
    }

    if (isBrowser && addDiscountStatus.finished === true) {
      const searchParams = new URLSearchParams(document.location.search);
      searchParams.delete('discount_code');
      router.replace(
        document.location.origin +
          document.location.pathname +
          (searchParams.toString() !== '' ? `?${searchParams.toString()}` : ''),
        undefined,
        { shallow: true }
      );
    }
  }, [addDiscountStatus.finished]);
}
