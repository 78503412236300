export function Loading() {
  const buildDots = () => {
    const dots = [];
    for(let i = 0; i < 3; i++) {
     dots.push(<div key={`dot-${i}`} className="loading__dot rounded-full w-2.5 h-2.5 bg-grey-3 mx-1 inline-block animate-bounce" style={{
        animationDelay: `${i * 150}ms`,
        animationFillMode: 'both',
        animationDuration: '750ms'
      }}/>)
    }

    return dots;
  }
  return (
    <div className="loading my-8">
      {buildDots()}
    </div>
  )
}