import { useRouter } from 'next/router';
import { useState } from 'react';
import { ArrowUp } from '../icons/ArrowUp';
import { Filters } from '../icons/Filter';
import SortBy from '../icons/SortBy';
import { Close } from '../icons/Close';

function CollectionHeader({
  sort,
  setMobileFilterOpen,
  setLayoutParams,
  currentFilters,
  enableFilters,
  itemsPerPage,
  browserHistory,
  enableLoadMore,
  currentPage,
  itemCount,
  productCount,
}) {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const sortOptions = [
    {
      label: 'Price, Low To High',
      value: 'price:asc',
    },
    {
      label: 'Price, High To Low',
      value: 'price:desc',
    },
    {
      label: 'Alphabetically, A-Z',
      value: 'alpha:asc',
    },
    {
      label: 'Alphabetically, Z-A',
      value: 'alpha:desc',
    },
    {
      label: 'Most Popular',
      value: 'pop:desc',
    },
  ];
  const initialIndex =
    enableLoadMore === true || currentPage === 1
      ? 1
      : (currentPage - 1) * itemsPerPage + 1;

  const finalIndex =
    enableLoadMore === true
      ? productCount
      : (currentPage - 1) * itemsPerPage + productCount;

  const getCurrentSortLabel = () => {
    const option = sortOptions.find((option) => {
      return option.value === sort;
    });

    return option?.label;
  };

  const updateSortOption = (option) => {
    setIsOpen(false);

    setLayoutParams((layoutParams) => {
      const newParams = {
        ...layoutParams,
        sort: option,
      };
      if (browserHistory) {
        const urlParams = new URLSearchParams(document.location.search);
        urlParams.set('sort', option);

        router.push(
          {
            query: {
              ...router.query,
              sort: option,
            },
          },
          undefined,
          { shallow: true }
        );
      }

      return newParams;
    });
  };

  return (
    <div className="relative z-20 mb-4 mt-5 flex items-center justify-between md:border-t-0 md:border-b-0">
      <div className="collection__product-count mr-auto">
        showing {initialIndex} - {finalIndex} of {itemCount} products
      </div>
      <div className="sort-container relative z-10 ml-auto hidden max-w-[350px] overflow-visible lg:flex xl:hidden">
        <span className="mr-4 flex flex-shrink-0 items-center gap-4">
          <SortBy />
          sort by
        </span>
        <button
          type="button"
          className="sort-box flex h-[50px] w-[250px] items-center justify-center px-2 md:border md:border-grey-1 md:px-0"
          onClick={() => {
            setIsOpen((currentIsOpen) => !currentIsOpen);
          }}
        >
          <span className="max-w-full overflow-hidden text-ellipsis whitespace-nowrap text-xs md:w-full md:px-4 md:text-left md:font-gothamBold">
            {getCurrentSortLabel() || 'Sort By'}
          </span>
          <div className="flex h-full w-[50px] shrink-0 items-center justify-center self-end md:border-l">
            <div
              className={`transition-transform duration-150 ${
                !isOpen ? 'rotate-180' : ''
              }`}
            >
              <ArrowUp className="w-3 md:w-4" />
            </div>
          </div>
        </button>
        {isOpen && (
          <ul className="sort-options absolute top-full right-0 m-0 w-full border border-grey-4 bg-white p-0 md:border-grey-1">
            {sortOptions.map((option) => {
              return (
                <li key={option.value} className="my-4 list-none">
                  <button
                    type="button"
                    className={`w-full border-0 bg-transparent px-4 text-left text-xs hover:font-gothamBold ${
                      option.value === sort ? 'font-gothamBold' : ''
                    }`}
                    onClick={() => {
                      updateSortOption(option.value);
                    }}
                  >
                    {option.label}
                  </button>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}

export default CollectionHeader;
