import Link from 'next/link';
import { newLineToBr } from '@/utils/Helper';
import styles from './ProductSpotlight.module.css';

function Section({ cms }) {
  const { title, description } = cms;
  const spotlights =
    cms?.spotlight?.filter((_, i) => {
      return i < 2;
    }) || [];
  const itemCount = spotlights.length;

  return (
    <div
      id={`${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId}`}
      data-comp={Section.displayName}
      className="px-4 py-6 md:px-12"
    >
      <style>
        {`#${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
            margin-top: ${cms.mobileSpacing}px;
          }
          @media(min-width: 1024px) {
            #${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
              margin-top: ${cms.desktopSpacing}px;
            }
          }`}
      </style>
      <div className="mx-auto max-w-[1120px]">
        <div className="w-full max-w-[546px]">
          {title && <h2 className="text-header mt-0 mb-2 md:mb-3">{title}</h2>}
          {description && (
            <p
              className="m-0 text-sm font-light md:text-h6"
              dangerouslySetInnerHTML={{ __html: newLineToBr(description) }}
            />
          )}
        </div>
        {itemCount > 0 && (
          <div className="mt-4 block sm:mt-8 md:-ml-7 md:mt-9 md:flex">
            {spotlights.map((spotlight, index) => {
              return (
                <div
                  key={index}
                  className="mt-12 w-full first:mt-0 md:mt-0 md:w-1/2 md:pl-7"
                >
                  <Spotlight item={spotlight} index={index} />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

function Spotlight({ item }) {
  const {
    image,
    image_m,
    collection_link,
    franchise_color,
    ingredient_name,
    product_name,
    image_alt,
  } = item;
  const cta = item?.cta;
  const features = item?.feature || [];

  return (
    <>
      {image &&
        (collection_link?.url ? (
          <Link href={collection_link?.url}>
            <a className="relative no-underline">
              <div>
                {image_m ? (
                  <img
                    src={image_m?.src}
                    alt={image_alt}
                    loading="lazy"
                    className="block w-full md:hidden"
                  />
                ) : null}
                <img
                  src={image?.src}
                  alt={image_alt}
                  loading="lazy"
                  className="hidden w-full md:block"
                />
              </div>
              <div className="bottom-0 left-0 w-full px-0 py-4 sm:py-6 md:absolute md:px-5 md:py-8 lg+:px-7">
                {collection_link && collection_link?.text && (
                  <p className={`sub-xsmall mb-1 md:mb-2 ${franchise_color}`}>
                    {collection_link?.text}
                  </p>
                )}
                {ingredient_name && (
                  <p className="mt-0 mb-0 font-tiempos text-h5-m italic md:mb-1 md:text-h5">
                    {ingredient_name}
                  </p>
                )}
                {product_name && (
                  <h3 className="m-0 font-tiempos text-h3-m md:text-h4">
                    {product_name}
                  </h3>
                )}
              </div>
            </a>
          </Link>
        ) : (
          <div className="relative">
            <div>
              {image_m ? (
                <img
                  src={image_m?.src}
                  alt={image_alt}
                  loading="lazy"
                  className="block w-full md:hidden"
                />
              ) : null}
              <img
                src={image?.src}
                alt={image_alt}
                loading="lazy"
                className="hidden w-full md:block"
              />
            </div>
            <div className="bottom-0 left-0 w-full px-0 py-4 sm:py-6 md:absolute md:px-5 md:py-8 lg+:px-7">
              {collection_link && collection_link?.text && (
                <p className={`sub-xsmall mb-1 md:mb-2 ${franchise_color}`}>
                  {collection_link?.text}
                </p>
              )}
              {ingredient_name && (
                <p className="mt-0 mb-0 font-tiempos text-h5-m italic md:mb-1 md:text-h5">
                  {ingredient_name}
                </p>
              )}
              {product_name && (
                <h3 className="m-0 font-tiempos text-h3-m md:text-h4">
                  {product_name}
                </h3>
              )}
            </div>
          </div>
        ))}
      {features.length ? (
        <div className="text-large mt-0 border border-grey-3 md:mt-6">
          {features.map((feature, i) => {
            return (
              feature?.title && (
                <div
                  key={`spotlight-feature-${i}`}
                  className="flex min-h-[100px] items-center border-t border-grey-3 px-6 py-5 first:border-t-0"
                >
                  {feature?.image?.src && (
                    <div
                      className={`w-[42px] shrink-0 text-center ${styles.iconContainer}`}
                    >
                      <img
                        src={feature?.image?.src}
                        alt=""
                        className="w-42px h-42px object-contain"
                        loading="lazy"
                      />
                    </div>
                  )}
                  <p className={`m-0 ${feature?.image?.src ? 'pl-6' : ''}`}>
                    {feature?.title && <strong>{feature?.title}</strong>}
                    {feature?.description && (
                      <span
                        className="ml-2"
                        dangerouslySetInnerHTML={{
                          __html: newLineToBr(feature?.description),
                        }}
                      />
                    )}
                  </p>
                </div>
              )
            );
          })}
        </div>
      ) : null}
      {cta && cta?.link?.text && cta?.link?.url ? (
        <div className="mt-6 flex justify-center">
          <Link href={cta?.link?.url}>
            <a
              className={`${
                cta?.style !== 'focus-link'
                  ? `button ${cta?.style}`
                  : cta?.style
              } mb-4 inline-block min-w-[190px] text-center no-underline`}
            >
              {cta?.link?.text}
            </a>
          </Link>
        </div>
      ) : null}
    </>
  );
}

Section.displayName = 'Product Spotlight';
Spotlight.displayName = 'Spotlight';

Section.Schema = {
  category: 'Modules',
  label: Section.displayName,
  key: 'product-spotlight',
  fields: [
    {
      component: 'number',
      name: 'mobileSpacing',
      label: 'Section Top Margin - Mobile',
      defaultValue: 60,
    },
    {
      component: 'number',
      name: 'desktopSpacing',
      label: 'Section Top Margin - Desktop',
      defaultValue: 80,
    },
    {
      name: 'title',
      label: 'Section Title',
      component: 'text',
    },
    {
      name: 'description',
      label: 'Section Description',
      component: 'textarea',
    },
    {
      name: 'spotlight',
      label: 'Spotlight',
      component: 'group-list',
      fields: [
        {
          name: 'image',
          label: 'Image (Desktop)',
          component: 'image',
          description: 'Recomended image width size: 1092px',
        },
        {
          name: 'image_m',
          label: 'Image (Mobile)',
          component: 'image',
          description: 'Recomended image width size: 1344px',
        },
        {
          name: 'image_alt',
          label: 'Image alt text',
          component: 'text',
        },
        {
          name: 'collection_link',
          label: 'Collection (Franchise)',
          description: 'Optional Field',
          component: 'link',
        },
        {
          component: 'select',
          name: 'franchise_color',
          label: 'Collection (Franchise) Color',
          description: 'Select from preselected brand colors.',
          options: [
            { label: 'Pink', value: 'text-primary-2' },
            { label: 'Blue 2', value: 'text-quinary-2' },
            { label: 'Blue 2 Alt', value: 'text-quinary-2-alt' },
            { label: 'Green', value: 'text-quaternary-1' },
            { label: 'Purple', value: 'text-tertiary-1' },
            { label: 'Pink 2', value: 'text-secondary-1' },
            { label: 'Orange', value: 'text-secondary-2' },
            { label: 'Cream', value: 'text-tertiary-2' },
            { label: 'Blue 1', value: 'text-quinary-1' },
            { label: 'Seafoam', value: 'text-quaternary-2' },
            { label: 'Air Dry Cream', value: 'text-stylers-1' },
            { label: 'Sculpting Cream', value: 'text-stylers-2' },
            { label: 'Texture Foam', value: 'text-stylers-3' },
            { label: 'Sleek Stick', value: 'text-stylers-4' }
          ],
          defaultValue: 'text-primary-2',
        },
        {
          name: 'ingredient_name',
          label: 'Ingredient',
          description: 'Optional Field',
          component: 'text',
        },
        {
          name: 'product_name',
          label: 'Product Title',
          description: 'Optional Field',
          component: 'text',
        },
        {
          name: 'feature',
          label: 'Feature',
          component: 'group-list',
          fields: [
            {
              name: 'image',
              label: 'Icon',
              component: 'image',
              description: 'Recomended image size 100px x 100px',
            },
            {
              name: 'title',
              label: 'Title',
              description: 'Optional Field',
              component: 'text',
            },
            {
              name: 'description',
              label: 'Description',
              description: 'Optional Field',
              component: 'textarea',
            },
          ],
        },
        {
          label: 'CTA',
          name: 'cta',
          component: 'group',
          fields: [
            {
              label: 'CTA URL',
              name: 'link',
              component: 'link',
            },
            {
              component: 'select',
              name: 'style',
              label: 'Cta Style',
              options: [
                { label: 'Primary', value: 'button--primary' },
                { label: 'Secondary', value: 'button--secondary' },
                { label: 'checkout', value: 'button--checkout' },
              ],
              defaultValue: 'button--primary',
            },
          ],
        },
      ],
    },
  ],
};

export const ProductSpotlight = Section;
