import { useCustomer, useCountry, useProduct, useCartAddItem, useCartAddAttributes, useCart, useCartCount, useCartClear } from '@backpackjs/storefront';
import { useRouter } from 'next/router';
import { useMemo, useEffect } from 'react';

function Visually() {
  const customer = useCustomer();
  const router = useRouter();
  const pageRoute = router.asPath;
  const cart = useCart();
  let pageType = 'other';
  const { country } = useCountry();
  const product = useProduct();
  const { cartAddAttributes } = useCartAddAttributes();
  const { cartClear } = useCartClear();
  const { cartAddItem } = useCartAddItem();

  if(pageRoute.indexOf('/products/') > -1) {
    pageType = 'product';
  }
  if(pageRoute.indexOf('/collections/') > -1) {
    pageType = 'catalog';
  }
  if(pageRoute == '/') {
    pageType = 'home';
  }

  const transformCart = (cart) => {
    return cart ? {
      item_count: cart.lines.reduce((acc, line) => acc + line.quantity, 0),
      attributes: cart.attributes || [],
      currency: cart.estimatedCost.totalAmount.currencyCode,
      total_price: cart.estimatedCost.totalAmount.amount * 100,
      original_total_price: cart.estimatedCost.totalAmount.amount * 100,
      token: cart.id.replace('gid://shopify/Cart/', ''),
      items: cart.lines.map((line) => {
        return {
          handle: line.variant.product.handle,
          price: line.variant.priceV2.amount * 100,
          compare_at_price: line.variant.comparePriceV2?.amount ? line.variant.comparePriceV2.amount * 100 : null,
          product_id: parseInt(line.variant.product.id.replace('gid://shopify/Product/', ''), 10),
          quantity: line.quantity,
          variant_id: parseInt(line.variant.id.replace('gid://shopify/ProductVariant/', ''), 10),
          selling_plan_allocation: line.variant.sellingPlanAllocation ? {
            selling_plan: {
              name: line.variant.sellingPlanAllocation.name,
            }
          } : undefined,
        };
      })
    } : undefined;
  }

  const transformProduct = (product) => {
    return product ? {
      variants: product?.variants.map((variant) => {
        return {
          id: parseInt(variant.legacyResourceId, 10),
          iq: variant.quantityAvailable,
          price: variant.priceV2.amount * 100
        };
      }),
      id: parseInt(product.legacyResourceId, 10),
      oos: !product.variants.some((variant) => variant.availableForSale === true),
    } : undefined;
  };

  const visuallyInstrument = useMemo(() => {
    const visuallyObject = {
      addToCart: (variantId, quantity) => {
        cartAddItem({
          merchandiseId: `gid://shopify/ProductVariant/${variantId}`,
          quantity,
        });
      },
      openCartDrawer: () => {
        window.dispatchEvent(new CustomEvent('openSideCart'));
      },
      closeCartDrawer: () => {
        window.dispatchEvent(new CustomEvent('closeSideCart'));
      },
      cartAddAttributes: ({attributes}) => {
        cartAddAttributes({ attributes });
      },
      cartClear: () => {
        cartClear()
      },
      pageType,
      initialLocale: country?.isoCode,
      initialCurrency: country?.currency.isoCode,
      initialCart: transformCart(cart),
    }

    if(!!product) {
      visuallyObject.currentProduct = transformProduct(product);
      visuallyObject.initialProductId = parseInt(product?.legacyResourceId, 10);
      visuallyObject.initialVariantId = parseInt(product?.variants[0]?.legacyResourceId, 10);
      visuallyObject.initialVariantPrice = parseInt(product?.variants[0]?.priceV2.amount * 100, 10);
    }

    if(!!customer) {
      visuallyObject.initialUserId = customer?.id;
      visuallyObject.customerTags = customer?.tags;
    }

    return visuallyObject;
  }, []);


  useEffect(() => {
    if(window.visually) {
      window.visually.visuallyConnect(visuallyInstrument);
    }
  }, [])

  useEffect(() => {
    if(window.visually) {
      window.visually.onUserIdChange(customer?.id);
    }
  }, [customer])

  useEffect(() => {
    if(window.visually && window.visually.pageTypeChanged) {
      window.visually.pageTypeChanged(pageType);
    }
  },[pageType])

  useEffect(() => {
    if(window.visually && country) {
      window.visually.localeChanged(country.isoCode);
      if(window.visually.currencyChanged) {
        window.visually.currencyChanged(country.currency.isoCode);
      }
    }
  }, [country])

  useEffect(() => {
    if(window.visually) {
      window.visually.productChanged(transformProduct(product));
    }
  }, [product?.id])

  useEffect(() => {
    if(window.visually) {
      window.visually.onCartChanged(transformCart(cart));
    }
  }, [cart])
}

Visually.displayName = 'Visually';

export default Visually;
