import { handleize } from '@/utils/Helper';

export const getMetafieldMap = (product) => {
  if (!product?.metafields?.length) return {};
  const ogMetafields = product.metafields;
  function getMetafield({namespace, key}) {
    return ogMetafields.find(metafield => metafield.namespace === namespace && metafield.key === key);
  }
  function getMetafieldsByNamespace(namespace) {
    const metafieldsList = ogMetafields.filter(metafield => metafield.namespace === namespace);
    return metafieldsList.reduce((acc, { key, value }) => {
      return { ...acc, [key]: value };
    }, {})
  }

  return {...product.metafields.reduce((acc, { key, value }) => {
      return { ...acc, [key]: value };
    }, {}),
    getMetafield,
    getMetafieldsByNamespace,
    ogMetafields,
  }
};

export const getMetafieldColor = (metafieldMap) => {
  let metafieldColor = metafieldMap.custom_collection_title_color;
  if (metafieldColor) {
    let colorHandle = handleize(metafieldColor);
    if (colorHandle == 'air-dry-cream') {
      colorHandle = 'stylers-1';
    } else if (colorHandle == 'sculpting-cream') {
      colorHandle = 'stylers-2';
    } else if (colorHandle == 'texture-foam') {
      colorHandle = 'stylers-3';
    } else if (colorHandle == 'sleek-stick') {
      colorHandle = 'stylers-4';
    }

    metafieldColor = `text-${colorHandle}`;
  } else {
    metafieldColor = 'text-black';
  }

  return metafieldColor;
};