import { useSettings } from '@backpackjs/storefront';
import { useEffect, useState } from 'react';
import SidecartUpsellItem from './SidecartUpsellItem';

export default function MetafieldUpsell({ cartItems }) {
  const [upsellProducts, setUpsellProducts] = useState([]);
  const siteSettings = useSettings();

  const getProductsData = async (productGids) => {
    const productsData = await fetch(
      `https://${process.env.SHOPIFY_DOMAIN}/api/2023-01/graphql.json`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/graphql',
          'X-Shopify-Storefront-Access-Token':
            process.env.SHOPIFY_STOREFRONT_API_TOKEN,
        },
        body: `query getProductsByGid {
        nodes (ids : [${productGids
          .map((element) => `"${element}"`)
          .join(',')}] ) {
          ...on Product {
            title
            handle
            id
            featuredImage {
              width
              height
              url
            }
            variants (first: 10) {
              nodes {
                id
                product {
                  handle
                }
                compareAtPriceV2 {
                  amount
                  currencyCode
                }
                priceV2 {
                  amount
                  currencyCode
                }
              }
            }
            metafield (namespace: "c_f", key: "custom_product_title") {
              key
              namespace
              value
            }
          }
        }
      }`,
      }
    )
      .then((response) => response.json())
      .catch((error) => console.error(error));
    return productsData.data.nodes;
  };

  const getUpsellProducts = async (newItems) => {
    let attributeList = newItems.map((item) => {
      const upsellAttribute = item.attributes.find((attribute) => {
        return attribute.key === '_upsellProduct';
      });

      return (item = upsellAttribute.value);
    });

    attributeList = attributeList.filter((el) => !!el);

    const products = await getProductsData(attributeList);

    const filteredProducts = products.filter((item) => {
      if (item) {
        const existsInCart = cartItems.some((cartItem) => {
          return cartItem.variant.product.handle === item.handle;
        });

        if (!existsInCart) {
          return true;
        }
      }
      return false;
    });

    setUpsellProducts(filteredProducts);
  };

  const buildProductItems = async () => {
    const newItems = cartItems.filter((lineItem) => {
      const LineItemProperty = lineItem.attributes.find((property) => {
        return property.key === '_upsellProduct';
      });
      return LineItemProperty;
    });

    const product = await getUpsellProducts(newItems);
  };

  useEffect(() => {
    buildProductItems();
  }, [cartItems, siteSettings]);
  return (
    upsellProducts.length > 0 && (
      <div className="metafield-upsell py-5 px-4">
        <div className="metafield-upsell__header font-gothamBold text-xs">
          {siteSettings?.sideCart?.upsellProductsLabel || 'You May Also Like'}
        </div>

        {upsellProducts.map((product, index) => {
          return <SidecartUpsellItem product={product} key={index} />;
        })}
      </div>
    )
  );
}
