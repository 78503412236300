import { createContext, useContext } from "react";

export const QuizContext = createContext({
  quizSettings: {},
  quizData: {},
  updateQuizData: () => {},
  parseQuizResultUrl: () => {},
  getResultsProducts: () => {},
  buildSummaryResults: () => {},
});

export function useQuizContext() {
  return useContext(QuizContext);
}