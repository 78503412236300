import { useCallback, useEffect, useState } from 'react';
import { store, useCustomer } from '@backpackjs/storefront';
import { nanoid } from 'nanoid';

export function useDataLayerAccount({
  baseEcommerce,
  DEBUG,
  generateUserProperties,
  userDataEvent,
  userDataEventTriggered,
}) {
  const customer = useCustomer();

  const accessToken = store.recoil.useRecoilValue(store.state.accessToken);
  const [loggedIn, setLoggedIn] = useState(false);
  const [registered, setRegistered] = useState(false);

  const loggedInEvent = useCallback(({ ecommerce, userProperties }) => {
    const event = {
      event: 'dl_login',
      event_id: nanoid(),
      event_time: new Date().toISOString(),
      user_properties: userProperties,
      ecommerce,
    };

    window.dispatchEvent(
      new CustomEvent(event.event, {
        detail: event,
      })
    );
    if (DEBUG) console.log(`DataLayer:dispatch:${event.event}`, event);
    setLoggedIn(false);
  }, []);

  const registeredEvent = useCallback(({ ecommerce, userProperties }) => {
    const event = {
      event: 'dl_sign_up',
      event_id: nanoid(),
      event_time: new Date().toISOString(),
      user_properties: userProperties,
      ecommerce,
    };

    window.dispatchEvent(
      new CustomEvent(event.event, {
        detail: event,
      })
    );
    if (DEBUG) console.log(`DataLayer:dispatch:${event.event}`, event);
    setRegistered(false);
  }, []);

  const getUserPropertiesAndTriggerLoggedInEvents = useCallback(
    async ({ customer: _customer, ecommerce, accessToken: _accessToken }) => {
      const userProperties = await generateUserProperties({
        customer: _customer,
        accessToken: _accessToken.token,
      });
      loggedInEvent({ ecommerce, userProperties });
      setTimeout(() => {
        userDataEvent({ ecommerce, userProperties });
      }, 1000);
    },
    []
  );

  const getUserPropertiesAndTriggerRegisterEvents = useCallback(
    async ({ customer: _customer, ecommerce, accessToken: _accessToken }) => {
      const userProperties = await generateUserProperties({
        customer: _customer,
        accessToken: _accessToken.token,
      });
      registeredEvent({ ecommerce, userProperties });
      setTimeout(() => {
        userDataEvent({ ecommerce, userProperties });
      }, 1000);
    },
    []
  );

  useEffect(() => {
    const customerLoggedIn = PubSub.subscribe(
      'CUSTOMER_LOGGED_IN',
      async () => {
        setLoggedIn(true);
      }
    );
    const customerRegistered = PubSub.subscribe(
      'CUSTOMER_REGISTERED',
      async () => {
        setRegistered(true);
      }
    );
    return () => {
      if (customerLoggedIn) {
        PubSub.unsubscribe(customerLoggedIn);
      }
      if (customerRegistered) {
        PubSub.unsubscribe(customerRegistered);
      }
    };
  }, []);

  // Generate new base data after customer login and customer profile is ready
  // Trigger 'dl_user_data' and 'login' events
  useEffect(() => {
    if (
      !baseEcommerce ||
      !customer ||
      !accessToken?.token ||
      !loggedIn ||
      !userDataEventTriggered
    )
      return;
    getUserPropertiesAndTriggerLoggedInEvents({
      accessToken,
      customer,
      ecommerce: baseEcommerce,
    });
  }, [
    accessToken?.token,
    !!baseEcommerce,
    !!customer,
    loggedIn,
    userDataEventTriggered,
  ]);

  // Generate new base data after customer register and customer profile is ready
  // Trigger 'dl_user_data' and 'dl_sign_up' events
  useEffect(() => {
    if (
      !baseEcommerce ||
      !customer ||
      !accessToken?.token ||
      !registered ||
      !userDataEventTriggered
    )
      return;
    getUserPropertiesAndTriggerRegisterEvents({
      accessToken,
      customer,
      ecommerce: baseEcommerce,
    });
  }, [
    accessToken?.token,
    !!baseEcommerce,
    !!customer,
    registered,
    userDataEventTriggered,
  ]);
}
