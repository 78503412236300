import { Image } from "@/snippets/components/Image";
function Section({ cms }) {
  return (
    <div
      data-comp={Section.displayName}
      id={`${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId}`}
    >
      <style>
        {`#${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
            margin-top: ${cms.mobileSpacing}px;
          }
          @media(min-width: 1024px) {
            #${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
              margin-top: ${cms.desktopSpacing}px;
            }
          }`}
      </style>
      {cms?.background_d?.src ? (
        <div
          className={`relative w-full ${
            cms?.background_m?.src ? 'hidden lg:block' : ''
          }`}
          style={{
            aspectRatio: cms?.aspect_ratio_d,
          }}
        >
          <Image
            src={cms?.background_d?.src}
            alt={cms?.background_image_alt || ''}
            fill
            loading="lazy"
            sizes="100vw"
          />
        </div>
      ) : (
        ''
      )}
      {cms?.background_m?.src ? (
        <div
          className="relative w-full lg:hidden"
          style={{
            aspectRatio: cms?.aspect_ratio_m,
          }}
        >
          <Image
            src={cms?.background_m?.src}
            alt={cms?.background_image_alt || ''}
            fill
            loading="lazy"
            sizes="100vw"
          />
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

Section.displayName = 'Banner';

Section.Schema = {
  category: 'Modules',
  label: Section.displayName,
  key: 'banner',
  fields: [
    {
      component: 'number',
      name: 'mobileSpacing',
      label: 'Section Top Margin - Mobile',
      defaultValue: 60,
    },
    {
      component: 'number',
      name: 'desktopSpacing',
      label: 'Section Top Margin - Desktop',
      defaultValue: 80,
    },
    {
      name: 'background_d',
      label: 'Background Image (Desktop)',
      component: 'image',
      description: 'Recommended Image Size: 3000px wide',
    },
    {
      name: 'aspect_ratio_d',
      label: 'Desktop Background Aspect Ratio',
      description: 'Width divided by height, e.g. 16:9 image is "1.78"',
      component: 'number',
    },
    {
      name: 'background_m',
      label: 'Background Image (Mobile)',
      component: 'image',
      description: 'Recommended Image Size: 2000px wide',
    },
    {
      name: 'aspect_ratio_m',
      label: 'Mobile Background Aspect Ratio',
      description: 'Width divided by height, e.g. 16:9 image is "1.78"',
      component: 'number',
    },
    {
      name: 'background_image_alt',
      label: 'Background Image Alt text',
      component: 'text',
    },
  ],
};

export const Banner = Section;
