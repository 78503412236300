function SelectElement({ options, name, label }) {
  return (
    <div className="select-wrap relative flex flex-col">
      <label className="sub-small mb-2 font-gothamBold" htmlFor={name}>
        {label}
      </label>
      <select
        className="w-full cursor-pointer border border-grey-5 py-3 px-4 text-black placeholder-black transition-all placeholder-shown:border-grey-5 hover:border-black hover:placeholder-black focus:placeholder-black focus:outline-ada-outline"
        name={name}
        id={name}
      >
        {options.map((option, optionIndex) => (
          <option key={`option-${name}-${optionIndex}`} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default SelectElement;
