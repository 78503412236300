import { useEffect, useMemo, useState, useRef } from 'react';
import { useRouter } from 'next/router';
import { useScrollDirection } from '@hooks';
import Checkbox from '../inputs/Checkbox';
import { ArrowUp } from '@/snippets/icons/ArrowUp';
import { Filters } from '@/snippets/icons/Filter';
import SortBy from '@/snippets/icons/SortBy';
import { Close } from '@/snippets/icons/Close';
import { useWindowDimensions } from '@/utils/Helper';

function FilterBar({
  sort,
  items,
  mobileFilterOpen,
  setMobileFilterOpen,
  currentFilters,
  setLayoutParams,
  browserHistory,
  enableFilters,
  disableSort
}) {
  const filterPrefix = [
    'Discounts:',
    'Prime Day Deals:',
    'Collection:',
    'Product Type:',
    'Hair Concern:',
    'Hair Type:',
  ];

  const router = useRouter();
  const [sortOpen, setSortOpen] = useState(false);
  const [desktopOpenFilter, setDesktopOpenfilter] = useState(null);
  const { vpWidth } = useWindowDimensions();
  const scrollDirection = useScrollDirection();
  const filtersContainer = useRef();
  const stickySentinel = useRef();
  const isBrowser = typeof window !== 'undefined';
  const header = isBrowser ? document.querySelector('.site-header') : null;
  const [enableShadow, setEnableShadow] = useState(false);

  const sortOptions = [
    {
      label: 'Price, Low To High',
      value: 'price:asc',
    },
    {
      label: 'Price, High To Low',
      value: 'price:desc',
    },
    {
      label: 'Alphabetically, A-Z',
      value: 'alpha:asc',
    },
    {
      label: 'Alphabetically, Z-A',
      value: 'alpha:desc',
    },
    {
      label: 'Most Popular',
      value: 'pop:desc',
    },
  ];

  const collectionIndex = [
    {
      ref: "don't despair, repair!",
      label: "Don't Despair, Repair!",
      color: 'text-pink',
      order: 1,
    },
    {
      ref: "don't despair repair!",
      label: "Don't Despair Repair!",
      color: 'text-pink',
      order: 1,
    },
    {
      ref: 'scalp revival',
      label: 'Scalp Revival',
      color: 'text-blue-2-alt',
      order: 2,
    },
    {
      ref: 'curl charisma',
      label: 'Curl Charisma',
      color: 'text-purple',
      order: 3,
    },
    {
      ref: 'destined for density',
      label: 'Destined for Density',
      color: 'text-seafoam',
      order: 4,
    },
    {
      ref: 'briogeo superfoods',
      label: 'Briogeo Superfoods',
      color: 'text-green',
      order: 5,
    },
    {
      ref: 'farewell frizz',
      label: 'Farewell Frizz',
      color: 'text-pink-2',
      order: 6,
    },
    {
      ref: 'be gentle, be kind',
      label: 'Be Gentle, Be Kind',
      color: 'text-brown',
      order: 7,
    },
    {
      ref: 'be gentle be kind',
      label: 'Be Gentle Be Kind',
      color: 'text-brown',
      order: 7,
    },
    {
      ref: 'color me brilliant',
      label: 'Color Me Brilliant',
      color: 'text-blue-1',
      order: 8,
    },
    {
      ref: 'blossom & bloom',
      label: 'Blossom & Bloom',
      color: 'text-orange',
      order: 9,
    },
  ];

  const productTypeIndex = [
    {ref: 'shampoos', label: 'Shampoos', order: 1 },
    {ref: 'conditioners', label: 'Conditioners', order: 2 },
    {ref: 'treatment masks', label: 'Treatments + masks', order: 3 },
    {ref: 'oils', label: 'Oils + serums', order: 4 },
    {ref: 'styling & finishing', label: 'Styling', order: 5 },
    {ref: 'supplements', label: 'Supplements', order: 6 },
    {ref: 'value sets (save 15%+)', label: 'Bundles', order: 7 },
    {ref: 'jumbos (save 40%)', label: 'Jumbo sizes', order: 8 },
    {ref: 'trial kits (under $35)', label: 'Travel', order: 9 },
    {ref: 'brushes & tools', label: 'Tools + accessories', order: 10 },
  ];

  const concernIndex = [
    {ref: 'dry damaged', label: 'Repair', order: 1},
    {ref: 'dry flaky scalp', label: 'Scalp', order: 2},
    {ref: 'frizz', label: 'Frizz', order: 3},
    {ref: 'thinning', label: 'Thicken', order: 4},
    {ref: 'frizzy curls/coils', label: 'Curls', order: 5},
    {ref: 'color protect', label: 'Color treated', order: 6},
    {ref: 'volumize', label: 'Volumize', order: 7},
    {ref: 'sensitive skin', label: 'Hypoallergenic', order: 8},
    {ref: 'dull', label: 'Moisturize', order: 9},
  ];

  const typeIndex = [
    { ref: 'straight', label: 'Straight (1A - 1C)', order: 1 },
    { ref: 'wavy', label: 'Wavy (2A - 2C)', order: 2 },
    { ref: 'curly', label: 'Curly (3A - 3C)', order: 3 },
    { ref: 'coily', label: 'Coily (4A - 4C)', order: 4 },
  ];

  const dealsOrder = [
    { ref: '50% off', label: '50% off', order: 1 },
    { ref: '40% off', label: '40% off', order: 2 },
    { ref: '35% off', label: '35% off', order: 3 },
    { ref: '30% off', label: '30% off', order: 4 },
    { ref: '25% off', label: '25% off', order: 5 },
    { ref: '20% off', label: '20% off', order: 6 },
  ];

  const discountsOrder = [
    { ref: '50% off', label: '50% off', order: 1 },
    { ref: '40% off', label: '40% off', order: 2 },
    { ref: '35% off', label: '35% off', order: 3 },
    { ref: '30% off', label: '30% off', order: 4 },
  ];

  const setFilterList = () => {
    if (!Array.isArray(items)) return [];

    const tagList = [];

    items?.forEach((product) => {
      product?.tags?.forEach((tag) => {
        const tagFound = tagList.find((item) => {
          return item === tag;
        });
        if (!tagFound) {
          tagList.push(tag);
        }
      });
    });

    const filterList = tagList.filter((item) => {
      return filterPrefix.some((value) => {
        return item.includes(value);
      });
    });

    return filterList;
  };

  const filterList = useMemo(setFilterList);

  const getItemsByFilter = (filter) => {
    let currentFilterList = filterList.filter((item) => item.includes(filter));

    if (filter === 'Collection:') {
      currentFilterList = currentFilterList.map((item) => {
        const indexedItem = collectionIndex.find((mapItem) => {
          return mapItem.ref === item.replace(filter, '').toLowerCase();
        });
        if (indexedItem) {
          indexedItem.originalText = item;
        }
        return indexedItem;
      });
    }
    if (filter === 'Hair Concern:') {
      currentFilterList = currentFilterList.map((item) => {
        const indexedItem = concernIndex.find((mapItem) => {
          return mapItem.ref === item.replace(filter, '').toLowerCase();
        });
        if (indexedItem) {
          indexedItem.originalText = item;
        }
        return indexedItem;
      });
    }
    if (filter === 'Hair Type:') {
      currentFilterList = currentFilterList.map((item) => {
        const indexedItem = typeIndex.find((mapItem) => {
          return mapItem.ref === item.replace(filter, '').toLowerCase();
        });
        if (indexedItem) {
          indexedItem.originalText = item;
        }
        return indexedItem;
      });
    }
    if (filter === 'Product Type:') {
      currentFilterList = currentFilterList.map((item) => {
        const indexedItem = productTypeIndex.find((mapItem) => {
          return mapItem.ref === item.replace(filter, '').toLowerCase();
        });
        if (indexedItem) {
          indexedItem.originalText = item;
        }
        return indexedItem;
      });
    }
    if (filter === 'Prime Day Deals:') {
      currentFilterList = currentFilterList.map((item) => {
        const indexedItem = dealsOrder.find((mapItem) => {
          return mapItem.ref === item.replace(filter, '').toLowerCase();
        });
        if (indexedItem) {
          indexedItem.originalText = item;
        }
        return indexedItem;
      });
    }
    if (filter === 'Discounts:') {
      currentFilterList = currentFilterList.map((item) => {
        const indexedItem = dealsOrder.find((mapItem) => {
          return mapItem.ref === item.replace(filter, '').toLowerCase();
        });
        if (indexedItem) {
          indexedItem.originalText = item;
        }
        return indexedItem;
      });
    }

    return currentFilterList.filter((item) => !!item);
  };

  const getItemData = (filter) => {
    const group = filter.split(':')[0];
    const item = filter.split(':')[1];
    let currentFilter = {};

    if (group === 'Collection') {
      currentFilter = collectionIndex.find((mapItem) => {
        return mapItem.ref.indexOf(item.toLowerCase()) > -1;
      });
    }
    if (group === 'Hair Concern') {
      currentFilter = concernIndex.find((mapItem) => {
        return mapItem.ref === item.toLowerCase();
      });
    }
    if (group === 'Hair Type') {
      currentFilter = typeIndex.find((mapItem) => {
        return mapItem.ref === item.toLowerCase();
      });
    }
    if (group === 'Product Type') {
      currentFilter = productTypeIndex.find((mapItem) => {
        return mapItem.ref === item.toLowerCase();
      });
    }
    if (group === 'Prime Day Deals') {
      currentFilter = dealsOrder.find((mapItem) => {
        return mapItem.ref === item.toLowerCase();
      });
    }
    if (group === 'Discounts') {
      currentFilter = discountsOrder.find((mapItem) => {
        return mapItem.ref === item.toLowerCase();
      });
    }

    return currentFilter;
  }

  const toggleFilter = (evt) => {
    const filter = evt.target.value;
    const filterIndex = currentFilters.indexOf(filter);
    const addFilter = evt.target.checked;
    const newFilters = currentFilters;
    const urlParams = new URLSearchParams(document.location.search);

    if (addFilter) {
      newFilters.push(filter);
    } else {
      newFilters.splice(filterIndex, 1);
    }

    if (newFilters.length > 0) {
      urlParams.set('filter', newFilters.join('|'));
    } else {
      urlParams.delete('filter');
    }
    urlParams.delete('page');

    setLayoutParams((layoutParams) => {
      if (browserHistory) {
        const customState = {
          ...window.history.state,
          url: router.pathname + '?' + urlParams.toString(),
          as: router.pathname.replace('[handle]', router.query.handle) + '?' + urlParams.toString(),
        }
        window.history.replaceState(customState, '', customState.as);
      }

      const newParams = {
        ...layoutParams,
        filters: newFilters,
        page: 1,
      };

      return newParams;
    });
  };

  const clearFilters = () => {
    setLayoutParams((layoutParams) => {
      router.push(document.location.pathname, undefined, { shallow: true });
      return {
        ...layoutParams,
        handle: router.query.handle,
        filters: [],
        page: 1,
      };
    });
  };

  const toggleGroup = (evt) => {
    evt.currentTarget.parentNode.classList.toggle('is-closed');
  };

  const buildFilterItemList = (filter) => {
    const currentItems = getItemsByFilter(filter).sort((a, b) => {
      return a.order - b.order;
    });

    const filterItems = currentItems.map((filterItem) => {
      return (
        <li key={`${filterItem.originalText}`} className="my-2">
          <Checkbox
            name={`${filterItem.originalText}`}
            value={`${filterItem.originalText}`}
            Icon={filterItem?.icon}
            label={`${filterItem?.label}`}
            className={`${filterItem?.color}`}
            onClick={toggleFilter}
            checked={currentFilters?.includes(filterItem.originalText)}
          />
        </li>
      );
    });
    return filterItems;
  };

  const getCurrentFilters = (filter) => {
    const activeFilters = currentFilters.filter((currentFilter) => {
      return currentFilter.includes(filter);
    });

    return activeFilters?.length || 0;
  };

  const getCurrentSortLabel = () => {
    const option = sortOptions.find((option) => {
      return option.value === sort;
    });

    return option?.label;
  };

  const buildDesktopFilterGroups = () => {
    const filterGroups = filterPrefix.map((filter) => {
      const title = filter.replace(':', '').replace('Hair ', '');
      const currentActiveFilters = getCurrentFilters(filter);
      const filterItemList = buildFilterItemList(filter);

      if (filterItemList.length > 0) {
        return (
          <div
            key={title}
            className="filter-group group relative border-grey-4"
          >
            <button
              className="filter-button relative flex h-[50px] min-w-[200px] items-center border border-black pl-4 pr-16 text-left font-gothamBold text-xs"
              type="button"
              onClick={() => {
                setDesktopOpenfilter((actualFilter) => {
                  if (document.location.href.indexOf('/collections/') > -1) {
                    window.dataLayer.push({
                      event: 'Click - Desktop PLP Filter',
                    });
                  }

                  return actualFilter === filter ? null : filter;
                });
              }}
            >
              {title}
              {currentActiveFilters > 0 && (
                <span className="ml-1">({currentActiveFilters})</span>
              )}
              <span className="align-center t-1/2 absolute right-0 ml-auto flex justify-center border-l border-l-black px-4 py-5">
                <ArrowUp
                  className={`rotate-180
                    ${desktopOpenFilter === filter ? 'rotate-0' : ''}
                  `}
                />
              </span>
            </button>
            {desktopOpenFilter === filter && (
              <ul
                className="filter-list absolute left-0 top-full z-50 mb-0 mt-5 min-w-[250px] list-none border border-black bg-white px-5 py-3 text-sm
                before:absolute before:top-[-11px] before:block before:h-5 before:w-5 before:-rotate-45 before:border-t before:border-r before:border-black before:bg-white"
              >
                {filterItemList}
              </ul>
            )}
          </div>
        );
      }
    });

    return filterGroups;
  };

  const buildMobilefilterGroups = () => {
    let printed = 0;
    const filterGroups = filterPrefix.map((filter) => {
      const title = filter.replace(':', '').replace('Hair ', '');
      const currentActiveFilters = getCurrentFilters(filter);
      const filterItemList = buildFilterItemList(filter);

      if (filterItemList.length > 0) {
        printed++;
        return (
          <div
            className={`filter-group group border-grey-4 px-5 ${
              printed === 1 ? 'border-t-0 md:border-t' : 'border-t'
            }`}
            key={title}
          >
            <button
              type="button"
              className="filter-header flex w-full items-center py-3"
              onClick={toggleGroup}
            >
              <h6 className="mb-0 text-left">
                {title}
                {currentActiveFilters > 0 && (
                  <span className="ml-1">({currentActiveFilters})</span>
                )}
              </h6>
              <span
                className="ml-auto rotate-180 transition-transform duration-150 group-[.is-closed]:rotate-0"
                aria-hidden
              >
                <ArrowUp />
              </span>
            </button>
            <ul className="m-0 mb-8 list-none overflow-hidden p-0 group-[.is-closed]:mb-0 group-[.is-closed]:max-h-0">
              {filterItemList}
            </ul>
          </div>
        );
      }
    });

    return filterGroups;
  };

  const buildActivefilters = () => {
    const currentFilterItems = currentFilters.map((filter) => {
      const item = getItemData(filter);

      return (
        <button
          key={filter}
          type="button"
          className="filter__active-item flex items-center gap-2 rounded-full border-2 py-1 px-5 font-gothamBold text-xs"
          onClick={() => {
            toggleFilter({ target: { value: filter, checked: false } });
          }}
        >
          {item.label}
          <span className="ml-1">
            <Close className="w-2.5" />
          </span>
        </button>
      );
    })

    return currentFilterItems
  }

  const toggleMobileFilter = () => {
    setMobileFilterOpen((filterOpen) => {
      return !filterOpen;
    });
  };

  const updateSortOption = (option) => {
    setSortOpen(false);

    setLayoutParams((layoutParams) => {
      const newParams = {
        ...layoutParams,
        sort: option,
      };
      if (browserHistory) {
        const urlParams = new URLSearchParams(document.location.search);
        urlParams.set('sort', option);

        router.push(
          {
            query: {
              ...router.query,
              sort: option,
            },
          },
          undefined,
          { shallow: true }
        );
      }

      return newParams;
    });
  };

  const escapeListener = (evt) => {
    if (evt.key === 'Escape') {
      setMobileFilterOpen(false);
      setDesktopOpenfilter(null);
    }
  }

  const clickListener = (evt) => {
    if (evt.target.closest('.filter-button')) return null;

    if (!evt.target.closest('.filter-list') && desktopOpenFilter) {
      setDesktopOpenfilter(null);
    }
  }

  const observerCallback = (evt) => {
    evt.forEach((entry) => {
      if (entry.intersectionRatio < 1) {
        setEnableShadow(true);
      } else {
        setEnableShadow(false);
      }
    })
  }

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, {
      threshold: [1, 0],
    });

    observer.observe(stickySentinel.current);
    return () => {
      observer.disconnect(stickySentinel.current);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('keyup', escapeListener);
    document.addEventListener('click', clickListener);

    return () => {
      document.removeEventListener('keyup', escapeListener);
      document.removeEventListener('click', clickListener);
    };
  }, [desktopOpenFilter]);

  useEffect(() => {
    let action = 'remove';
    if (mobileFilterOpen) {
      action = 'add';
    }
    if (isBrowser) {
      document.querySelector('body').classList[action]('overflow-hidden');
    }
  }, [mobileFilterOpen]);

  useEffect(() => {
    if (!header) return;
    const headerHeight = header.offsetHeight;
    if (vpWidth < 1024) {
      if (scrollDirection === 'up') {
        header.style.top = '0';
        filtersContainer.current.style.top = headerHeight - 1 + 'px';
        stickySentinel.current.style.height = headerHeight + 1 + 'px';
      } else if(scrollDirection === 'down') {
        header.style.top = `-${headerHeight}px`;
        filtersContainer.current.style.top = '0';
        stickySentinel.current.style.height = '1px';
      }
    } else {
      header.style.top = '0';
      filtersContainer.current.style.top = headerHeight + 'px';
      stickySentinel.current.style.height = headerHeight + 1 + 'px';
    }
  }, [vpWidth, scrollDirection]);

  return (
    <>
      <div
        className="filters__desktop-container sticky z-30 mx-auto mb-5 bg-white transition-all duration-150 ease-in-out"
        ref={filtersContainer}
      >
        <span
          className="sticky_sentinel absolute bottom-full block w-5"
          ref={stickySentinel}
        />
        {enableShadow && (
          <span className="absolute left-1/2 top-0 hidden h-full w-full -translate-x-1/2 opacity-25 shadow-md shadow-black lg:block" />
        )}

        <div className="flex border-t border-b border-grey-4 lg:container lg:mx-auto lg:border-b-0 lg:border-t-0 lg:px-5 lg:py-7">
          <div
            className={`filters__mobile-toggler-container lg:hidden ${
              disableSort ? 'w-full' : 'w-1/2'
            }`}
          >
            <button
              type="button"
              className="flex h-[50px] w-full items-center justify-center text-xs"
              onClick={toggleMobileFilter}
            >
              <span className="icon-container mb-0.5">
                <Filters />
              </span>
              <span className="ml-2">Filter</span>
              {currentFilters?.length > 0 && (
                <span className="ml-1">({currentFilters.length})</span>
              )}
            </button>
          </div>
          <div className="filters__desktop-container hidden items-center lg:flex">
            <div className="filters__label mr-5 flex items-center gap-2">
              <Filters />
              filter
            </div>
            <div className="filters__desktop-container flex gap-5">
              {buildDesktopFilterGroups()}
            </div>
          </div>
          {disableSort !== true && (
            <div className="filters__desktop-sort-container relative ml-auto w-1/2 lg:hidden lg:w-auto xl:flex">
              <span className="mr-4 ml-4 hidden flex-shrink-0 items-center gap-4 lg:flex">
                <SortBy />
                sort by
              </span>
              <button
                type="button"
                className={`sort-box flex h-[50px] w-full items-center justify-center px-2 lg:w-[205px] lg:border lg:border-grey-1 lg:px-0 ${
                  enableFilters !== false && 'border-l border-grey-4'
                }`}
                onClick={() => {
                  if (document.location.href.indexOf('/collections/') > -1) {
                    window.dataLayer.push({
                      event: 'Click - Desktop Sort By',
                    });
                  }
                  setSortOpen((curentSortOpen) => !curentSortOpen);
                }}
              >
                <span className="max-w-full overflow-hidden text-ellipsis whitespace-nowrap text-xs lg:w-full lg:px-4 lg:text-left lg:font-gothamBold">
                  {getCurrentSortLabel() || 'Sort By'}
                </span>
                <div className="flex h-full w-[50px] shrink-0 items-center justify-center self-end lg:border-l">
                  <div
                    className={`transition-transform duration-150 ${
                      !sortOpen ? 'rotate-180' : ''
                    }`}
                  >
                    <ArrowUp className="w-3 md:w-4" />
                  </div>
                </div>
              </button>
              {sortOpen && (
                <ul className="sort-options absolute top-full right-0 z-50 m-0 w-full border border-grey-4 bg-white p-0 md:border-grey-1">
                  {sortOptions.map((option) => {
                    return (
                      <li key={option.value} className="my-4 list-none">
                        <button
                          type="button"
                          className={`w-full border-0 bg-transparent px-4 text-left text-xs hover:font-gothamBold ${
                            option.value === sort ? 'font-gothamBold' : ''
                          }`}
                          onClick={() => {
                            updateSortOption(option.value);
                          }}
                        >
                          {option.label}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          )}
        </div>
      </div>
      {currentFilters.length > 0 && (
        <div className="filters__active-list my-5 hidden items-start gap-5 px-5 lg:container lg:mx-auto lg:flex lg:px-5">
          <span className="filters__active-label shrink-0 font-gothamBold text-xs leading-8">
            Refine By
          </span>
          <div className="filter__active-items flex flex-wrap gap-5">
            {buildActivefilters()}
            <button
              type="button"
              className="font-gothamBold text-sm underline hover:no-underline"
              onClick={clearFilters}
            >
              clear all
            </button>
          </div>
        </div>
      )}
      <div className="filters__mobile-container">
        <aside
          key="filter-sidebar"
          className={`md:min-w[250px] fixed top-0 left-0 z-[2147483648] flex h-full w-full max-w-md flex-col overflow-hidden bg-white transition-all duration-300
          ${mobileFilterOpen ? 'translate-x-0' : '-translate-x-full'}`}
        >
          <div className="filter-header-mobile sticky top-0 z-10 flex items-center border-b bg-white px-7 py-5">
            {currentFilters.length > 0 && (
              <button
                type="button"
                className="focus-link"
                onClick={clearFilters}
              >
                clear filters
              </button>
            )}

            <button
              type="button"
              className="close-button ml-auto flex items-center gap-6 font-gothamBold"
              onClick={toggleMobileFilter}
            >
              close
              <Close />
            </button>
          </div>
          <div className="overflow-y-auto">{buildMobilefilterGroups()}</div>
          <div className="filter-footer-mobile sticky bottom-0 mt-auto bg-grey-9 px-4 py-5">
            <button
              type="button"
              className="button button--primary w-full font-gothamBold"
              onClick={toggleMobileFilter}
            >
              apply filters
            </button>
          </div>
        </aside>
        {mobileFilterOpen && (
          <button
            type="button"
            aria-label="Toggle mobile filter"
            className={`mobile-filter-backdrop fixed top-0 left-0 z-[214748364] h-full w-full cursor-pointer bg-black transition-all duration-300 ${
              mobileFilterOpen
                ? 'pointer-events-auto opacity-50'
                : 'pointer-events-none opacity-0'
            }`}
            onClick={toggleMobileFilter}
          />
        )}
      </div>
    </>
  );
}

export default FilterBar;
