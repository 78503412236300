import Slider from 'react-slick';
import Link from 'next/link';
import { getSizedImageUrl } from '@/utils/Images';
import ProductCard from '@/snippets/globals/layout/ProductCard';
import { newLineToBr } from '@/utils/Helper';
import { useCollection, useCountry, useSettings } from '@backpackjs/storefront';

function Section({ cms, collection }) {
  const { textColor, textColor_m } = cms;
  const siteSettings = useSettings();
  const bwpSettings = siteSettings?.buyWithPrime;
  const { country } = useCountry();
  const isUs = country?.isoCode === 'US';
  let showBWPBadge = false;

  if (bwpSettings?.bwpLocation === 'global' && isUs) {
    showBWPBadge = true;
  }

  if (
    bwpSettings?.bwpLocation === 'local' &&
    bwpSettings?.collectionList?.indexOf(collection?.title) > -1 &&
    isUs
  ) {
    showBWPBadge = true;
  }

  const getCTAclass = (style) => {
    if (style === 'link') return 'focus-link';
    if (style === 'white-link') return 'focus-link-white';
    return '';
  };

  const buildImage = () => {
    if (cms?.section_image_desktop || cms?.section_image_mobile) {
      return cms?.section_image_link?.url ? (
        <Link href={cms?.section_image_link?.url}>
          <a className="relative cursor-pointer">
            <picture className="relative block h-auto w-full lg:h-full">
              {cms?.section_image_mobile && (
                <source
                  srcSet={getSizedImageUrl(
                    cms?.section_image_desktop.src,
                    '432x@2x'
                  )}
                  media="(min-width: 1024px)"
                />
              )}
              <img
                src={getSizedImageUrl(
                  cms?.section_image_mobile?.src ||
                    cms?.section_image_desktop?.src,
                  '670x405@2x'
                )}
                alt={cms.section_image_alt}
                loading="lazy"
                className="top-0 left-0 w-full object-cover object-center lg:absolute lg:h-full"
              />
            </picture>

            <div className="absolute bottom-0 left-0 hidden w-full py-4 px-5 sm:py-6 md:py-8 lg:block lg+:px-7">
              {cms?.desktop_image_title && (
                <h3
                  className={`m-0 mb-3 font-tiempos text-h3-m md:text-h4 ${textColor}`}
                >
                  {cms?.desktop_image_title}
                </h3>
              )}
              {cms?.desktop_image_copy && (
                <p className={`mb-2 ${textColor}`}>{cms?.desktop_image_copy}</p>
              )}
              <span
                className={`${getCTAclass(
                  cms.section_image_cta_style
                )} cursor-pointer text-[14px] leading-[22px]`}
              >
                {cms.section_image_link.text}
              </span>
            </div>

            <div className="absolute bottom-0 left-0 block w-full py-4 px-5 sm:py-6 md:py-8 lg:hidden lg+:px-7">
              {cms?.mobile_image_title && (
                <h3
                  className={`m-0 mb-3 font-tiempos text-h3-m md:text-h4 ${textColor_m}`}
                >
                  {cms?.mobile_image_title}
                </h3>
              )}
              {cms?.mobile_image_description && (
                <p className={`mb-2 ${textColor_m}`}>
                  {cms?.mobile_image_description}
                </p>
              )}
              <span className="focus-link cursor-pointer">
                {cms.section_image_link.text}
              </span>
            </div>
          </a>
        </Link>
      ) : (
        <picture className="relative block h-auto w-full lg:h-full">
          {cms?.section_image_mobile && (
            <source
              srcSet={getSizedImageUrl(
                cms?.section_image_desktop.src,
                '432x@2x'
              )}
              media="(min-width: 1024px)"
            />
          )}
          <img
            src={getSizedImageUrl(
              cms?.section_image_mobile?.src || cms?.section_image_desktop?.src,
              '670x405@2x'
            )}
            alt={cms.section_image_alt}
            loading="lazy"
            className="top-0 left-0 w-full object-cover object-center lg:absolute lg:h-full"
          />
          <div className="absolute bottom-0 left-0 hidden w-full py-4 px-5 sm:py-6 md:py-8 lg:block lg+:px-7">
            {cms?.desktop_image_title && (
              <h3
                className={`m-0 mb-3 font-tiempos text-h3-m md:text-h4 ${textColor}`}
              >
                {cms?.desktop_image_title}
              </h3>
            )}
            {cms?.desktop_image_copy && (
              <p className={`mb-0 ${textColor}`}>{cms?.desktop_image_copy}</p>
            )}
          </div>

          <div className="absolute bottom-0 left-0 block w-full py-4 px-5 sm:py-6 md:py-8 lg:hidden lg+:px-7">
            {cms?.mobile_image_title && (
              <h3
                className={`m-0 mb-3 font-tiempos text-h3-m md:text-h4 ${textColor_m}`}
              >
                {cms?.mobile_image_title}
              </h3>
            )}
            {cms?.mobile_image_description && (
              <p className={`mb-0 ${textColor_m}`}>
                {cms?.mobile_image_description}
              </p>
            )}
          </div>
        </picture>
      );
    }

    return null;
  };

  const buildText = (floatingCta = false) => {
    return (
      <div className="recommendations-text relative mx-auto mb-7 flex w-full max-w-[440px] flex-col items-center px-4 text-center md:mb-15 md:px-0 lg:block lg:max-w-full lg:text-left">
        {cms?.section_title && (
          <h2 className="text-header mb-5 lg:mb-2 lg:max-w-md xl:max-w-lg">
            {cms?.section_title}
          </h2>
        )}
        {cms?.section_description && (
          <p
            className="mb-5 text-h6-m lg:mb-4 lg:max-w-sm lg:text-h6 xl:max-w-lg"
            dangerouslySetInnerHTML={{
              __html: newLineToBr(cms?.section_description),
            }}
          />
        )}
        {cms?.section_cta?.text && cms?.section_cta?.url && (
          <div
            className={`${
              floatingCta
                ? 'lg:absolute lg:right-[7%] lg:bottom-0 '
                : 'relative'
            }`}
          >
            <Link href={cms?.section_cta.url}>
              <a
                className="focus-link"
                target={cms?.section_cta.newTab ? '_blank' : null}
              >
                {cms?.section_cta.text}
              </a>
            </Link>
          </div>
        )}
      </div>
    );
  };

  return (
    <section
      id={`${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId}`}
      key={cms?.cmsId}
      data-comp={Section.key}
      className="flex flex-col items-center gap-x-7 px-0 md-:px-12 lg:flex-row lg:items-stretch"
    >
      <style>
        {`#${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
            margin-top: ${cms.mobileSpacing}px;
          }
          @media(min-width: 1024px) {
            #${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
              margin-top: ${cms.desktopSpacing}px;
            }
          }`}
      </style>
      <div className="recommendations-left-area mb-6 w-full shrink-0 md:mb-7 lg:mb-0 lg:w-3/12">
        {buildImage() ? buildImage() : buildText()}
      </div>
      <div className="recommendations-right-area w-full lg:w-9/12">
        {buildImage() && buildText(true)}
        {cms?.product_list?.length > 3 ? (
          <Slider
            slidesToShow={4.2}
            infinite
            arrows
            draggable
            slidesToScroll={1}
            responsive={[
              {
                breakpoint: 1500,
                settings: {
                  slidesToShow: 3.2,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 1100,
                settings: {
                  slidesToShow: 2.1,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3.2,
                  slidesToScroll: 1,
                  infinite: false,
                  arrows: false,
                },
              },
              {
                breakpoint: 450,
                settings: {
                  slidesToShow: 1.15,
                  slidesToScroll: 1,
                  infinite: false,
                  arrows: false,
                },
              },
            ]}
            className="product-recommendations-slider"
          >
            {cms?.product_list.map((productItem, index) => {
              return productItem.product ? (
                <ProductCard
                  handle={productItem.product.handle}
                  showReviews={false}
                  quickViewButton="bottom"
                  key={index}
                  showBWPBadge={showBWPBadge}
                />
              ) : null;
            })}
          </Slider>
        ) : (
          <div className="flex gap-5">
            {cms?.product_list.map((productItem, index) => {
              return productItem.product ? (
                <div className="max-w-xs flex-1">
                  <ProductCard
                    handle={productItem.product.handle}
                    showReviews={false}
                    quickViewButton="bottom"
                    key={index}
                    showBWPBadge={showBWPBadge}
                  />
                </div>
              ) : null;
            })}
          </div>
        )}
      </div>
    </section>
  );
}

Section.displayName = 'Product Recommendations';

Section.Schema = {
  category: 'Modules',
  label: Section.displayName,
  key: 'productRecommendations',
  fields: [
    {
      component: 'number',
      name: 'mobileSpacing',
      label: 'Section Top Margin - Mobile',
      defaultValue: 60,
    },
    {
      component: 'number',
      name: 'desktopSpacing',
      label: 'Section Top Margin - Desktop',
      defaultValue: 80,
    },
    {
      component: 'text',
      label: 'Section Title',
      name: 'section_title',
    },
    {
      component: 'textarea',
      label: 'Section Description',
      name: 'section_description',
    },
    {
      component: 'link',
      label: 'Section CTA',
      name: 'section_cta',
    },
    {
      component: 'image',
      label: 'Section Image Desktop (optional)',
      name: 'section_image_desktop',
      description: 'Recommended size: 864px x 1420px (Height can be variable)',
    },
    {
      component: 'image',
      label: 'Section Image Mobile (optional)',
      name: 'section_image_mobile',
      description: 'Recommended size: 1340px x 800px',
    },
    {
      component: 'text',
      label: 'Section Image Alt',
      name: 'section_image_alt',
      description: 'For ADA. Add description about image',
    },
    {
      component: 'link',
      label: 'Section Image Link',
      name: 'section_image_link',
      description:
        'Optional. Applies to desktop and mobile image. If link and text are not populated then the link will not show',
    },
    {
      name: 'section_image_section_image_',
      label: 'CTA Link Style',
      component: 'select',
      options: [
        { label: 'Focus Link', value: 'link' },
        { label: 'Focus Link White', value: 'white-link' },
      ],
      defaultValue: 'link',
    },
    {
      component: 'text',
      label: 'Desktop Image Title',
      name: 'desktop_image_title',
    },
    {
      component: 'text',
      label: 'Desktop Image Description',
      name: 'desktop_image_copy',
    },
    {
      component: 'select',
      name: 'textColor',
      label: 'Text Color (Desktop)',
      options: [
        { label: 'Light', value: 'text-white' },
        { label: 'Dark', value: 'text-black' },
      ],
      defaultValue: 'text-black',
    },
    {
      component: 'text',
      label: 'Mobile Image Title',
      name: 'mobile_image_title',
    },
    {
      component: 'text',
      label: 'Mobile Image Description',
      name: 'mobile_image_description',
    },
    {
      component: 'select',
      name: 'textColor_m',
      label: 'Text Color (Mobile)',
      options: [
        { label: 'Light', value: 'text-white' },
        { label: 'Dark', value: 'text-black' },
      ],
      defaultValue: 'text-black',
    },
    {
      component: 'group-list',
      label: 'Product List',
      name: 'product_list',
      description: 'Recommended to have at least 5 products populated.',
      fields: [
        {
          component: 'productSearch',
          label: 'Product',
          name: 'product',
        },
      ],
    },
  ],
};

export const ProductRecommendations = Section;
