import React from "react";

export const useKlaviyoApi = () => {
  const storeKeyId = 'H6yLPD';
  const revision = '2024-02-15';

  const subscribeToList = async ({listId, email, phone, source, callback, properties}) => {
    if(typeof(listId) == 'undefined') {
      console.error('useKlaviyoApi.subscribeToList: listId is required');
      return;
    }
    if(typeof(email) == 'undefined' && typeof(phone) == 'undefined') {
      console.error('useKlaviyoApi.subscribeToList: email or phone are required');
      return;
    }

    const response = await fetch(`https://a.klaviyo.com/client/subscriptions/?company_id=${storeKeyId}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        revision: revision,
      },
      body: JSON.stringify({
        data: {
          type: 'subscription',
          attributes: {
            custom_source: source,
            profile: {
              data: {
                type: 'profile',
                attributes: {
                  email: email,
                  phone_number: phone,
                },
                properties: properties
              }
            }
          },
          relationships: {
            list: {
              data: {
                type: 'list',
                id: listId,
              }
            }
          }
        },
      })
    }).then((response) => {
      if (response.status == 202) {
        return 'success';
      } else {
        return response.json()
      }});

    if(callback) {
      callback(response);
    }

    return response;
  }

  const sendEvent = async ({email, phone, eventName, profileProperties, eventProperties, metricName, callback}) => {
    if(typeof(eventName) == 'undefined') {
      console.error('useKlaviyoApi.sendEvent: eventName is required to submit an event to klaviyo');
      return;
    }
    if(typeof(email) == 'undefined' && typeof(phone) == 'undefined') {
      console.error('useKlaviyoApi.sendEvent: email or phone are required to identify the customer on klaviyo');
      return;
    }

    if(typeof(metricName) == 'undefined') {
      console.error('useKlaviyoApi.sendEvent: metricName property is required to submit an event to klaviyo');
      return;
    }

    const response = await fetch(`https://a.klaviyo.com/client/events/?company_id=${storeKeyId}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        revision: revision,
      },
      body: JSON.stringify({
        data: {
          type: 'event',
          attributes: {
            properties: {
              ...eventProperties,
            },
            metric: {
              data: {
                type: 'metric',
                attributes: {
                  name: metricName,
                }
              }
            },
            profile: {
              data: {
                type: 'profile',
                attributes: {
                  email: email,
                  phone_number: phone,
                  properties: profileProperties
                },
              }
            }
          }
        },
      })
    }).then((response) => {
      if (response.status == 202) {
        return 'success';
      } else {
        return response.json()
      }});

    if(callback) {
      callback(response);
    }

    return response;
  }

  const backInStockSubscription = async ({email, phone, variantId, callback}) => {
    if(typeof(variantId) == 'undefined') {
      console.error('useKlaviyoApi.backInStockSubscription: variantId is required to submit a back in stock subscription event to klaviyo');
      return;
    }
    if(typeof(email) == 'undefined' && typeof(phone) == 'undefined') {
      console.error('useKlaviyoApi.backInStockSubscription: email or phone are required to identify the customer on klaviyo');
      return;
    }

    const channels = [];

    if(phone) {
      channels.push('SMS');
    }

    if(email) {
      channels.push('EMAIL');
    }

    const response = await fetch(`https://a.klaviyo.com/client/back-in-stock-subscriptions/?company_id=${storeKeyId}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        revision: revision,
      },
      body: JSON.stringify({
        data: {
          type: 'back-in-stock-subscription',
          attributes: {
            channels,
            profile: {
              data: {
                type: 'profile',
                attributes: {
                  email: email,
                  phone_number: phone,
                },
              },
            },
          },
          relationships: {
            variant: {
              data: {
                type: 'catalog-variant',
                id: `$shopify:::$default:::${variantId}`,
              }
            }
          },
        },
      })
    }).then((response) => {
      if (response.status == 202) {
        return 'success';
      } else {
        return response.json()
      }});

    if(callback) {
      callback(response);
    }

    return response;
  }

  return {
    subscribeToList,
    backInStockSubscription,
    sendEvent,
  }
}