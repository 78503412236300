export default function BagFull(props) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 26 33"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      fill="currentcolor"
      {...props}
    >
      <path d="M8.089,9.743L3.123,9.743L3.123,24.123C3.123,26.866 5.38,29.123 8.123,29.123C8.123,29.123 17.503,29.123 17.503,29.123C20.246,29.123 22.503,26.866 22.503,24.123C22.503,24.123 22.503,9.743 22.503,9.743L17.537,9.743L17.537,7.847C17.537,5.256 15.405,3.123 12.813,3.123C10.222,3.123 8.089,5.256 8.089,7.847L8.089,9.743ZM15.537,9.743L15.537,7.847C15.537,6.353 14.307,5.123 12.813,5.123C11.319,5.123 10.089,6.353 10.089,7.847L10.089,9.743L15.537,9.743Z" />
    </svg>
  );
}