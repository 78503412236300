import { useEffect, useState } from 'react';
import { useThrottle } from '@hooks';
import { useBuilderContext } from './BundleBuildercontext';

export default function BundleLoadMore() {
  const context = useBuilderContext();
  const { builderData, builderSettings } = context;
  const maxPages = Math.ceil(
    builderData.filteredProducts.length / builderSettings.pageLimit
  );
  const isBrowser = typeof window !== 'undefined';
  const [scrollPosition, setScrollPosition] = useState(0);
  const throttledScrollPosition = useThrottle(scrollPosition, 20);

  const [loading, setLoading] = useState(false);

  const loadNewPage = () => {
    const page = builderData.page + 1;
    if (builderData.page < maxPages) {
      // setLoading(true);
      context.updateBuilderData({
        page,
      });
    }
  };

  const scrollHandler = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    if (
      isBrowser &&
      !!builderSettings.scrollThreshold &&
      builderSettings.scrollThreshold > 0 &&
      builderData.page < maxPages
    ) {
      window.addEventListener('scroll', scrollHandler);
    }
    if (
      builderData.filteredProducts.length ===
      builderData.page * builderSettings.pageLimit
    ) {
      setLoading(false);
    }

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [builderData.filteredProducts.length]);

  useEffect(() => {
    const windowHeight = isBrowser ? document?.body?.offsetHeight : 0;
    const currentScrollThreshold =
      windowHeight * (builderSettings.scrollThreshold * 0.01);

    if (window.scrollY >= currentScrollThreshold) {
      loadNewPage();
    }
  }, [throttledScrollPosition]);

  return (
    (!builderSettings.scrollThreshold ||
      builderSettings.scrollThreshold === 0) &&
    builderData.page < maxPages && (
      <div className="load-more__container my-10 text-center">
        <button
          onClick={loadNewPage}
          type="button"
          className="button button--primary"
        >
          {loading ? 'Loading...' : 'load more'}
        </button>
      </div>
    )
  );
}