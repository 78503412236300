const COLORS = [
  { label: 'Transparent', value: 'var(--transparent)' },
  { label: 'White', value: 'var(--white)' },
  { label: 'Dark Loden', value: 'var(--dark-loden)' },
  { label: 'Dark Orion', value: 'var(--dark-orion)' },
  { label: 'Orion', value: 'var(--orion)' },
  { label: 'Light Desert', value: 'var(--light-desert)' },
];

export function Schema() {
  return {
    category: 'Navigation',
    label: 'CtaSlider',
    key: 'cta-slider',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1616/9825/files/in-page-nagigation.jpg?v=1717094674',
    fields: [
      {
        component: 'number',
        name: 'mobileSpacing',
        label: 'Section Top Margin - Mobile',
        defaultValue: 60,
      },
      {
        component: 'number',
        name: 'desktopSpacing',
        label: 'Section Top Margin - Desktop',
        defaultValue: 80,
      },
      {
        component: 'toggle',
        name: 'isFullwidth',
        label: 'Full Width',
        toggleLabels: {
          true: 'Full Width',
          false: 'Grid width',
        },
        defaultValue: true,
      },
      {
        component: 'toggle',
        name: 'enableSlider',
        label: 'Enable Slider',
        defaultValue: true,
        toggleLabels: {
          true: 'Enable',
          false: 'Disable',
        },
      },
      {
        label: 'CTA',
        name: 'cta',
        component: 'group-list',
        itemProps: {
          label: '{{item.link.text}}',
        },
        validate: {
          minItems: 2,
          maxItems: 8,
        },
        fields: [
          {
            label: 'CTA Title',
            name: 'title',
            component: 'text',
          },
          {
            label: 'CTA URL',
            name: 'link',
            component: 'link',
          },
          {
            label: 'CTA Link Style',
            name: 'cta_style',
            component: 'select',
            options: [
              { label: 'Primary', value: 'no-underline button button--primary' },
              { label: 'Primary - Air Dry Cream', value: 'no-underline button button--primary-stylers-1' },
              { label: 'Primary - Sculpting Cream', value: 'no-underline button button--primary-stylers-2' },
              { label: 'Primary - Texture Foam', value: 'no-underline button button--primary-stylers-3' },
              { label: 'Primary - Sleek Stick', value: 'no-underline button button--primary-stylers-4' },
              { label: 'Secondary', value: 'no-underline button button--secondary' },
              { label: 'checkout', value: 'no-underline button button--checkout inline-block' },
            ],
            defaultValue: 'no-underline button button--primary',
          },
          {
            label: 'CTA Visibility',
            name: 'cta_visibility',
            component: 'select',
            options: [
              { label: 'Desktop', value: 'desktop' },
              { label: 'Mobile', value: 'mobile' },
              { label: 'Both', value: '' },
            ],
            defaultValue: '',
          },
        ],
        defaultItem: { label: 'New CTA' },
      },
    ],
  };
}
