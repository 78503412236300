import { useCallback, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { nanoid } from 'nanoid';
import { useCustomer, useCustomerAccessToken } from '@backpackjs/storefront';

import { fetchCustomerOrders } from './utils';

export function useDataLayerInit({
  baseEcommerce,
  DEBUG,
  pageTitle,
  template,
}) {
  const asPathRef = useRef(null);
  const customer = useCustomer();
  const router = useRouter();
  const asPath = router.asPath.split('?')[0];

  const accessToken = useCustomerAccessToken();
  const [userProperties, setUserProperties] = useState(null);

  const customerPending = typeof customer === 'undefined';
  const pathname = asPath?.split('?')[0];

  const generateUserProperties = useCallback(
    async ({ customer: _customer, accessToken: _accessToken }) => {
      let _userProperties = {};
      if (_customer) {
        const orders = await fetchCustomerOrders({
          accessToken: _accessToken,
        });

        _userProperties = {
          visitor_type: 'logged_in',
          user_consent: '',
          customer_address_1: _customer.defaultAddress?.address1 || '',
          customer_address_2: _customer.defaultAddress?.address2 || '',
          customer_city: _customer.defaultAddress?.city || '',
          customer_country: _customer.defaultAddress?.country || '',
          customer_country_code: _customer.defaultAddress?.countryCode || '',
          customer_email: _customer.email,
          customer_first_name: _customer.firstName,
          customer_id: _customer.id?.split('/').pop() || '',
          customer_last_name: _customer.lastName,
          customer_order_count: orders?.length || 0,
          customer_phone: _customer.defaultAddress?.phone || '',
          customer_province_code: _customer.defaultAddress?.provinceCode || '',
          customer_province: _customer.defaultAddress?.province || '',
          customer_tags: _customer.tags?.join(', ') || '',
          customer_total_spent: orders
            ?.reduce((acc, order) => {
              return acc + parseFloat(order.totalPriceV2?.amount || 0);
            }, 0)
            .toFixed(2),
          customer_zip: _customer.defaultAddress?.zip || '',
        };
      } else {
        _userProperties = {
          visitor_type: 'guest',
          user_consent: '',
        };
      }
      setUserProperties(_userProperties);
      return _userProperties;
    },
    []
  );

  // Set previous paths and current path in session storage
  useEffect(() => {
    const currentPath = sessionStorage.getItem('CURRENT_PATH');
    if (asPath === currentPath) {
      sessionStorage.setItem('PREVIOUS_PATH_INLC_REFRESH', asPath);
      return;
    }
    sessionStorage.setItem('PREVIOUS_PATH', currentPath);
    sessionStorage.setItem('PREVIOUS_PATH_INLC_REFRESH', currentPath);
    sessionStorage.setItem('CURRENT_PATH', asPath);
  }, [asPath]);

  // generate user properties on customer ready and path change
  useEffect(() => {
    if (
      customerPending || // wait until customer status is ready
      (customer && !accessToken?.token) || // wait until accessToken is ready, if logged in customer
      asPath === asPathRef.current // prevent multiple triggers on same path
    )
      return undefined;
    generateUserProperties({ customer, accessToken: accessToken?.token });
    asPathRef.current = asPath;
    return () => {
      asPathRef.current = null;
    };
  }, [accessToken?.token, asPath, customerPending]);

  useEffect(() => {
    if (!baseEcommerce || !userProperties) return;

    const event = {
      event: 'dl_route_update',
      event_id: nanoid(),
      event_time: new Date().toISOString(),
      page: {
        path: pathname,
        title: pageTitle,
        type: template,
        search: window.location.search,
      },
      user_properties: userProperties,
      ecommerce: baseEcommerce,
    };

    window.dispatchEvent(
      new CustomEvent(event.event, {
        detail: event,
      })
    );
    if (DEBUG) console.log(`DataLayer:dispatch:${event.event}`, event);
  }, [!!baseEcommerce, pathname, !!userProperties]);

  return {
    generateUserProperties,
    userProperties,
  };
}
