import { useRouter } from '@backpackjs/storefront';
import { useState, useEffect } from 'react';

export function ProductOptions({
  activeVariant,
  product,
  updateUrl = true,
  onVariantChange,
}) {
  const router = useRouter();

  const [selectedOptions, setSelectedOptions] = useState(
    activeVariant?.title.split(' / ').map((value) => value.trim()) || null
  );

  useEffect(() => {
    if (selectedOptions) {
      const variantTitle = selectedOptions.join(' / ');

      const selectedVariant = product.variants.find(
        (variant) => variant.title === variantTitle
      );
      if (onVariantChange) {
        onVariantChange(selectedVariant);
      }
      if (updateUrl) {
        router.push(
          {
            pathname: router.pathname,
            query: {
              ...router.query,
              variant: selectedVariant?.legacyResourceId,
            },
          },
          undefined,
          { shallow: true }
        );
      }
    }
    if (
      typeof window !== 'undefined' &&
      window.hasOwnProperty('updateYotpoWidgets')
    ) {
      updateYotpoWidgets();
    }
  }, [selectedOptions]);

  useEffect(() => {
    setSelectedOptions(
      activeVariant?.title.split(' / ').map((value) => value.trim())
    );
  }, [activeVariant]);

  const checkCurrentVariant = (value, nameIndex) => {
    if (!selectedOptions) return false;

    if (selectedOptions[nameIndex] === value) {
      return true;
    }
  };

  if (!product || !product.options || product.variants.length <= 1) {
    return null;
  }

  return product.options.map((option, nameIndex) => {
    return (
      <div key={nameIndex} className="mb-6">
        <span className="text-small mb-1 font-gothamBold text-black">
          {' '}
          {option.name}{' '}
        </span>

        <div className="mx-auto flex w-full">
          {option.values.map((value, valueIndex) => {
            return (
              <button
                type="button"
                key={valueIndex}
                className={`w-1/6 border border py-2 px-1 font-gothamBold text-[12px] duration-200 hover:border-black hover:bg-grey-9 hover:text-black lg:text-base  xl:text-[14px] ${
                  checkCurrentVariant(value, nameIndex)
                    ? 'border-black bg-grey-9 text-black'
                    : 'border-grey-4 text-grey-3'
                }`}
                onClick={() => {
                  setSelectedOptions((curr) => {
                    const selectedOptions = [...curr];
                    selectedOptions[nameIndex] = value.trim();
                    return selectedOptions;
                  });
                }}
              >
                {value}
              </button>
            );
          })}
        </div>
      </div>
    );
  });
}
