export function ArrowUp(props) {
  return (
    <svg
      width="17"
      height="10"
      viewBox="0 0 17 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 8.5L8.5 2L2 8.5"
        stroke="currentcolor"
        strokeWidth="2"
        strokeLinecap="square"
      />
    </svg>
  );
}
