import { useState, useEffect } from 'react';

export function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState('');
  let lastScrollTop = 0;

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;
      if(currentScrollTop < 0) {
        // Nullifies scroll direction when elastic scroll is triggered.
        setScrollDirection('');
        return;
      }
      if (currentScrollTop > lastScrollTop + 10) {
        setScrollDirection('down');
        lastScrollTop = currentScrollTop;
      } else if (currentScrollTop < lastScrollTop - 10) {
        setScrollDirection('up');
        lastScrollTop = currentScrollTop;
      }

    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return scrollDirection;
}
