import { IconRemove } from "@/snippets/index.js";
import { Image } from "@/snippets/components/Image";
import { useCartRemoveItems, useCountry } from '@backpackjs/storefront';
import { formatMoney } from "@/utils/Helper";

export default function SideCartBundleItem({ item }) {
  const { cartRemoveItems } = useCartRemoveItems();
  const discountValue = item.attributes.find(
    (attribute) => attribute.key === '_discount'
  ).value;
  const { country } = useCountry();
  const currencySymbol = country?.currency.symbol || '$';

  const removeBundle = async () => {
    const lineItemsId = item.bundleItems.map((bundleItem) => bundleItem.id);

    await cartRemoveItems({ lineIds: lineItemsId });
  };

  const buildBundleItems = () => {
    const bundleItems = item.bundleItems.map((bundleItem) => {
      return (
        <div
          key={bundleItem.id}
          className="bundle-item flex justify-between py-1"
        >
          <div className="w-16 shrink-0">
            <Image
              width={100}
              height={100}
              src={bundleItem.variant.image.src}
              alt={bundleItem.variant.image.altText}
              sizes="10vw"
              loading="lazy"
            />
          </div>
          <div className="relative-flex-grow w-full pl-3">
            <span className="inline-block pr-4 font-gothamBold text-xs">
              {bundleItem.variant.product.title}
            </span>
          </div>
        </div>
      );
    });

    return bundleItems;
  };

  const bundleFullPrice = item.bundleItems.reduce((acc, bundleItem) => {
    return (
      acc + Number.parseFloat(bundleItem.estimatedCost.subtotalAmount.amount)
    );
  }, 0);

  const bundleDiscountedPrice = item.bundleItems.reduce((acc, bundleItem) => {
    return acc + Number.parseFloat(bundleItem.estimatedCost.totalAmount.amount);
  }, 0);

  return (
    <div key={item?.attributes["_bundleId"]} className="items start flex flex-col justify-between border-b border-grey-4 py-5 px-4">
      <div className="bundle-line-item__header flex justify-center">
        <div className="my-0 block font-gothamBold text-xs">
          Bundled Items
          <span className="relative top-[-1px] ml-1 inline-block rounded-sm border border-solid border-primary-2 px-1 font-gothamBold text-sub-xx-small text-primary-2">
            {discountValue}% off
          </span>
        </div>
        <button type="button" onClick={removeBundle} className="ml-auto">
          <IconRemove />
        </button>
      </div>
      <div className="bundle-line-item__body flex flex-col py-2">
        {buildBundleItems()}
      </div>
      <div className="bundle-line-item__price text-right">
        {bundleFullPrice > bundleDiscountedPrice && (
          <s className="font-gothamBold text-sm text-grey-3">
            {formatMoney(bundleFullPrice.toFixed(2) || 0).replace(
              '$',
              currencySymbol
            )}
          </s>
        )}
        <span
          className={`${
            bundleFullPrice > bundleDiscountedPrice ? 'ml-2' : ''
          } font-gothamBold text-sm`}
        >
          {formatMoney(bundleDiscountedPrice.toFixed(2) || 0).replace(
            '$',
            currencySymbol
          )}
        </span>
      </div>
    </div>
  );
}
