import Link from 'next/link';
import { useSettings } from '@backpackjs/storefront';
import { newLineToBr, getApplicableOverride } from '@/utils/Helper';

export function QuizBanner() {
  const settings = useSettings();
  if (!settings?.footer?.quizBanner?.sectionTitle && !settings?.footer?.quizBanner?.sectionDescription) return null;
  const { sectionTitle, fontSize, sectionDescription, cta, textAlign, textAlign_m, lightText, bgColor } = settings?.footer?.quizBanner;
  let alignContainer = '';
  if (textAlign_m === 'text-right') {
    alignContainer += 'mr-0';
  } else if (textAlign_m === 'text-left') {
    alignContainer += 'ml-0';
  } else {
    alignContainer += 'mx-auto';
  }
  if (textAlign === 'md:text-right') {
    alignContainer += ' md:flex-row-reverse md:gap-6 md:items-center md:mr-0';
  } else if (textAlign === 'md:text-left') {
    alignContainer += ' md:flex-row md:gap-6 md:items-center md:ml-0';
  } else {
    alignContainer += ' md:mx-auto md:max-w-[55.5rem]';
  }

  return (
    <div
      id={`footer-quiz-banner`}
      data-comp="footer-quiz-banner"
      className={bgColor}
    >
      <div className="my-0 mx-auto flex w-full max-w-[496px] md:max-w-[1180px] items-center px-8">
        <div
          className={`py-9 lg:py-16 flex flex-col ${
            lightText ? 'text-grey-9' : 'text-black'
          } ${textAlign_m} ${textAlign} ${alignContainer}`}
        >
          <div>
            <h2 className={`font-tiempos ${fontSize} mb-2`}>
              {' '}
              {sectionTitle}
            </h2>
            {sectionDescription && (
              <p
                className="mb-0"
                dangerouslySetInnerHTML={{
                  __html: newLineToBr(sectionDescription),
                }}
              />
            )}
          </div>
          {cta?.link?.text && (
            <div className={`${textAlign === 'md:text-center' ? 'mt-6' : 'max-md:mt-6'} block`}>
              {cta?.link?.text && cta?.link?.url && (
                <Link href={cta.link.url}>
                  <a
                    className={`${cta.style} no-underline max-md:!min-w-[10rem] max-md:!py-1.5`}
                    target={cta.link.newTab ? '_blank' : null}
                  >
                    {cta.link.text}
                  </a>
                </Link>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
