export function Quiz({ cms }) {
  return (
    <section
      data-comp={Quiz.displayName}
      id={`${Quiz.Schema.key}-${cms.id || cms.cmsId || cms.tinaId}`}
    >
      <style>
        {`#${Quiz.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
            margin-top: ${cms.mobileSpacing}px;
          }
          @media(min-width: 1024px) {
            #${Quiz.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
              margin-top: ${cms.desktopSpacing}px;
            }
          }`}
      </style>
      {cms?.snippet && (
        <div
          className="m-auto block"
          dangerouslySetInnerHTML={{ __html: cms.snippet }}
        />
      )}
    </section>
  );
}

Quiz.displayName = 'Quiz';

Quiz.Schema = {
  category: 'Modules',
  label: Quiz.displayName,
  key: 'quiz',
  fields: [
    {
      component: 'number',
      name: 'mobileSpacing',
      label: 'Section Top Margin - Mobile',
      defaultValue: 60,
    },
    {
      component: 'number',
      name: 'desktopSpacing',
      label: 'Section Top Margin - Desktop',
      defaultValue: 80,
    },
    {
      name: 'snippet',
      label: 'Quiz Snippet',
      component: 'html',
      description: 'Paste in snippet from Quiz app',
    },
  ],
};
