export default function DiscountMessage({
  hideDiscount,
  discountHighlight,
  promo,
  isUs,
  siteSettings,
  handle,
  discountValue,
}) {

  let promoMessage = null;
  const pdpDiscountMessage = siteSettings?.discountHighlight?.pdpDiscountMessage;
  const enableGlobalDiscount = siteSettings?.product?.enableGlobalDiscountMessage;
  const globalDiscountMessage = siteSettings?.product?.globalDiscountMessage;
  const globalDiscountExceptions = siteSettings?.product?.globalDiscountExceptions;

  if(!hideDiscount && !discountHighlight && promo && isUs) {
    promoMessage = promo;
  }

  if(!hideDiscount && discountHighlight && discountValue) {
    promoMessage = pdpDiscountMessage.replace('[discount]', `${discountValue}%`);
  }

  if(enableGlobalDiscount && globalDiscountMessage) {
    const isException = globalDiscountExceptions?.some((exception) => {
      return exception?.handle == handle
    })

    if(!isException) {
      promoMessage = globalDiscountMessage;
    }
  }

  return (
    <p className="sub-xsmall md:sub-small text-pink product-discount-message mb-2">
      { promoMessage }
    </p>
  );
}