import { useLocalizedVariant, useCountry } from '@backpackjs/storefront';
function ProductPrice({
  variant,
  selectedPlan,
  planPrice,
  className = '',
  compareStyles = '',
}) {
  const { country } = useCountry();
  const { localized: localizedVariant } = useLocalizedVariant({ variant });
  const selectedVariant = { ...variant, ...localizedVariant };
  const isLocalized = !!selectedVariant?.priceV2?.locale;
  const currencySymbol = isLocalized ? country?.currency?.symbol || '$' : '$';

  const getCompareAtPrice = () => {
    let comparePrice;

    if (selectedVariant?.compareAtPriceV2?.amount) {
      comparePrice = parseFloat(
        selectedVariant?.compareAtPriceV2?.amount
      ).toFixed(2);
    }

    if (selectedPlan && planPrice) {
      comparePrice = parseFloat(selectedVariant?.priceV2?.amount).toFixed(2);
    }

    if (comparePrice === 0) return null;
    return comparePrice;
  };

  const getProductPrice = () => {
    let price = parseFloat(selectedVariant?.priceV2?.amount).toFixed(2);

    if (selectedPlan && planPrice) {
      price = parseFloat(planPrice).toFixed(2);
    }

    return price;
  };

  const compareAmount = getCompareAtPrice();
  const comparePrice = compareAmount
    ? `${currencySymbol}${compareAmount}`.replace('.00', '')
    : compareAmount;
  const productPrice = `${currencySymbol}${getProductPrice()}`.replace(
    '.00',
    ''
  );

  // hide price is not us and currency conversion not found
  // if (!isUs && !isLocalized) return null;
  if (!variant) return null;

  return (
    <div
      key="product-price"
      className={`product-price font-gothamBold text-sm ${className}`}
    >
      {compareAmount && (
        <span className={`mr-1 text-grey-3 line-through ${compareStyles}`}>
          <div className="sr-only">Previous Price</div>
          {comparePrice}
        </span>
      )}
      <span>
        <div className="sr-only text-grey-1">Current price</div>
        {productPrice}
      </span>
    </div>
  );
}

export default ProductPrice;