function DarkCircleCheckmark() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="11.25" stroke="black" strokeWidth="1.5" />
      <path
        d="M8 12.5714L11.2727 16L16 8"
        stroke="#444444"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </svg>
  );
}

export default DarkCircleCheckmark;
