import { useCartAddItem, useProductByHandle } from "@backpackjs/storefront";
import { useCountry } from "@backpackjs/storefront";
import { YotpoStars } from "@/snippets/components/YotpoStars";
import { useYotpoProductBottomline } from "@hooks";
import { getMetafieldMap, getMetafieldColor } from '@/utils/Metafields';
import { useEffect, useMemo, useState } from "react";
import ProductPrice from "@/snippets/globals/layout/ProductPrice";
import { Image } from "@/snippets/components/Image";


export default function QuizProduct(props) {
  const {
    productData,
    index
  } = props;
  const {cartAddItem} = useCartAddItem();
  const [added, setAdded] = useState(false);
  const { product } = useProductByHandle({handle: productData.product.handle});
  const metafieldMap = useMemo(() => {
    return getMetafieldMap(product);
  }, [product?.id])

  const currentVariant = product?.variants[0];
  const categoryTitle = metafieldMap.custom_collection_title;
  const categoryColor = getMetafieldColor(metafieldMap);
  const customTitle = metafieldMap.custom_product_title || product?.title;
  const { bottomline } = useYotpoProductBottomline({legacyResourceId: product?.legacyResourceId});
  const { country } = useCountry();
  const countryCode = country?.isoCode || 'US';
  const isUs = countryCode === 'US';

  useEffect(() => {
    if (added) {
      if(isUs) {
        const datalayer = window.dataLayer || [];
        datalayer.push({
          event:'quiz_atc_single_product'
        })
      }
      setTimeout(() => {
        setAdded(false);
      }, 3000);
    }
  }, [added])

  return (
    <div className="quiz-product flex flex-wrap">
      <div className="quiz-product__image w-1/3">
        {product?.featuredImage?.src && (
          <Image
            src={product?.featuredImage?.src}
            alt={product?.featuredImage?.altText}
            width={107}
            height={107}
            loading="lazy"
            className="block w-full"
          />
        )}
      </div>
      <div className="quiz-product__info w-2/3 pl-3">
        <span className={`${categoryColor} font-gothamBold text-xs`}>
          {categoryTitle}
        </span>
        <h4 className="mb-1.5 font-tiempos text-sm"> {customTitle} </h4>
        <YotpoStars rating={bottomline?.average_score} />
        <button
          type="button"
          className="button button--checkout mt-3 w-full !text-xs"
          onClick={() => {
            cartAddItem({
              merchandiseId: currentVariant.id,
              quantity: 1,
              attributes: [
                {
                  key: '_sc',
                  value:
                    typeof window.ShipScoutLineItemProperty !== 'undefined'
                      ? window.ShipScoutLineItemProperty
                      : 'null',
                },
              ],
            }).then(() => {
              setAdded(true);
            });
          }}
        >
          {added == true ? (
            'added'
          ) : (
            <>
              add to cart{' '}
              {currentVariant && (
                <ProductPrice
                  variant={currentVariant}
                  className="ml-2 inline-block !text-xs "
                  compareStyles=" !text-xs !text-white"
                />
              )}
            </>
          )}
        </button>
      </div>
      {productData.resultMessage && (
        <div className="mt-5 w-full text-xs">
          <p className="mb-0">
            <b> Why this product? </b> {productData.resultMessage}{' '}
          </p>
        </div>
      )}
    </div>
  );
}