import { createContext, useContext } from 'react';

export const BuilderContext = createContext({
  builderSettings: {},
  updateBuilderSettings: () => {},
  builderData: {},
  updateBuilderData: () => {},
});

export function useBuilderContext() {
  return useContext(BuilderContext);
}
