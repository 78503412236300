const generateId = () => Math.random().toString(36).substr(2, 9);

export default [
  {
    label: 'Analytics',
    name: 'analytics',
    component: 'group',
    description: 'Enable Shopify analytics',
    fields: [
      {
        label: 'Enabled',
        name: 'enabled',
        component: 'toggle',
        description: 'Ensure "NEXT_PUBLIC_SHOPIFY_SHOP_ID" is set as an env var',
        toggleLabels: {
          true: 'On',
          false: 'Off',
        },
        defaultValue: true,
      },
    ],
  },
  {
    label: 'Geo Localization',
    name: 'geoLocalization',
    component: 'group',
    fields: [
      {
        label: 'Enable Country Recommendation',
        component: 'toggle',
        name: 'enableNotification',
        description:
          "Enables a modal window that notifies the customer about being on a country different to their own, if disabled the store will pick customer's location by default",
      },
      {
        label: 'Modal title',
        component: 'text',
        name: 'modalTitle',
        description:
          "Title for the modal shown to the customer, use keyword [currentCountry] to show the current store country name use [customerCountry] to show the country where they are located at, use [storeCurrency] to display store's current currency, use [customerCurrency] to show the customer's country currency (example USD $)",
      },
      {
        label: 'Modal description',
        component: 'markdown',
        name: 'modalBody',
        description:
          "Body of the modal shown to the customer, use keyword [currentCountry] to show the current store country name, use [customerCountry] to show the country where they are located at, use [storeCurrency] to display store's current currency, use [customerCurrency] to show the customer's country currency (example USD $)",
      },
    ],
  },
  {
    label: 'Discount Banner',
    name: 'discountBanner',
    component: 'group',
    description: 'Discount Banner Settings',
    fields: [
      {
        component: 'toggle',
        name: 'toggleAutoRotate',
        label: 'Auto Rotate Toggle',
        description:
          'Describes if banner is auto-rotating when 2 messages populated.',
        toggleLabels: {
          true: 'Enable',
          false: 'Disable',
        },
        defaultValue: true,
      },
      {
        component: 'toggle',
        name: 'toggleAutoRotateInt',
        label: 'Int Auto Rotate Toggle',
        description:
          'Int - Describes if banner is auto-rotating when 2 messages populated.',
        toggleLabels: {
          true: 'Enable',
          false: 'Disable',
        },
        defaultValue: true,
      },
      {
        component: 'group-list',
        name: 'messages',
        label: 'Discount Banner Messages',
        description: 'Discount banner messages for desktop and mobile.',
        fields: [
          {
            component: 'textarea',
            name: 'messageMobile',
            label: 'Mobile Message',
          },
          {
            component: 'textarea',
            name: 'messageDesktop',
            label: 'Desktop Message',
          },
        ],
      },
      {
        component: 'group-list',
        name: 'messagesInt',
        label: 'Int Discount Banner Messages',
        description:
          'International discount banner messages for desktop and mobile.',
        fields: [
          {
            component: 'textarea',
            name: 'messageMobile',
            label: 'Mobile Message',
          },
          {
            component: 'textarea',
            name: 'messageDesktop',
            label: 'Desktop Message',
          },
        ],
      },
      {
        component: 'select',
        name: 'bgColor',
        label: 'Background Color',
        description:
          'For discount banner background color, select one from preselected brand colors.',
        options: [
          { label: 'Grey', value: 'grey-1' },
          { label: 'Pink', value: 'primary-2' },
          { label: 'Green', value: 'quaternary-1' },
          { label: 'Blue 1', value: 'quinary-1' },
          { label: 'Blue 2', value: 'quinary-2' },
          { label: 'Air Dry Cream', value: 'stylers-1' },
          { label: 'Sculpting Cream', value: 'stylers-2' },
          { label: 'Texture Foam', value: 'stylers-3' },
          { label: 'Sleek Stick', value: 'stylers-4' }
        ],
        defaultValue: 'quinary-2',
      },
      {
        component: 'select',
        name: 'bgColorInt',
        label: 'Int Background Color',
        description:
          'International - For discount banner background color, select one from preselected brand colors.',
        options: [
          { label: 'Grey', value: 'grey-1' },
          { label: 'Pink', value: 'primary-2' },
          { label: 'Green', value: 'quaternary-1' },
          { label: 'Blue 1', value: 'quinary-1' },
          { label: 'Blue 2', value: 'quinary-2' },
          { label: 'Air Dry Cream', value: 'stylers-1' },
          { label: 'Sculpting Cream', value: 'stylers-2' },
          { label: 'Texture Foam', value: 'stylers-3' },
          { label: 'Sleek Stick', value: 'stylers-4' }
        ],
        defaultValue: 'quinary-2',
      },
      {
        component: 'group-list',
        name: 'overrides',
        label: 'Discount overrides',
        description:
          'Describes the text cases when a discount code is applied on the cart, this overrides will apply to both INTL and US customers the same',
        fields: [
          {
            component: 'text',
            name: 'discountCode',
            label: 'Discount code reference',
            description:
              'Discount code that needs to be present on the cart in order to trigger this override',
          },
          {
            component: 'textarea',
            name: 'messageMobile',
            label: 'Mobile Message',
          },
          {
            component: 'textarea',
            name: 'messageDesktop',
            label: 'Desktop Message',
          },
        ],
      },
    ],
  },
  {
    label: 'Global Share Image',
    name: 'globalShareImage',
    component: 'group',
    fields: [
      {
        label: 'Image',
        name: 'image',
        component: 'image',
        description: 'Recommended image size: 1200 x 628px',
      },
    ],
  },
  {
    label: 'Header',
    name: 'header',
    component: 'group',
    fields: [
      {
        label: 'Logo',
        name: 'logo',
        component: 'image',
      },
      {
        label: 'Logo Alt Text',
        name: 'tagline',
        component: 'text',
        description: 'Add descriptive text for ADA',
      },
      {
        label: 'Rewards Link in Header',
        name: 'rewards_enabled',
        component: 'toggle',
        description: 'Toggle Off if wanting to display custom CTA below instead.',
        toggleLabels: {
          true: 'On',
          false: 'Off',
        },
        defaultValue: true,
      },
      {
      label: 'CTA',
      name: 'cta',
      component: 'group',
      description: 'If Rewards Link in Header is toggled off then CTA will display instead',
      fields: [
        {
          label: 'CTA URL',
          name: 'link',
          component: 'link',
          description: 'Recommended Max Character count: 17',
        },
        {
          component: 'select',
          name: 'style',
          label: 'Cta Style',
          description: 'CTA Style and link title will be applied the same for desktop and mobile',
          options: [
              { label: 'Primary', value: 'button button--primary inline-block' },
              {
                label: 'Secondary',
                value: 'button button--secondary inline-block',
              },
              { label: 'Focus Link', value: 'focus-link' },
              {
                label: 'checkout',
                value: 'button button--checkout inline-block',
              },
            ],
            defaultValue: 'button button--primary inline-block',
          },
        ],
      },
    ],
  },
  {
    label: 'Header Menu',
    name: 'header_menu',
    component: 'group-list',
    itemProps: {
      label: '{{item.link.text}}',
    },
    fields: [
      {
        label: 'Link',
        name: 'link',
        component: 'link',
      },
      {
        label: 'Column',
        name: 'column',
        component: 'group-list',
        fields: [
          {
            component: 'toggle',
            name: 'enable_column',
            label: 'Enable',
            description: 'Enable Column',
            defaultValue: true,
          },
          {
            component: 'toggle',
            name: 'featured_column',
            label: 'Featured Column',
            description:
              'Doubles the column width and allows for links images to be placed on top of the links',
            defaultValue: false,
          },
          {
            label: 'Menu',
            name: 'menu',
            component: 'group-list',
            itemProps: {
              label: '{{item.link.text}}',
            },
            fields: [
              {
                component: 'toggle',
                name: 'open_column',
                label: 'Enable',
                description: 'Mobile Menu- Show Menu Dropdown Open by Default',
                defaultValue: false,
              },
              {
                label: 'Link',
                name: 'link',
                component: 'link',
              },
              {
                label: 'Link Icon',
                name: 'link_icon',
                component: 'image',
                description:
                  'Recommended Icon Size: 50px x 50px (square dimension) for regular icons next to the text',
              },
              {
                label: 'Child Menu',
                name: 'child_menu',
                component: 'group-list',
                itemProps: {
                  label: '{{item.link.text}}',
                },
                fields: [
                  {
                    label: 'Link',
                    name: 'link',
                    component: 'link',
                  },
                  {
                    label: 'Link Icon',
                    name: 'link_icon',
                    component: 'image',
                    description:
                      'Recommended Icon Size: 50px x 50px (square dimension) and 200px x 200px for featured columns where icons are placed on top of the text',
                  },
                  {
                    component: 'color',
                    name: 'link_color',
                    label: 'Custom Color',
                    description: 'Custom Link color',
                  },
                  {
                    label: 'Promo Text',
                    name: 'promo_text',
                    component: 'text',
                    description: 'Add Promo Text',
                  },
                  {
                    label: 'Hover Image',
                    name: 'link_hover_image',
                    component: 'image',
                    description: 'Recommended Icon Size: 1392px x 920px',
                  },
                  {
                    component: 'toggle',
                    name: 'bold_underline',
                    label: 'Bold and Underline',
                    description: 'Make menu item bold and underline',
                    defaultValue: false,
                  },
                ],
              },
            ],
          },
          {
            label: 'CTA',
            name: 'cta',
            component: 'group',
            fields: [
              {
                label: 'CTA Image',
                name: 'image',
                component: 'image',
              },
              {
                label: 'CTA Title',
                name: 'title',
                component: 'text',
              },
              {
                label: 'CTA URL',
                name: 'url',
                component: 'text',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    label: 'Mobile Sticky Navigation',
    name: 'mobileStickyNavigation',
    component: 'group-list',
    itemProps: {
      label: '{{item.link.text}}',
    },
    fields: [
      {
        label: 'Link',
        name: 'link',
        component: 'link',
      },
    ],
  },
  {
    label: 'Footer',
    name: 'footer',
    component: 'group',
    fields: [
      {
        label: 'Quiz Banner',
        name: 'quizBanner',
        component: 'group',
        fields: [
          {
            label: 'Banner Invisibility',
            name: 'bannerInvisibility',
            description: 'Hide the footer quiz banner on the following pages',
            component: 'group-list',
            itemProps: {
              label: '{{item.link.text}}',
            },
            fields: [
              {
                label: 'Link',
                name: 'link',
                component: 'link',
              },
            ],
          },
          {
            component: 'text',
            label: 'Title',
            name: 'sectionTitle',
          },
          {
            name: 'fontSize',
            label: 'Title Font Size',
            component: 'select',
            options: [
              { label: 'H1', value: 'text-h1-m md:text-h1' },
              { label: 'H2', value: 'text-h2-m md:text-h2' },
              { label: 'H3', value: 'text-h3-m md:text-h3 text-[22px] md:text-[38px]' },
            ],
            defaultValue: 'text-h3-m md:text-h3 text-[22px] md:text-[38px]',
          },
          {
            component: 'textarea',
            label: 'Description',
            name: 'sectionDescription',
          },
          {
            label: 'CTA',
            name: 'cta',
            component: 'group',
            fields: [
              {
                label: 'CTA URL',
                name: 'link',
                component: 'link',
              },
              {
                component: 'select',
                name: 'style',
                label: 'Cta Style',
                options: [
                  { label: 'Primary', value: 'button button--primary inline-block' },
                  {
                    label: 'Secondary',
                    value: 'button button--secondary inline-block',
                  },
                  {
                    label: 'Tertiary',
                    value: 'button button--tertiary inline-block',
                  },
                  { label: 'Focus Link', value: 'focus-link' },
                  { label: 'Focus Link White', value: 'focus-link-white' },
                  {
                    label: 'checkout',
                    value: 'button button--checkout inline-block',
                  },
                ],
                defaultValue: 'button button--primary inline-block',
              },
            ],
          },
          {
            component: 'select',
            name: 'textAlign',
            label: 'Text Align (Desktop)',
            options: [
              { label: 'Left', value: 'md:text-left' },
              { label: 'Center', value: 'md:text-center' },
              { label: 'Right', value: 'md:text-right' },
            ],
            defaultValue: 'md:text-center',
          },
          {
            component: 'select',
            name: 'textAlign_m',
            label: 'Text Align (Mobile)',
            options: [
              { label: 'Left', value: 'text-left' },
              { label: 'Center', value: 'text-center' },
              { label: 'Right', value: 'text-right' },
            ],
            defaultValue: 'text-center',
          },
          {
            name: 'lightText',
            label: 'Text Color',
            component: 'toggle',
            toggleLabels: {
              true: 'Light',
              false: 'Dark',
            },
            defaultValue: 'Light',
          },
          {
            name: 'bgColor',
            label: 'Background Color',
            component: 'select',
            description:
              'For section background color, select one from preselected brand colors.',
            options: [
              { label: 'White', value: 'bg-white' },
              { label: 'Grey', value: 'bg-grey-1' },
              { label: 'Light Grey', value: 'bg-grey-9' },
              { label: 'Blue 1', value: 'bg-quinary-1' },
              { label: 'Blue 2', value: 'bg-quinary-2' },
              { label: 'Blue 3', value: 'bg-light-blue' },
              { label: 'Cream 1', value: 'bg-tertiary-2' },
              { label: 'Cream 2', value: 'bg-senary-4' },
              { label: 'Purple', value: 'bg-tertiary-1' },
              { label: 'Green', value: 'bg-quaternary-1' },
              { label: 'Pink 1', value: 'bg-primary-2' },
              { label: 'Pink 2', value: 'bg-secondary-1' },
              { label: 'Yellow', value: 'bg-yellow' },
              { label: 'Orange', value: 'bg-secondary-2' },
              { label: 'Seafoam 1', value: 'bg-quaternary-2' },
              { label: 'Seafoam 2', value: 'bg-senary-3' },
              { label: 'Seafoam 3', value: 'bg-senary-5'},
              { label: 'Air Dry Cream', value: 'bg-stylers-1' },
              { label: 'Sculpting Cream', value: 'bg-stylers-2' },
              { label: 'Texture Foam', value: 'bg-stylers-3' },
              { label: 'Sleek Stick', value: 'bg-stylers-4' }
            ],
            defaultValue: 'bg-quaternary-2',
          },
        ],
      },
      {
        label: 'Tagline',
        name: 'tagline',
        component: 'text',
      },
      {
        label: 'Footer Menu 1',
        name: 'footerMenu1',
        component: 'group-list',
        itemProps: {
          label: '{{item.link.text}}',
        },
        fields: [
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
        ],
      },
      {
        label: 'Footer Menu 2',
        name: 'footerMenu2',
        component: 'group-list',
        itemProps: {
          label: '{{item.link.text}}',
        },
        fields: [
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
        ],
      },
      {
        label: 'Footer Small Menu',
        name: 'footerSmallMenu',
        component: 'group-list',
        itemProps: {
          label: '{{item.link.text}}',
        },
        fields: [
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
        ],
      },
      {
        label: 'Email Subscribe',
        name: 'email',
        component: 'group',
        fields: [
          {
            label: 'Title',
            name: 'title',
            component: 'text',
          },
          {
            label: 'Klaviyo List ID',
            name: 'listId',
            component: 'text',
          },
          {
            label: 'Button Text',
            name: 'buttonText',
            component: 'text',
          },
          {
            label: 'Success Message',
            name: 'successMessage',
            component: 'text',
          },
        ],
      },
    ],
  },
    {
    label: 'Product',
    name: 'product',
    component: 'group',
    fields: [
      {
        component: 'group',
        name: 'productValueProps',
        label: 'Value Props',
        fields: [
          {
            label: 'Value Icon 1',
            name: 'value_icon_1',
            component: 'image',
            description:
              'Recommended SVG Icon Size: 24px x 24px (square dimension)',
          },
          {
            label: 'Value Promo Text 1',
            name: 'value_text_1',
            component: 'text',
            description: 'Value Description (recommended 24 characters)',
          },
          {
            label: 'Value Icon 2',
            name: 'value_icon_2',
            component: 'image',
            description:
              'Recommended SVG Icon Size: 24px x 24px (square dimension)',
          },
          {
            label: 'Value Promo Text 2',
            name: 'value_text_2',
            component: 'text',
            description: 'Value Description (recommended 24 characters)',
          },
          {
            label: 'Value Icon 3',
            name: 'value_icon_3',
            component: 'image',
            description:
              'Recommended SVG Icon Size: 24px x 24px (square dimension)',
          },
          {
            label: 'Value Promo Text 3',
            name: 'value_text_3',
            component: 'text',
            description: 'Value Description (recommended 24 characters)',
          },
        ],
      },
      {
        label: 'Coming Soon Email Subscribe',
        name: 'productEmail',
        component: 'group',
        fields: [
          {
            label: 'Title',
            name: 'title',
            component: 'text',
          },
          {
            label: 'Klaviyo List ID',
            name: 'listId',
            component: 'text',
          },
          {
            label: 'Button Text',
            name: 'buttonText',
            component: 'text',
          },
          {
            label: 'Success Message',
            name: 'successMessage',
            component: 'text',
          },
        ],
      },
      {
        component: "toggle",
        name: "enableGlobalDiscountMessage",
        label: "Enable global discount message",
        toggleLabels: {
          true: "Enabled",
          false: "Disabled",
        },
      },
      {
        component: "textarea",
        name: "globalDiscountMessage",
        label: "Global discount message",
        info: "Will override any discount message on all products with the exception of the prodcuts listed on the setting below"
      },
      {
        component: "list",
        name: "globalDiscountExceptions",
        label: "Global discount message exceptions",
        info: "The products populated on this list will not display the global discount message populated above if enabled",
        field: {
          component: "productSearch",
        },
      }
    ],
  },
  {
    label: 'Cart Page',
    name: 'cartPage',
    component: 'group',
    fields: [
      {
        label: 'Cart Empty Message',
        name: 'cartEmptyMessage',
        component: 'text',
        defaultValue: 'Oops! Your cart is currently empty.',
      },
      {
        label: 'CTA',
        name: 'cta',
        component: 'group',
        fields: [
          {
            label: 'CTA Title',
            name: 'title',
            component: 'text',
            defaultValue: 'continue shopping',
          },
          {
            label: 'CTA URL',
            name: 'url',
            component: 'text',
            defaultValue: '/',
          },
        ],
      },
    ],
  },
  {
    label: 'Side Cart',
    name: 'sideCart',
    component: 'group',
    fields: [
      {
        label: 'Free Shipping Threshold',
        name: 'freeShippingThreshold',
        component: 'group',
        fields: [
          {
            label: 'Free Shipping Threshold',
            name: 'Threshold',
            component: 'number',
            description:
              'Enter a dollar amount. Free shipping will be applied when cart total meets the threshold.',
            defaultValue: 0,
          },
          {
            label: 'Free shipping product information',
            name: 'information',
            component: 'text',
            description:
              'Example: away from our free mini hair mask OR away from free shipping. (use text [TOTAL] for variable for left amount)',
            defaultValue:
              'You are only [TOTAL] away from free shipping. (subscription orders ship free)',
          },
          {
            label: 'Message when threshold is met',
            name: 'message',
            component: 'text',
            description: "Example: Yay! you're getting a free mini hair mask!",
            defaultValue: "Yay! You're getting free shipping!",
          },
          {
            label: 'Enable subscription shipping message',
            name: 'enableSubscriptionText',
            description:
              'If enabled, carts with subscriptions will show the free shipping message below',
            component: 'toggle',
            toggleLabels: {
              true: 'Enabled',
              false: 'Disabled',
            },
          },
          {
            label: 'Subscription shipping message',
            name: 'subscriptionThresoldText',
            component: 'text',
            defaultValue: "Yay! You're getting free shipping!",
          },
        ],
      },
      {
        label: "Promotional Slider",
        name: 'promotionalSlider',
        component: 'group',
        fields: [
          {
            label: 'Enable Promotional Slider',
            name: 'enable',
            component: 'toggle',
            toggleLabels: {
              true: 'Enabled',
              false: 'Disabled',
            },
          },
          {
            label: 'Background Color',
            name: 'backgroundColorHex',
            component: 'color',
            defaultValue: '#B8C5D6',
          },
          {
            label: 'Text Color',
            name: 'textColor',
            component: 'select',
            options: [
              { label: 'White', value: 'text-white' },
              { label: 'Black', value: 'text-black' },
            ],
            defaultValue: 'text-black',
          },
          {
            label: 'Enable Auto Play',
            name: 'enableAutoplay',
            component: 'toggle',
            toggleLabels: {
              true: 'Enabled',
              false: 'Disabled',
            },
          },
          {
            label: 'Autoplay Speed',
            name: 'autoplaySpeed',
            component: 'number',
            description: 'Time each slide will stay in screen in seconds (if auto  play is enabled)',
            defaultValue: 5,
          },
          {
            label: 'Promotional Slide',
            name: 'slide',
            component: 'group-list',
            itemProps: {
              label: '{{item.text}}',
            },
            fields: [
              {
                label: "Customer Base",
                name: 'customerBase',
                component: 'select',
                options: [
                  { label: 'US only ', value: 'us' },
                  { label: 'International Only', value: 'intl' },
                  { label: 'All', value: 'all' },
                ],
                defaultValue: 'all',
              },
              {
                label: 'Slide Text',
                name: 'text',
                component: 'markdown',
                defaultValue: 'Promotional message'
              }
            ]
          }
        ]
      },
      {
        label: 'Upsell Products Label',
        name: 'upsellProductsLabel',
        component: 'text',
        defaultValue: 'You May Also Like',
      }
    ],
  },
  {
    label: 'Discount Highlight',
    name: 'discountHighlight',
    component: 'group',
    fields: [
      {
        label: 'Enable Discount Highlight',
        name: 'enableDiscountHighlight',
        component: 'toggle',
        toggleLabels: {
          true: 'Enabled',
          false: 'Disabled',
        },
        description:
          'If enabled, percentage difference between compare at price vs price will be shown on the mini cart and cart template, this will also enable this discount to be visible within the PDP',
      },
      {
        label: 'Cart Discount Message',
        name: 'cartDiscountMessage',
        component: 'text',
        defaultValue: 'Black Friday Savings',
      },
      {
        label: 'PDP discount message',
        name: 'pdpDiscountMessage',
        component: 'text',
        description:
          'Use the keyword [discount] to dynamically display the savings value, if this text is not populated the text will not be displayed',
        defaultValue: '[discount] OFF BLACK FRIDAY',
      },
    ],
  },
  {
    label: 'Search',
    name: 'search',
    component: 'group',
    fields: [
      {
        label: 'Terms heading',
        name: 'termsHeading',
        component: 'text',
        defaultValue: 'Popular Searches',
      },
      {
        label: 'Popular search terms',
        name: 'terms',
        component: 'group-list',
        itemProps: {
          label: '{{item.label}}',
        },
        defaultItem: () => ({
          id: generateId(),
          label: 'New term',
        }),
        fields: [
          {
            label: 'Term',
            name: 'label',
            component: 'text',
          },
        ],
        defaultValue: [
          { label: 'Moisturize', value: 'Moisturize' },
          { label: 'Strengthen', value: 'Strengthen' },
          { label: 'Repair', value: 'Repair' },
          { label: 'Treatment', value: 'Treatment' },
          { label: 'Charcoal', value: 'Charcoal' },
          { label: 'Refresh', value: 'Refresh' },
          { label: 'Shampoo', value: 'Shampoo' },
          { label: 'Conditioner', value: 'Conditioner' },
          { label: 'Leave-In', value: 'Leave-In' },
        ],
      },
      {
        label: 'Terms heading',
        name: 'productsHeading',
        component: 'text',
        defaultValue: 'Popular products',
      },
      {
        name: 'products',
        label: 'Popular products',
        component: 'group-list',
        description: 'Only add max of three products',
        itemProps: {
          label: '{{item.product.title}}',
        },
        fields: [
          {
            name: 'product',
            component: 'productSearch',
            label: 'Product',
          },
        ],
      },
    ],
  },
    {
    label: 'Collection Tile',
    name: 'collectionTile',
    component: 'group',
    fields: [
      {
        label: 'Promo Tile Campaigns',
        name: 'campaigns',
        component: 'group-list',
        itemProps: {
          label: '{{item.name}}',
        },
        fields: [
          {
            label: 'Enable',
            name: 'enabled',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Name',
            name: 'name',
            component: 'text',
          },
          {
            label: 'Promo Tiles',
            name: 'promoTiles',
            component: 'group-list',
            itemProps: {
              label: '{{item.position}}: {{item.text.heading}}',
            },
            fields: [
              {
                label: 'Grid Position',
                name: 'position',
                component: 'number',
                description: 'Assigns grid order',
              },
              {
                label: 'Aspect Ratio',
                name: 'aspectRatio',
                component: 'select',
                options: [
                  { label: '1:1', value: 'aspect-[1/1]' },
                  { label: '4:5', value: 'aspect-[4/5]' },
                  { label: '3:4', value: 'aspect-[3/4]' },
                  { label: '2:3', value: 'aspect-[2/3]' },
                  { label: '9:16', value: 'aspect-[9/16]' },
                  { label: 'Fill', value: 'h-full' },
                ],
              },
              {
                label: 'Link',
                name: 'link',
                component: 'link',
                description: 'Makes the entire tile a clickable link',
              },
              {
                label: 'Image Settings',
                name: 'background',
                component: 'group',
                fields: [
                  {
                    label: 'Image Alt',
                    name: 'alt',
                    component: 'text',
                    description: 'Brief description of image',
                  },
                  {
                    label: 'Desktop Image',
                    name: 'd_image',
                    component: 'image',
                    description: '*Required. Recommended Image size for "Fill" setting: 674px x 1000px',
                  },
                  {
                    label: 'Mobile Image',
                    name: 'm_image',
                    component: 'image',
                    description: '*Required. Recommended Image size for "Fill" setting: 628px x 1528px',
                  },
                ],
              },
            ],
            defaultItem: {
              position: 5,
              aspectRatio: 'h-full',
            },
          },
          {
            label: 'Collection Handles',
            name: 'collections',
            component: 'list',
            description:
              'Add all collection handles that will run this campaign, e.g. "all", "best-sellers"',
            field: {
              component: 'text',
            },
          },
        ],
        defaultItem: {
          enabled: false,
          name: 'Campaign',
          promoTiles: [
            {
              position: 5,
              aspectRatio: 'h-full',
            },
          ],
          collections: ['all'],
        },
      },
    ],
  },
  {
    label: 'Subscriptions',
    name: 'subscriptions',
    component: 'group',
    fields: [
      {
        label: 'Free Shipping Text',
        name: 'freeShippingText',
        component: 'text',
        defaultValue: 'Free Shipping',
      },
      {
        label: 'Color Badge Text',
        name: 'colorBadgeText',
        component: 'text',
        defaultVlue: 'Most Popular!',
      },
      {
        label: 'Cancel Anytime Text',
        name: 'cancelText',
        component: 'text',
        defaultValue: 'Cancel anytime + membership perks.',
      },
      {
        component: 'toggle',
        name: 'selectSubscriptionOnLoad',
        label: 'Select Subscription on load',
        description: 'Toggle default subscription option selection in Product Page and Quickview Modal.',
        toggleLabels: {
          true: 'Yes',
          false: 'No',
        },
        defaultValue: true,
      },
      {
        label: 'Product Subscription Modal',
        name: 'subscriptionModal',
        component: 'group',
        fields: [
          {
            label: 'Trigger CTA Label',
            name: 'triggerCTALabel',
            component: 'text',
          },
          {
            label: 'Modal Title',
            name: 'title',
            component: 'text',
          },
          {
            label: 'Modal Content List',
            name: 'contentList',
            component: 'group-list',
            itemProps: {
              label: '{{item.title}}',
            },
            fields: [
              {
                label: 'Icon',
                name: 'icon',
                component: 'image',
                description:
                  'Recommended Icon Size: 100px x 100px (square dimension)',
              },
              {
                label: 'Title',
                name: 'title',
                component: 'text',
              },
              {
                label: 'Description',
                name: 'description',
                component: 'textarea',
              },
            ],
            validate: {
              maxItems: 5,
            }
          }
        ]
      }
    ],
  },
  {
    label: 'Free Product Scenarios',
    name: 'newFreeProducts',
    component: 'group',
    fields: [
      {
        label: 'Free Product Scenarios',
        name: 'gwpScenarios',
        component: 'blocks',
        templates: {
          buyXGetY: {
            label: 'Buy X Get Y',
            key: 'buyXGetY',
            fields: [
              {
                component: 'toggle',
                name: 'enabled',
                label: 'Enable Promotion',
                description: 'Disable this to pre-populate any promotion without getting it active on the site',
                toggleLabels: {
                  true: 'Yes',
                  false: 'No',
                },
                defaultValue: true,
              },
              {
                component: 'text',
                name: 'campaign',
                label: 'Gift Campaign',
                description: 'Unique identifier for the GWP promotion, this is required to allow managing individual GWPs',
                validate: {
                  required: true,
                }
              },
              {
                name: 'gwpProductQualify',
                label: 'Add This Product To Cart To Qualify For Gift (USD)',
                component: 'productSearch',
              },
              {
                name: 'enableSubQualify',
                label: 'Check to look for only subscription in cart',
                component: 'toggle',
                toggleLabels: {
                  true: 'Yes',
                  false: 'No',
                },
                defaultValue: false,
              },
              {
                name: 'gwpProduct',
                label: 'Gift With Purchase Product',
                component: 'productSearch',
              },
              {
                label: 'Add item to cart label',
                name: 'gwpAddBack',
                component: 'text',
                default: 'Add free gift',
                description:
                  'Label for the button that the customer uses to add the items back to their cart if they have opted out',
              },
            ]
          },
          spendXGetY: {
            label: 'Spend X Get Y',
            key: 'spendXGetY',
            fields: [
              {
                component: 'toggle',
                name: 'enabled',
                label: 'Enable Promotion',
                description: 'Disable this to pre-populate any promotion without getting it active on the site',
                toggleLabels: {
                  true: 'Yes',
                  false: 'No',
                },
                defaultValue: true,
              },
              {
                component: 'text',
                name: 'campaign',
                label: 'Gift Campaign',
                description: 'Unique identifier for the GWP promotion, this is required to allow managing individual GWPs',
                validate: {
                  required: true,
                }
              },
              {
                name: 'gwpProduct',
                label: 'Gift With Purchase Product',
                component: 'productSearch',
              },
              {
                label: 'Cart Spend To Qualify For Gift (USD)',
                name: 'gwpCartMinimum',
                component: 'number',
              },
              {
                label: 'Add item to cart label',
                name: 'gwpAddBack',
                component: 'text',
                default: 'Add free gift',
                description:
                  'Label for the button that the customer uses to add the items back to their cart if they have opted out',
              },
            ],
          },
          spendXandChooseY: {
            label: 'Spend X Choose Y',
            key: 'spendXChooseY',
            fields: [
              {
                component: 'toggle',
                name: 'enabled',
                label: 'Enable Promotion',
                description: 'Disable this to pre-populate any promotion without getting it active on the site',
                toggleLabels: {
                  true: 'Yes',
                  false: 'No',
                },
                defaultValue: true,
              },
              {
                component: 'text',
                name: 'campaign',
                label: 'Gift Campaign',
                description: 'Unique identifier for the GWP promotion, this is required to allow managing individual GWPs',
                validate: {
                  required: true,
                }
              },
              {
                name: 'gwpProduct',
                label: 'Gift With Purchase Product',
                component: 'list',
                field: {
                  component: "productSearch"
                },
                validate: {
                  maxItems: 4,
                }
              },
              {
                label: 'Cart Spend To Qualify For Gift (USD)',
                name: 'gwpCartMinimum',
                component: 'number',
              },
              {
                label: 'Add item to cart label',
                name: 'gwpAddBack',
                component: 'text',
                default: 'Add free gift',
                description:
                  'Label for the button that the customer uses to add the items back to their cart if they have opted out',
              },
              {
                label: 'Modal Settings',
                name: 'modalSettings',
                component: 'group',
                description:
                  'Information to show on the modal when the customer is selecting the free gift',
                fields: [
                  {
                    label: 'Title',
                    name: 'title',
                    component: 'text',
                  },
                  {
                    label: 'subtitle',
                    name: 'subtitle',
                    component: 'textarea'
                  },
                  {
                    label: 'Add to Cart text',
                    name: 'atcText',
                    component: 'text',
                    defaultValue: 'add to cart'
                  },
                  {
                    label: 'Skip button text',
                    name: 'skipButtonText',
                    component: 'text',
                    defaultValue:'skip free gift'
                  }
                ]
              }
            ],
          },
          buyFromCollectionGetY: {
            label: 'Buy From Collection Get Y',
            name: 'buyFromCollectionGetY',
            key: 'buyFromCollectionGetY',
            fields: [
              {
                component: 'toggle',
                name: 'enabled',
                label: 'Enable Promotion',
                description: 'Disable this to pre-populate any promotion without getting it active on the site',
                toggleLabels: {
                  true: 'Yes',
                  false: 'No',
                },
                defaultValue: true,
              },
              {
                component: 'text',
                name: 'campaign',
                label: 'Gift Campaign',
                description: 'Unique identifier for the GWP promotion, this is required to allow managing individual GWPs',
                validate: {
                  required: true,
                }
              },
              {
                name: 'gwpCollectionQualify',
                label:
                  'Add products from this collection To Qualify For Gift (USD)',
                component: 'text',
                info: 'Enter collection handle',
              },
              {
                name: 'gwpProduct',
                label: 'Gift With Purchase Product',
                component: 'productSearch',
              },
              {
                label: 'Add item to cart label',
                name: 'gwpAddBack',
                component: 'text',
                default: 'Add free gift',
                description:
                  'Label for the button that the customer uses to add the items back to their cart if they have opted out',
              },
            ],
          },
          buySubscriptionGetY: {
            label: 'Buy Subscription Get Y',
            name: 'buySubscriptionGetY',
            fields: [
              {
                component: 'toggle',
                name: 'enabled',
                label: 'Enable Promotion',
                description: 'Disable this to pre-populate any promotion without getting it active on the site',
                toggleLabels: {
                  true: 'Yes',
                  false: 'No',
                },
                defaultValue: true,
              },
              {
                component: 'text',
                name: 'campaign',
                label: 'Gift Campaign',
                description: 'Unique identifier for the GWP promotion, this is required to allow managing individual GWPs',
                validate: {
                  required: true,
                }
              },
              {
                name: 'gwpAllSubsQualify',
                label: 'Enable All Subscription Products to Qualify for Gift (USD)',
                component: 'toggle',
                toggleLabels: {
                  true: 'Yes',
                  false: 'No',
                },
                defaultValue: false,
                description:
                  'Enables only subscription products to trigger free gift',
              },
              {
                name: 'gwpProduct',
                label: 'Gift With Purchase Product',
                component: 'productSearch',
              },
              {
                label: 'Add item to cart label',
                name: 'gwpAddBack',
                component: 'text',
                default: 'Add free gift',
                description:
                  'Label for the button that the customer uses to add the items back to their cart if they have opted out',
              },
            ],
          },
          buyBundleGetY: {
            label: 'Buy Bundle Get Gift',
            name: 'buyBundleGetY',
            fields: [
              {
                component: 'toggle',
                name: 'enabled',
                label: 'Enable Promotion',
                description: 'Disable this to pre-populate any promotion without getting it active on the site',
                toggleLabels: {
                  true: 'Yes',
                  false: 'No',
                },
                defaultValue: true,
              },
              {
                component: 'text',
                name: 'campaign',
                label: 'Gift Campaign',
                description: 'Unique identifier for the GWP promotion, this is required to allow managing individual GWPs',
                validate: {
                  required: true,
                }
              },
              {
                name: 'gwpProduct',
                label: 'Gift With Purchase Product',
                component: 'productSearch',
              },
              {
                name: 'gwpBundleCount',
                label: 'Qualifying bundle length',
                component: 'number',
                defaultValue: 0,
              },
              {
                component: 'productSearch',
                label: 'Qualifying product',
                name: 'gwpProductQualify',
                description: 'Product that needs to be in the bundle to trigger the free gift, if the bundle lenght is matched but the product is not in the bundle the gift will not be added',
              },
              {
                component: 'text',
                label: 'Qualify Message',
                name: 'gwpQualifyMessage',
                description: 'Message that will be displayed on a toast with confetti when the bundle qualifies for the gift',
                default: 'You\'ve got a free gift',
              },
              {
                label: 'Add item to cart label',
                name: 'gwpAddBack',
                component: 'text',
                default: 'Add free gift',
                description:
                  'Label for the button that the customer uses to add the items back to their cart if they have opted out',
              },
            ],
          },
          freeSamples: {
            label: 'Free Samples',
            name: 'freeSamples',
            fields: [
              {
                label: 'Enable Free Samples',
                name: 'enabled',
                component: 'toggle',
                toggleLabels: {
                  true: 'Yes',
                  false: 'No',
                },
              },
              {
                component: 'text',
                name: 'campaign',
                label: 'Gift Campaign (required)',
                description: 'Unique identifier for the GWP promotion, this is required to allow managing individual GWPs',
                validate: {
                  required: true,
                }
              },
              {
                name: 'gwpProduct',
                label: 'Free Sample Product',
                component: 'productSearch',
              },
              {
                label: 'Cart Spend To Qualify For Samples (USD)',
                name: 'samplesCartMinimum',
                component: 'number',
              },
              {
                label: 'Add item to cart label',
                name: 'gwpAddBack',
                component: 'text',
                default: 'Add Free Sample',
                description:
                  'Label for the button that the customer uses to add the items back to their cart if they have opted out',
              },
            ],
          }
        }
      }
    ]
  },
  {
    label: 'Account',
    name: 'account',
    component: 'group',
    fields: [
      {
        label: 'Refer A Friend Image',
        name: 'referFriendImage',
        component: 'image',
        description: 'Recommended Image Size: 1000 x 1000',
      },
      {
        label: 'Sign In Message',
        name: 'SignInMessage',
        component: 'text',
      },
    ],
  },
  {
    label: 'Social Share',
    name: 'socialShare',
    component: 'group',
    fields: [
      {
        label: 'Instagram',
        name: 'instagramLink',
        component: 'text',
      },
      {
        label: 'Facebook',
        name: 'facebookLink',
        component: 'text',
      },
      {
        label: 'You Tube',
        name: 'youTubeLink',
        component: 'text',
      },
      {
        label: 'Pinterest',
        name: 'pinterestLink',
        component: 'text',
      },
      {
        label: 'Twitter',
        name: 'twitterLink',
        component: 'text',
      },
      {
        label: 'Tik Tok',
        name: 'tikTokLink',
        component: 'text',
      },
    ],
  },
  {
    label: 'Buy with Prime',
    name: 'buyWithPrime',
    component: 'group',
    fields: [
      {
        label: 'Badges Location',
        name: 'bwpLocation',
        component: 'select',
        description:
          'For this feature to work the products must include the tag BWP, if no products include the tag beside of the options selected below they will not show',
        options: [
          {
            label: 'None (disabled)',
            value: 'disabled',
          },
          {
            label: 'Selected collections',
            value: 'local',
          },
          {
            label: 'Sitewide',
            value: 'global',
          },
        ],
      },
      {
        label: 'Collections',
        name: 'collectionList',
        component: 'list',
        description:
          'List of collections in which the badges will be show, badge will be shown on the PDP besides it being applied to any collection unless the Badges location option is set to disabled, this reference should match exactly with the Collection Name in order to work',
        field: {
          component: 'text',
        },
      },
    ],
  },
];
