import Script from 'next/script';

export function GorgiasContactForm({ cms }) {
  return (
    <div
      id={`${GorgiasContactForm.Schema.key}-${
        cms.id || cms.cmsId || cms.tinaId
      }`}
    >
      <style>
        {`#${GorgiasContactForm.Schema.key}-${
          cms.id || cms.cmsId || cms.tinaId
        } {
            margin-top: ${cms.mobileSpacing}px;
          }
          @media(min-width: 1024px) {
            #${GorgiasContactForm.Schema.key}-${
          cms.id || cms.cmsId || cms.tinaId
        } {
              margin-top: ${cms.desktopSpacing}px;
            }
          }`}
      </style>
      <Script
        defer
        type="text/javascript"
        src="https://contact-us.gorgias.help/api/contact-form-loader.js?source=https://contact-us.gorgias.help/en-US/embed/contact"
      />
      <link
        rel="stylesheet"
        href="https://contact-us.gorgias.help/api/contact-form-loader.css"
      />
      <div id="gorgias-contact-form-wrapper">
        <div id="gorgias-contact-form-loader" />
      </div>
    </div>
  );
}

GorgiasContactForm.displayName = 'GorgiasContactForm';

GorgiasContactForm.Schema = {
  category: 'Contact',
  label: GorgiasContactForm.displayName,
  key: 'contactform',
  fields: [
    {
      component: 'number',
      name: 'mobileSpacing',
      label: 'Section Top Margin - Mobile',
      defaultValue: 60,
    },
    {
      component: 'number',
      name: 'desktopSpacing',
      label: 'Section Top Margin - Desktop',
      defaultValue: 80,
    },
  ],
};
