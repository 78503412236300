import { useDataLayerEventListeners } from './useDataLayerEventListeners';

// Envs to set:
// * NEXT_PUBLIC_SITE_TITLE // provides backup brand name
// * NEXT_PUBLIC_ENABLE_DL_DEBUGGER // enables console logs for event dispatches
// * NEXT_PUBLIC_SHOPIFY_CUSTOM_APP_ID

export function DataLayerEventListeners({ pageTitle, template }) {
  useDataLayerEventListeners({
    pageTitle,
    template,
  });

  return null;
}

DataLayerEventListeners.displayName = 'DataLayerEventListeners';
