import React from 'react';
import Head from 'next/head';

const backpackHeadUserConfig = {};

export function PageHead({ page = {}, children }) {
  const { resourceType } = page || {};
  const { noIndex, noFollow } = page?.seo || {};
  const index = noIndex ? 'noindex' : 'index';
  const follow = noFollow ? 'nofollow' : 'follow';

  let pageUrl = '';

  if (resourceType === 'home_page') {
    pageUrl = process.env.SITE_URL;
  } else if (resourceType === 'page') {
    pageUrl = `${process.env.SITE_URL}/pages/${page.handle}`;
  } else if (resourceType === 'product_page') {
    pageUrl = `${process.env.SITE_URL}/products/${page.handle}`;
  } else if (resourceType === 'collection_page') {
    pageUrl = `${process.env.SITE_URL}/collections/${page.handle}`;
  } else if (resourceType === 'article') {
    pageUrl = `${process.env.SITE_URL}/articles/${page.handle}`;
  } else if (resourceType === 'blog') {
    pageUrl = `${process.env.SITE_URL}/blogs/${page.handle}`;
  } else {
    pageUrl = process.env.SITE_URL;
  }

  return (
    <>
      <Head>
        <title key="page-title">{page?.seo?.title}</title>

        <meta
          key="page-description"
          name="description"
          content={page?.seo?.description}
        />
        <meta
          key="page-keywords"
          name="keywords"
          content={page?.seo?.keywords}
        />
        <link key="page-canonical-url" rel="canonical" href={pageUrl} />
        {page?.favicon ? (
          <link rel="shortcut icon" href={page.favicon} />
        ) : null}

        {/* IMPORTANT
            DO NOT NEST THESE INTO SUB-COMPONENTS OR REACT FRAGMENTS
            as next/head doesn't parse nested metas
        */}

        {/* OG and TWIITER CARD IMAGE */}

        {page?.seo?.image ? (
          <>
            <meta
              key="page:fb:image"
              property="og:image"
              content={page.seo.image}
            />
            <meta
              key="page:fb:image:alt"
              property="og:image:alt"
              content={page?.seo?.title || backpackHeadUserConfig?.title}
            />
            <meta
              key="page:twitter:image"
              name="twitter:image"
              content={page.seo.image}
            />
          </>
        ) : null}

        {/*
          OpenGraph
            @see: more info https://dev.twitter.com/cards/getting-started
            @see: testing https://cards-dev.twitter.com/validator
        */}

        {process.env.FACEBOOK_APP_ID ? (
          <meta
            key="page:fb:app_id"
            property="fb:app_id"
            content={process.env.FACEBOOK_APP_ID}
          />
        ) : null}

        <meta key="page:fb:url" property="og:url" content={pageUrl} />

        <meta key="page:fb:type" property="og:type" content="website" />

        <meta
          key="page:fb:title"
          property="og:title"
          content={page?.seo?.title || backpackHeadUserConfig?.title}
        />

        <meta
          key="page:fb:description"
          property="og:description"
          content={
            page?.seo?.description || backpackHeadUserConfig?.description
          }
        />

        <meta
          key="page:fb:locale"
          property="og:locale"
          content={backpackHeadUserConfig?.locale}
        />

        <meta
          key="page:fb:author"
          property="og:author"
          content={backpackHeadUserConfig?.description}
        />

        {/*
          TwitterCard
            @see: more info https://dev.twitter.com/cards/getting-started
            @see: testing https://cards-dev.twitter.com/validator
        */}

        <meta key="page:twitter:card" name="twitter:card" content="summary" />

        {backpackHeadUserConfig?.social?.twitter ? (
          <meta
            key="page:twitter:site"
            name="twitter:site"
            content={backpackHeadUserConfig.social.twitter}
          />
        ) : null}

        {backpackHeadUserConfig?.social?.twitter ? (
          <meta
            key="page:twitter:creator"
            name="twitter:creator"
            content={backpackHeadUserConfig.social.twitter}
          />
        ) : null}

        <meta key="page:twitter:url" name="twitter:url" content={pageUrl} />

        <meta
          key="page:twitter:title"
          name="twitter:title"
          content={page?.seo?.title || backpackHeadUserConfig?.title}
        />

        <meta
          key="page:twitter:description"
          name="twitter:description"
          content={
            page?.seo?.description || backpackHeadUserConfig?.description
          }
        />

        <meta
          key="page:twitter:image:alt"
          name="twitter:image:alt"
          content={page?.seo?.title || backpackHeadUserConfig?.title}
        />

        <meta
          key="default-all-search-engines-crawling"
          name="robots"
          content={`${index},${follow}`}
        />

        <meta
          key="default-google-engine-crawling"
          name="googlebot"
          content={`${index},${follow}`}
        />
      </Head>

      {children}
    </>
  );
}
