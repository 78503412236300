import { useEffect, useState } from 'react';
import Script from 'next/script';

const eventTypes = ['dl_add_to_cart'];

const BingScript = ({ bingPixelId }) => (
  <Script
    id="bing-pixel-script"
    dangerouslySetInnerHTML={{
      __html: `
        (function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"${bingPixelId}", enableAutoSpaTracking: true};
        o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){
        var s=this.readyState;s &&s!=="loaded"&& s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],
        i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");`,
    }}
  />
);

export function FueledBingScript({ withKey: bingPixelId }) {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  useEffect(() => {
    if (!isScriptLoaded) {
      setIsScriptLoaded(true);
    } else {
      const eventHandler = (event) => {
        switch (event.type) {
          case 'dl_add_to_cart': {
            const [product] = event.detail.ecommerce.add.products;

            window.uetq.push('event', 'add_to_cart', {
              ecomm_prodid: product.product_id,
              ecomm_pagetype: 'product',
              ecomm_totalvalue: product.price * product.quantity,
              ...(product.quantity && { ecomm_category: product.category }),
              currency: event.detail.ecommerce.currency_code,
              items: [
                {
                  id: product.product_id,
                  name: product.name,
                  price: product.price,
                  quantity: product.quantity,
                },
              ],
            });
            break;
          }
        }
      };

      eventTypes.forEach((eventType) => {
        window.addEventListener(eventType, eventHandler);
      });

      return () => {
        eventTypes.forEach((eventType) => {
          window.removeEventListener(eventType, eventHandler);
        });
      };
    }
  }, [isScriptLoaded]);

  return isScriptLoaded ? null : <BingScript bingPixelId={bingPixelId} />;
}
