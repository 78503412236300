import Script from 'next/script';

function BeamNonprofitWidget() {
  return (
    <div>
      <Script
        type="module"
        dangerouslySetInnerHTML={{
          __html: `
          import { init } from "https://sdk.beamimpact.com/web-sdk/v1.39.2/dist/integrations/beam.js";

          const beam = await init({
            domain: "briogeohair.com",
            apiKey:"1FZ6VRy197TU.0e43c50d-caa7-4d36-a559-6a893b37fba6",
            chainId:"270",
            storeId:"439",
            baseUrl:"https://production-central-backend-ne.beamimpact.com",
          });`,
        }}
      />
      <Script
        crossorigin=""
        defer
        src="https://production-beam-widgets.beamimpact.com/web-sdk/v1.39.2/dist/components/select-nonprofit.esm.js"
        type="module"
      />
      <div id="beamContainer2">
        <beam-select-nonprofit
          apiKey="1FZ6VRy197TU.0e43c50d-caa7-4d36-a559-6a893b37fba6"
          chainId="270"
          storeId="439"
          baseUrl="https://production-central-backend-ne.beamimpact.com"
          class="custom"
        >
          Select a nonprofit
        </beam-select-nonprofit>
      </div>
      <style>
        {`#beamContainer2 {
            display: block;
            text-align: left !important;
            max-width: 500px;
            border-radius: 4px;
            padding-top: 1.25rem;
            padding-right: 1rem;
            padding-bottom: 1.25rem;
            padding-left: 1rem;
          }`}
      </style>
    </div>
  );
}

export default BeamNonprofitWidget;