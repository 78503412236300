export function Unmute(props) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 63 63"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      {...props}
    >
      <g>
        <path
          d="M31.123,3.123C15.669,3.123 3.123,15.669 3.123,31.123C3.123,46.577 15.669,59.123 31.123,59.123C46.577,59.123 59.123,46.577 59.123,31.123C59.123,15.669 46.577,3.123 31.123,3.123ZM31.123,5.123C45.473,5.123 57.123,16.773 57.123,31.123C57.123,45.473 45.473,57.123 31.123,57.123C16.773,57.123 5.123,45.473 5.123,31.123C5.123,16.773 16.773,5.123 31.123,5.123ZM31.123,22.418L31.123,39.542C31.123,40.381 30.153,40.847 29.498,40.323L22.888,35.035L18.123,35.035C17.571,35.035 17.123,34.587 17.123,34.035L17.123,26.976C17.123,26.424 17.571,25.976 18.123,25.976L22.476,25.976L29.597,21.568C30.263,21.156 31.123,21.635 31.123,22.418ZM35.72,25.038C37.728,25.923 39.123,28.099 39.123,30.623C39.123,33.148 37.728,35.323 35.72,36.208L36.526,38.038C39.206,36.857 41.123,33.991 41.123,30.623C41.123,27.256 39.206,24.389 36.526,23.208L35.72,25.038ZM33.694,28.027C34.556,28.435 35.123,29.457 35.123,30.623C35.123,31.789 34.556,32.811 33.694,33.22L34.552,35.027C36.034,34.323 37.123,32.629 37.123,30.623C37.123,28.617 36.034,26.923 34.552,26.22L33.694,28.027Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}